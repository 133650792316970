/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Form, Field } from "react-final-form";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import smoothscroll from "smoothscroll-polyfill";
import Error from "../Error";
import { editUserSettings } from "../../actions";
import useStyles from "../styles";
import { RenderTextField, RenderSelectField } from "../../utlis/input";
import { required } from "../form/utils";
import timezones from "../../utlis/timezones";

const PersonalForm = ({ copy, ...user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [timezone, setTimeZone] = useState("UTC");

  function handleChange(event) {
    setTimeZone(event.target.value);
  }

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  });

  const onSubmit = async values => {
    const newUser = {
      first_name: values.first_name,
      last_name: values.last_name
    };
    const newSettings = {
      timezone: values.timezone
    };
    dispatch(await editUserSettings(user.username, newUser, newSettings));
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        timezone: "UTC",
        first_name: user.first_name,
        last_name: user.last_name
      }}
      render={({ handleSubmit, invalid, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Typography
            variant="h1"
            align="center"
            className={classes.settingHeader}
          >
            {copy.personal}
          </Typography>
          <Field
            name="first_name"
            id="first_name"
            type="text"
            component={RenderTextField}
            label="First Name"
            validate={required}
          />
          <Error name="first_name" {...classes} />
          <Field
            name="last_name"
            id="last_name"
            type="text"
            component={RenderTextField}
            label="Last Name"
            validate={required}
          />
          <Error name="last_name" {...classes} />
          <Field
            name="timezone"
            label="Timezone (optional)"
            component={RenderSelectField}
            values={timezone}
            onChange={handleChange}
          >
            {timezones.map((timezone, key) => (
              <MenuItem key={key} value={timezone.value}>
                {timezone.option}
              </MenuItem>
            ))}
          </Field>

          {user.fetchState === "User Updated Successfully" && (
            <Typography
              variant="caption"
              color="secondary"
              align="center"
              display="block"
              className={classes.error}
            >
              {user.fetchState}
            </Typography>
          )}

          <Fab
            variant="extended"
            aria-label="Submit"
            color="secondary"
            type="submit"
            disabled={invalid || submitting}
            className={classes.submitButton}
          >
            {copy.button}
          </Fab>
        </form>
      )}
    />
  );
};

PersonalForm.propTypes = {
  copy: PropTypes.object.isRequired
};

export default PersonalForm;
