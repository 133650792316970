import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "./layout/Layout";
import Loading from "./Loading";
import Loaded from "./Loaded";
import { getOrder, getLoan, getLead, pingLead } from "../actions";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Home = () => {
  const lead = useSelector((state) => state.lead);
  const order = useSelector((state) => state.order);
  const loan = useSelector((state) => state.loan);

  const dispatch = useDispatch();

  const [loadLead, setLoadLead] = useState(false);
  const [loadPing, setLoadPing] = useState(false);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchOrder() {
      dispatch(await getOrder());
    }

    async function fetchLoan() {
      dispatch(await getLoan());
    }

    async function fetchLead() {
      dispatch(await getLead());
    }

    fetchOrder();
    fetchLoan();
    fetchLead();
  }, [dispatch]);

  useEffect(() => {
    if (lead.fetchState === "Error") {
      throw new Error(JSON.stringify(lead.lead));
    }
    if (order.fetchState === "Order Error") {
      throw new Error(JSON.stringify(order.order));
    }
    if (loan.fetchState === "Loan Error") {
      throw new Error(JSON.stringify(loan.loan));
    }

    async function fetchPing() {
      dispatch(await pingLead(lead.lead.results[0].id));
    }

    // ---Once lead is loaded set loadLead as true---
    if (
      Object.getOwnPropertyNames(lead).length !== 0 &&
      lead.lead.hasOwnProperty("results") &&
      lead.lead.results.length > 0
    ) {
      setLoadLead(true);
    }

    // --- If lead is loaded and is in initial status, set loadPing to true---
    if (
      loadLead &&
      (lead.lead.results[0].status === "initial" ||
        lead.lead.results[0].status === "pending" ||
        lead.lead.results[0].status === "processing")
    ) {
      setLoadPing(true);
    }

    // --- If lead is loaded and is in not initial status, set loadPing to false---
    if (
      loadLead &&
      lead.lead.results[0].status !== "initial" &&
      lead.lead.results[0].status !== "pending" &&
      lead.lead.results[0].status !== "processing"
    ) {
      setLoadPing(false);
    }

    // ---Once lead is loaded and ping action !== null, ping lead---
    if (loadLead && lead.lead.results[0].actions.ping !== null) {
      fetchPing();
    }
  }, [lead, order, loan, loadLead, loadPing, dispatch]);

  useInterval(
    // ---While loadLead is true and load status is inital, keep loading lead---
    async () => {
      if (loadLead) {
        dispatch(await getLead());
      }
    },
    loadPing ? 1000 : null
  );

  return (
    <Layout>
      {loadLead &&
      order.fetchState === "Order Success" &&
      loan.fetchState === "Loan Success" &&
      lead.lead.results &&
      lead.lead.results[0].status !== "initial" &&
      lead.lead.results[0].status !== "pending" &&
      lead.lead.results[0].status !== "processing" ? (
        <Loaded />
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default Home;
