import * as actions from "../actions";

const initValues = {
  fetchState: ""
};

export default (user = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_USER:
      return { fetchState: "" };
    case actions.CLEAR_FETCH_STATE:
      return { ...user, fetchState: "" };
    case actions.GET_USER_LOADING:
      return { ...user, fetchState: "Loading" };
    case actions.GET_USER_SUCCESS:
      return { ...action.payload, fetchState: "Success" };
    case actions.GET_USER_ERROR:
      return { ...user, fetchState: action.payload };
    case actions.EDIT_USER_SETTINGS_LOADING:
      return { ...user, fetchState: "Loading" };
    case actions.EDIT_USER_SETTINGS_SUCCESS:
      return { ...action.payload, fetchState: "User Updated Successfully" };
    case actions.EDIT_USER_SETTINGS_ERROR:
      return { ...user, fetchState: action.payload };
    default:
      return user;
  }
};
