import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import EnableFooter from "./EnableFooter";

import payPossibleFooterLogo from "../../images/payPossibleFooterLogo.png";
import footerCoBrandLogo from "../../images/footerCoBrandLogo.png";
import enableFooterLogo from "../../images/enableFooterLogo.png";
import coBrand from "../../utlis/coBrand";

const FooterWrapper = () => {
  const lead = useSelector((state) => state.lead);
  const merchant = useSelector((state) => state.merchantInfo);

  const [brand, setBrand] = useState(false);

  useEffect(() => {
    // --------Co brand--------//
    function setCoBrand(code) {
      coBrand.curated.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.zipLoans.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.innateHealthcare.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.inspiredIdeaSolutions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.kiserArenaSpecialist.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.aurora.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.autoSales.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.floridaPaver.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.mapToClients.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.justiceTax.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.fitnessGrind.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.ppb.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.lightRx.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.kingWheel.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.abc.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.transMedCare.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.leCaprice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.budgetBlinds.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.everywhere.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.trinityProximity.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.medevateHealth.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.connellyPhoto.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.zg.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.diamondSolutions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.clearVision.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.elegantOccasions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.theoutfit.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.writeChoice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.parkOutdoor.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.incrediHome.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.bigOffice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.squareOne.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.rij.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.eCells.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.rok.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.ayr.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });

      coBrand.weldscholars.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand(true);
      });


      if (code === "") setBrand("");
    }
    // --------End Co brand--------//

    if (merchant.fetchState === "Success") {
      setCoBrand(merchant.merchant.code);
    } else {
      setCoBrand("");
    }

    if (
      Object.getOwnPropertyNames(lead).length !== 0 &&
      lead.lead.hasOwnProperty("results") &&
      lead.lead.results.length > 0 &&
      lead.lead.results[0].merchant
    ) {
      setCoBrand(lead.lead.results[0].merchant.code);
    }
  }, [lead, merchant.fetchState, merchant.merchant.code]);

  if (process.env.REACT_APP_FE === "enable") {
    return <EnableFooter logo={enableFooterLogo}/>;
  } else {
    return <Footer logo={brand ? footerCoBrandLogo : payPossibleFooterLogo}/>;
  }
};

export default FooterWrapper;
