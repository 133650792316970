import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import Fab from "@material-ui/core/Fab";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import useStyles from "../styles";
import { reapplyLead, reDirect } from "../../actions";

import copyEnable from "../../utlis/copyEnable";
import copyEn from "../../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const NoOfferCard = ({ personal, result }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  async function handleReapply() {
    await dispatch(reapplyLead(result.id));
  }

  async function handleRedirect() {
    await dispatch(reDirect(false));
  }

  return (
    <>
      <Card className={classes.card} raised style={{ marginTop: 50 }}>
        {result.actions.reapply === null ? (
          <>
            <CardContent>
              <Typography
                variant="body1"
                color="primary"
                className={classes.cardName}
              >
                Hi {personal.first_name},
              </Typography>
              <Typography variant="body1" color="primary">
                {copy.account.nooffercard.line1}
              </Typography>
              <DateRangeIcon
                color="secondary"
                style={{
                  fontSize: "6rem",
                  margin: "20px auto 10px",
                  display: "block"
                }}
              />
              <Typography
                variant="h1"
                color="secondary"
                align="center"
                style={{ fontSize: "2rem", fontWeight: 600 }}
              >
                {result.reapply_countdown} {copy.account.nooffercard.line3}
              </Typography>
            </CardContent>
            <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
              <Fab
                variant="extended"
                aria-label="Submit"
                color="secondary"
                disabled
                style={{ minWidth: 250 }}
              >
                {copy.account.nooffercard.button3}
              </Fab>
            </div>
            <div style={{ width: "100%", textAlign: "center", marginTop: 20 }}>
              <Link
                target="_blank"
                underline="none"
                rel="noopener noreferrer"
                href="https://www.creditservices.biz/credit-repair-pp/"
              >
                <Fab
                  variant="extended"
                  color="secondary"
                  aria-label="Submit"
                  style={{ padding: "0 22px", minWidth: 250 }}
                >
                  <span style={{ fontSize: "0.7rem" }}>
                    {copy.account.nooffercard.line4}
                  </span>
                </Fab>
              </Link>
            </div>
          </>
        ) : (
          <>
            <CardHeader
              title={
                <>
                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.cardName}
                  >
                    Hi {personal.first_name},
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h1"
                    className={classes.largeHeader}
                  >
                    {copy.account.nooffercard.line7}
                  </Typography>
                  <Typography
                    color="secondary"
                    variant="h1"
                    className={classes.largeSubHeader}
                  >
                    {copy.account.nooffercard.line8}
                  </Typography>
                  <CalendarTodayIcon
                    color="secondary"
                    style={{
                      fontSize: "6rem",
                      margin: "20px auto 10px",
                      display: "block"
                    }}
                  />
                </>
              }
              style={{ padding: 0 }}
            />

            <CardContent>
              <Typography variant="body2" color="primary">
                {copy.account.nooffercard.line2}
              </Typography>
            </CardContent>

            <Card raised elevation="4" className={classes.innerCard}>
              <CardHeader
                className={classes.offerCardHeader}
                style={{ padding: 10 }}
              />
              <CardContent>
                <Typography className={classes.cardHeader} variant="caption">
                  {copy.account.nooffercard.line5}
                </Typography>
                <div
                  style={{ width: "100%", textAlign: "center", marginTop: 30 }}
                >
                  <Link href={null} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                      onClick={handleReapply}
                    >
                      {copy.account.nooffercard.button1}
                    </Fab>
                  </Link>
                </div>
              </CardContent>
            </Card>

            <Card raised elevation="4" className={classes.innerCard}>
              <CardHeader
                className={classes.offerCardHeader}
                style={{ padding: 10 }}
              />
              <CardContent>
                <Typography className={classes.cardHeader} variant="caption">
                  {copy.account.nooffercard.line6}
                </Typography>
                <div
                  style={{ width: "100%", textAlign: "center", marginTop: 30 }}
                >
                  <Link href={`${process.env.PUBLIC_URL}/`} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                      style={{ padding: "0 22px", minWidth: "160px" }}
                      onClick={handleRedirect}
                    >
                      {copy.account.nooffercard.button2}
                    </Fab>
                  </Link>
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </Card>
    </>
  );
};

NoOfferCard.propTypes = {
  personal: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired
};

export default NoOfferCard;
