/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import _superagent from "superagent";
import superagentPromise from "superagent-promise";

const superagent = superagentPromise(_superagent, global.Promise);

// ---Dev Branch---
// const API_ROOT = "https://app-staging.paypossible.com/api";

// ---Main Branch---
const API_ROOT = process.env.REACT_APP_API_ENDPOINT;

const responseBody = (res) => res.body;
const responseHeader = (res) => res.headers;

let token = null;

const Token = {
  get: () => token,
  set: (newToken) => {
    token = newToken;
  },
  unSet: () => {
    token = null;
  }
};

const tokenAuth = (req) => {
  if (token) {
    req.set("authorization", `JWT ${token}`);
  }
};

// const refreshToken = (err, res) =>
//   new Promise((resolve, reject) => {
//     if (
//       token &&
//       err &&
//       (err.status === 400 || err.status === 401 || err.status === 403)
//     ) {
//       Auth.refresh(token).then(() => resolve(true));
//     } else {
//       resolve(false);
//     }
//   });

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody),
  postToken: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody),
  postTokenHeader: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseHeader),
  postNoToken: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).then(responseBody),
  postUrl: (body) =>
    superagent
      .post(body)
      .use(tokenAuth)
      // .retry(1, refreshToken)
      .then(responseBody)
};

const Auth = {
  current: () => requests.get("/v1/users/me/"),
  login: (username, password) =>
    requests.postToken("/token/", { username, password }),
  register: (first_name, last_name, email, password) =>
    requests.postToken("/v1/users/", {
      first_name,
      last_name,
      email,
      password
    }),
  refresh: (token) => requests.postToken("/token/refresh/", { token }),
  verify: (token) => requests.postToken("/token/verify/", { token }),
  session: (token) => requests.postTokenHeader("/token/session/", { token })
};

const Lead = {
  create: (obj) => requests.postNoToken("/v1/leads/", obj),
  get: () => requests.get("/v1/leads/"),
  getById: (id) => requests.get(`/v1/leads/${id}`),
  ping: (id) => requests.postToken(`/v1/leads/${id}/ping/`),
  reapply: (id) => requests.postToken(`/v1/leads/${id}/reapply/`),
  put: (id, obj) => requests.put(`/v1/leads/${id}`, obj),
  patch: (id, obj) => requests.patch(`/v1/leads/${id}`, obj)
};

const Loan = {
  get: () => requests.get("/v1/loans/"),
  getById: (id) => requests.get(`/v1/loans/${id}`)
};

const Order = {
  get: () => requests.get("/v1/orders/"),
  getById: (id) => requests.get(`/v1/orders/${id}`),
  approve: (url) => requests.postUrl(url)
};

const Merchant = {
  search: (id) => requests.get(`/v1/merchants/search/${id}/`)
};

const User = {
  save: (username, user) => requests.patch(`/v1/users/${username}/`, user),
  settings: (username, settings) =>
    requests.patch(`/v1/users/${username}/settings/`, settings),
  getAddresses: () => requests.get("/v1/addresses/"),
  createAddress: (address) => requests.postToken("/v1/addresses/", address),
  getPhoneNumbers: () => requests.get("/v1/phones/"),
  createPhoneNumber: (phone) => requests.postToken("/v1/phones/", phone),
  getEmails: () => requests.get("/v1/emails/"),
  createEmail: (email) => requests.postToken("/v1/emails/", email),
  timezones: (username) => requests.get(`/v1/users/${username}/timezones/`),
  validatePassword: (passwordObj) =>
    requests.postNoToken("/v1/users/validate-password/", passwordObj)
};

const Email = {
  validate: (emailObj) =>
    requests.postNoToken("/v1/emails/validate/", emailObj),
  makePrimary: (email) =>
    requests.postToken(`/v1/emails/${email}/make-primary/`),
  sendConfirmation: (email) => {
    requests.postToken(`/v1/emails/${email}/send-confirmation/`);
  }
};

const Phone = {
  validate: (phoneObj) =>
    requests.postNoToken("/v1/phones/validate/", phoneObj),
  makePrimary: (id, phone) =>
    requests.postToken(`/v1/phones/${id}/make-primary/`, phone)
};

const Address = {
  makePrimary: (id, address) =>
    requests.postToken(`/v1/addresses/${id}/make-primary/`, address)
};

export {
  Address,
  Auth,
  Email,
  Lead,
  Loan,
  Order,
  Merchant,
  Phone,
  Token,
  User,
  API_ROOT
};
