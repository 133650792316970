import { Lead, Token } from "../api/Agent";

export const CLEAR_PING = "CLEAR_PING";
export const PING_LOADING = "PING_LOADING";
export const PING_SUCCESS = "PING_SUCCESS";
export const PING_ERROR = "PING_ERROR";

export const clearPing = () => ({
  type: CLEAR_PING
});

export const pingLead = id => async dispatch => {
  dispatch({
    type: PING_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Lead.ping(id);
    dispatch({
      type: PING_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: PING_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};
