/* eslint-disable */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { RenderTextField, RenderSelectField } from "../../utlis/input";
import { required, composeValidators, ZipField } from "./utils";
import states from "../../utlis/states";

const postcode = require("postcode-validator");

const checkZip = value =>
  postcode.validate(value, "US") ? undefined : "Invalid zip code";

const checkPOBox = value => {
    const pattern = /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)?\b/i;
    return (value.match(pattern)) ? "Must be physical address not PO Box" : undefined;
}

const Page7 = ({ header, copy }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  const [state, setState] = useState("");

  function handleChange(event) {
    setState(event.target.value);
  }

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line1}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line2}
      </Typography>

      <Grid container spacing={1}>
        <Grid item md={8} xs={12}>
          <Field
            name="street1"
            id="street1"
            type="text"
            component={RenderTextField}
            label="Street Address"
            validate={composeValidators(required, checkPOBox)}
          />
          <Error name="street1" {...classes} />
        </Grid>
        <Grid item md={4} xs={12}>
          <Field
            name="street2"
            id="street2"
            type="text"
            component={RenderTextField}
            label="Ste/Apt"
          />
          <Error name="street2" {...classes} />
        </Grid>
      </Grid>

      <Field
        name="city"
        id="city"
        type="text"
        component={RenderTextField}
        label="City"
      />
      <Error name="city" {...classes} />
      <Field
        name="state"
        label="State"
        component={RenderSelectField}
        values={state}
        onChange={handleChange}
        validate={required}
      >
        {states.map((state, key) => (
          <MenuItem key={key} value={state.value}>
            {state.option}
          </MenuItem>
        ))}
      </Field>
      <Error name="state" {...classes} />
      <Field
        name="zip"
        id="zip"
        type="tel"
        component={RenderTextField}
        label="Zip Code"
        InputProps={{
          inputComponent: ZipField
        }}
        validate={composeValidators(required, checkZip)}
      />
      <Error name="zip" {...classes} />
    </Wizard.Page>
  );
};

Page7.propTypes = {
  header: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
};

export default Page7;
