const footerIndex = {
  headers: {
    header1: "Customers",
    header2: "Merchants",
    header3: "Links"
  },
  row1: [
    { path: `${process.env.PUBLIC_URL}/`, name: "Apply", external: false },
    {
      path: `${process.env.PUBLIC_URL}/sign-in`,
      name: "Sign In",
      external: false
    },
    {
      path: "https://paypossible.com/contact",
      name: "Help",
      external: false
    },
    { path: "https://help.paypossible.com", name: "FAQs", external: true }
  ],
  row2: [
    {
      path: "https://www.paypossible.com/business-signup",
      name: "Sign Up",
      external: false
    },
    {
      path: `${process.env.PUBLIC_URL}/sign-in`,
      name: "Sign In",
      external: false
    },
    { path: "https://help.paypossible.com", name: "FAQs", external: true },
    {
      path: "https://paypossible.com/contact",
      name: "Support",
      external: false
    }
  ],
  row3: [
    {
      path: "https://www.paypossible.com/about",
      name: "About Us",
      external: false
    },
    {
      path: "https://www.paypossible.com/privacy-policy",
      name: "Privacy",
      external: false
    },
    {
      path: "https://www.paypossible.com/terms-of-use",
      name: "Terms",
      external: false
    },
    {
      path: "https://www.paypossible.com/contact",
      name: "Contact Us",
      external: false
    }
  ]
};

export default footerIndex;
