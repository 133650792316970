const timezones = [
  { value: "Africa/Abidjan", option: "Africa/Abidjan" },
  { value: "Africa/Accra", option: "Africa/Accra" },
  { value: "Africa/Addis_Ababa", option: "Africa/Addis Ababa" },
  { value: "Africa/Algiers", option: "Africa/Algiers" },
  { value: "Africa/Asmara", option: "Africa/Asmara" },
  { value: "Africa/Bamako", option: "Africa/Bamako" },
  { value: "Africa/Bangui", option: "Africa/Bangui" },
  { value: "Africa/Banjul", option: "Africa/Banjul" },
  { value: "Africa/Bissau", option: "Africa/Bissau" },
  { value: "Africa/Blantyre", option: "Africa/Blantyre" },
  { value: "Africa/Brazzaville", option: "Africa/Brazzaville" },
  { value: "Africa/Bujumbura", option: "Africa/Bujumbura" },
  { value: "Africa/Cairo", option: "Africa/Cairo" },
  { value: "Africa/Casablanca", option: "Africa/Casablanca" },
  { value: "Africa/Ceuta", option: "Africa/Ceuta" },
  { value: "Africa/Conakry", option: "Africa/Conakry" },
  { value: "Africa/Dakar", option: "Africa/Dakar" },
  { value: "Africa/Dar_es_Salaam", option: "Africa/Dar es Salaam" },
  { value: "Africa/Djibouti", option: "Africa/Djibouti" },
  { value: "Africa/Douala", option: "Africa/Douala" },
  { value: "Africa/El_Aaiun", option: "Africa/El Aaiun" },
  { value: "Africa/Freetown", option: "Africa/Freetown" },
  { value: "Africa/Gaborone", option: "Africa/Gaborone" },
  { value: "Africa/Harare", option: "Africa/Harare" },
  { value: "Africa/Johannesburg", option: "Africa/Johannesburg" },
  { value: "Africa/Juba", option: "Africa/Juba" },
  { value: "Africa/Kampala", option: "Africa/Kampala" },
  { value: "Africa/Khartoum", option: "Africa/Khartoum" },
  { value: "Africa/Kigali", option: "Africa/Kigali" },
  { value: "Africa/Kinshasa", option: "Africa/Kinshasa" },
  { value: "Africa/Lagos", option: "Africa/Lagos" },
  { value: "Africa/Libreville", option: "Africa/Libreville" },
  { value: "Africa/Lome", option: "Africa/Lome" },
  { value: "Africa/Luanda", option: "Africa/Luanda" },
  { value: "Africa/Lubumbashi", option: "Africa/Lubumbashi" },
  { value: "Africa/Lusaka", option: "Africa/Lusaka" },
  { value: "Africa/Malabo", option: "Africa/Malabo" },
  { value: "Africa/Maputo", option: "Africa/Maputo" },
  { value: "Africa/Maseru", option: "Africa/Maseru" },
  { value: "Africa/Mbabane", option: "Africa/Mbabane" },
  { value: "Africa/Mogadishu", option: "Africa/Mogadishu" },
  { value: "Africa/Monrovia", option: "Africa/Monrovia" },
  { value: "Africa/Nairobi", option: "Africa/Nairobi" },
  { value: "Africa/Ndjamena", option: "Africa/Ndjamena" },
  { value: "Africa/Niamey", option: "Africa/Niamey" },
  { value: "Africa/Nouakchott", option: "Africa/Nouakchott" },
  { value: "Africa/Ouagadougou", option: "Africa/Ouagadougou" },
  { value: "Africa/Porto-Novo", option: "Africa/Porto-Novo" },
  { value: "Africa/Sao_Tome", option: "Africa/Sao Tome" },
  { value: "Africa/Tripoli", option: "Africa/Tripoli" },
  { value: "Africa/Tunis", option: "Africa/Tunis" },
  { value: "Africa/Windhoek", option: "Africa/Windhoek" },
  { value: "America/Adak", option: "America/Adak" },
  { value: "America/Anchorage", option: "America/Anchorage" },
  { value: "America/Anguilla", option: "America/Anguilla" },
  { value: "America/Antigua", option: "America/Antigua" },
  { value: "America/Araguaina", option: "America/Araguaina" },
  {
    value: "America/Argentina/Buenos_Aires",
    option: "America/Argentina/Buenos Aires"
  },
  {
    value: "America/Argentina/Catamarca",
    option: "America/Argentina/Catamarca"
  },
  { value: "America/Argentina/Cordoba", option: "America/Argentina/Cordoba" },
  { value: "America/Argentina/Jujuy", option: "America/Argentina/Jujuy" },
  { value: "America/Argentina/La_Rioja", option: "America/Argentina/La Rioja" },
  { value: "America/Argentina/Mendoza", option: "America/Argentina/Mendoza" },
  {
    value: "America/Argentina/Rio_Gallegos",
    option: "America/Argentina/Rio Gallegos"
  },
  { value: "America/Argentina/Salta", option: "America/Argentina/Salta" },
  { value: "America/Argentina/San_Juan", option: "America/Argentina/San Juan" },
  { value: "America/Argentina/San_Luis", option: "America/Argentina/San Luis" },
  { value: "America/Argentina/Tucuman", option: "America/Argentina/Tucuman" },
  { value: "America/Argentina/Ushuaia", option: "America/Argentina/Ushuaia" },
  { value: "America/Aruba", option: "America/Aruba" },
  { value: "America/Asuncion", option: "America/Asuncion" },
  { value: "America/Atikokan", option: "America/Atikokan" },
  { value: "America/Bahia", option: "America/Bahia" },
  { value: "America/Bahia_Banderas", option: "America/Bahia Banderas" },
  { value: "America/Barbados", option: "America/Barbados" },
  { value: "America/Belem", option: "America/Belem" },
  { value: "America/Belize", option: "America/Belize" },
  { value: "America/Blanc-Sablon", option: "America/Blanc-Sablon" },
  { value: "America/Boa_Vista", option: "America/Boa Vista" },
  { value: "America/Bogota", option: "America/Bogota" },
  { value: "America/Boise", option: "America/Boise" },
  { value: "America/Cambridge_Bay", option: "America/Cambridge Bay" },
  { value: "America/Campo_Grande", option: "America/Campo Grande" },
  { value: "America/Cancun", option: "America/Cancun" },
  { value: "America/Caracas", option: "America/Caracas" },
  { value: "America/Cayenne", option: "America/Cayenne" },
  { value: "America/Cayman", option: "America/Cayman" },
  { value: "America/Chicago", option: "America/Chicago" },
  { value: "America/Chihuahua", option: "America/Chihuahua" },
  { value: "America/Costa_Rica", option: "America/Costa Rica" },
  { value: "America/Creston", option: "America/Creston" },
  { value: "America/Cuiaba", option: "America/Cuiaba" },
  { value: "America/Curacao", option: "America/Curacao" },
  { value: "America/Danmarkshavn", option: "America/Danmarkshavn" },
  { value: "America/Dawson", option: "America/Dawson" },
  { value: "America/Dawson_Creek", option: "America/Dawson Creek" },
  { value: "America/Denver", option: "America/Denver" },
  { value: "America/Detroit", option: "America/Detroit" },
  { value: "America/Dominica", option: "America/Dominica" },
  { value: "America/Edmonton", option: "America/Edmonton" },
  { value: "America/Eirunepe", option: "America/Eirunepe" },
  { value: "America/El_Salvador", option: "America/El Salvador" },
  { value: "America/Fort_Nelson", option: "America/Fort Nelson" },
  { value: "America/Fortaleza", option: "America/Fortaleza" },
  { value: "America/Glace_Bay", option: "America/Glace Bay" },
  { value: "America/Godthab", option: "America/Godthab" },
  { value: "America/Goose_Bay", option: "America/Goose Bay" },
  { value: "America/Grand_Turk", option: "America/Grand Turk" },
  { value: "America/Grenada", option: "America/Grenada" },
  { value: "America/Guadeloupe", option: "America/Guadeloupe" },
  { value: "America/Guatemala", option: "America/Guatemala" },
  { value: "America/Guayaquil", option: "America/Guayaquil" },
  { value: "America/Guyana", option: "America/Guyana" },
  { value: "America/Halifax", option: "America/Halifax" },
  { value: "America/Havana", option: "America/Havana" },
  { value: "America/Hermosillo", option: "America/Hermosillo" },
  {
    value: "America/Indiana/Indianapolis",
    option: "America/Indiana/Indianapolis"
  },
  { value: "America/Indiana/Knox", option: "America/Indiana/Knox" },
  { value: "America/Indiana/Marengo", option: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg", option: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Tell_City", option: "America/Indiana/Tell City" },
  { value: "America/Indiana/Vevay", option: "America/Indiana/Vevay" },
  { value: "America/Indiana/Vincennes", option: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac", option: "America/Indiana/Winamac" },
  { value: "America/Inuvik", option: "America/Inuvik" },
  { value: "America/Iqaluit", option: "America/Iqaluit" },
  { value: "America/Jamaica", option: "America/Jamaica" },
  { value: "America/Juneau", option: "America/Juneau" },
  {
    value: "America/Kentucky/Louisville",
    option: "America/Kentucky/Louisville"
  },
  {
    value: "America/Kentucky/Monticello",
    option: "America/Kentucky/Monticello"
  },
  { value: "America/Kralendijk", option: "America/Kralendijk" },
  { value: "America/La_Paz", option: "America/La Paz" },
  { value: "America/Lima", option: "America/Lima" },
  { value: "America/Los_Angeles", option: "America/Los Angeles" },
  { value: "America/Lower_Princes", option: "America/Lower Princes" },
  { value: "America/Maceio", option: "America/Maceio" },
  { value: "America/Managua", option: "America/Managua" },
  { value: "America/Manaus", option: "America/Manaus" },
  { value: "America/Marigot", option: "America/Marigot" },
  { value: "America/Martinique", option: "America/Martinique" },
  { value: "America/Matamoros", option: "America/Matamoros" },
  { value: "America/Mazatlan", option: "America/Mazatlan" },
  { value: "America/Menominee", option: "America/Menominee" },
  { value: "America/Merida", option: "America/Merida" },
  { value: "America/Metlakatla", option: "America/Metlakatla" },
  { value: "America/Mexico_City", option: "America/Mexico City" },
  { value: "America/Miquelon", option: "America/Miquelon" },
  { value: "America/Moncton", option: "America/Moncton" },
  { value: "America/Monterrey", option: "America/Monterrey" },
  { value: "America/Montevideo", option: "America/Montevideo" },
  { value: "America/Montserrat", option: "America/Montserrat" },
  { value: "America/Nassau", option: "America/Nassau" },
  { value: "America/New_York", option: "America/New York" },
  { value: "America/Nipigon", option: "America/Nipigon" },
  { value: "America/Nome", option: "America/Nome" },
  { value: "America/Noronha", option: "America/Noronha" },
  {
    value: "America/North_Dakota/Beulah",
    option: "America/North Dakota/Beulah"
  },
  {
    value: "America/North_Dakota/Center",
    option: "America/North Dakota/Center"
  },
  {
    value: "America/North_Dakota/New_Salem",
    option: "America/North Dakota/New Salem"
  },
  { value: "America/Ojinaga", option: "America/Ojinaga" },
  { value: "America/Panama", option: "America/Panama" },
  { value: "America/Pangnirtung", option: "America/Pangnirtung" },
  { value: "America/Paramaribo", option: "America/Paramaribo" },
  { value: "America/Phoenix", option: "America/Phoenix" },
  { value: "America/Port-au-Prince", option: "America/Port-au-Prince" },
  { value: "America/Port_of_Spain", option: "America/Port of Spain" },
  { value: "America/Porto_Velho", option: "America/Porto Velho" },
  { value: "America/Puerto_Rico", option: "America/Puerto Rico" },
  { value: "America/Punta_Arenas", option: "America/Punta Arenas" },
  { value: "America/Rainy_River", option: "America/Rainy River" },
  { value: "America/Rankin_Inlet", option: "America/Rankin Inlet" },
  { value: "America/Recife", option: "America/Recife" },
  { value: "America/Regina", option: "America/Regina" },
  { value: "America/Resolute", option: "America/Resolute" },
  { value: "America/Rio_Branco", option: "America/Rio Branco" },
  { value: "America/Santarem", option: "America/Santarem" },
  { value: "America/Santiago", option: "America/Santiago" },
  { value: "America/Santo_Domingo", option: "America/Santo Domingo" },
  { value: "America/Sao_Paulo", option: "America/Sao Paulo" },
  { value: "America/Scoresbysund", option: "America/Scoresbysund" },
  { value: "America/Sitka", option: "America/Sitka" },
  { value: "America/St_Barthelemy", option: "America/St Barthelemy" },
  { value: "America/St_Johns", option: "America/St Johns" },
  { value: "America/St_Kitts", option: "America/St Kitts" },
  { value: "America/St_Lucia", option: "America/St Lucia" },
  { value: "America/St_Thomas", option: "America/St Thomas" },
  { value: "America/St_Vincent", option: "America/St Vincent" },
  { value: "America/Swift_Current", option: "America/Swift Current" },
  { value: "America/Tegucigalpa", option: "America/Tegucigalpa" },
  { value: "America/Thule", option: "America/Thule" },
  { value: "America/Thunder_Bay", option: "America/Thunder Bay" },
  { value: "America/Tijuana", option: "America/Tijuana" },
  { value: "America/Toronto", option: "America/Toronto" },
  { value: "America/Tortola", option: "America/Tortola" },
  { value: "America/Vancouver", option: "America/Vancouver" },
  { value: "America/Whitehorse", option: "America/Whitehorse" },
  { value: "America/Winnipeg", option: "America/Winnipeg" },
  { value: "America/Yakutat", option: "America/Yakutat" },
  { value: "America/Yellowknife", option: "America/Yellowknife" },
  { value: "Antarctica/Casey", option: "Antarctica/Casey" },
  { value: "Antarctica/Davis", option: "Antarctica/Davis" },
  { value: "Antarctica/DumontDUrville", option: "Antarctica/DumontDUrville" },
  { value: "Antarctica/Macquarie", option: "Antarctica/Macquarie" },
  { value: "Antarctica/Mawson", option: "Antarctica/Mawson" },
  { value: "Antarctica/McMurdo", option: "Antarctica/McMurdo" },
  { value: "Antarctica/Palmer", option: "Antarctica/Palmer" },
  { value: "Antarctica/Rothera", option: "Antarctica/Rothera" },
  { value: "Antarctica/Syowa", option: "Antarctica/Syowa" },
  { value: "Antarctica/Troll", option: "Antarctica/Troll" },
  { value: "Antarctica/Vostok", option: "Antarctica/Vostok" },
  { value: "Arctic/Longyearbyen", option: "Arctic/Longyearbyen" },
  { value: "Asia/Aden", option: "Asia/Aden" },
  { value: "Asia/Almaty", option: "Asia/Almaty" },
  { value: "Asia/Amman", option: "Asia/Amman" },
  { value: "Asia/Anadyr", option: "Asia/Anadyr" },
  { value: "Asia/Aqtau", option: "Asia/Aqtau" },
  { value: "Asia/Aqtobe", option: "Asia/Aqtobe" },
  { value: "Asia/Ashgabat", option: "Asia/Ashgabat" },
  { value: "Asia/Atyrau", option: "Asia/Atyrau" },
  { value: "Asia/Baghdad", option: "Asia/Baghdad" },
  { value: "Asia/Bahrain", option: "Asia/Bahrain" },
  { value: "Asia/Baku", option: "Asia/Baku" },
  { value: "Asia/Bangkok", option: "Asia/Bangkok" },
  { value: "Asia/Barnaul", option: "Asia/Barnaul" },
  { value: "Asia/Beirut", option: "Asia/Beirut" },
  { value: "Asia/Bishkek", option: "Asia/Bishkek" },
  { value: "Asia/Brunei", option: "Asia/Brunei" },
  { value: "Asia/Chita", option: "Asia/Chita" },
  { value: "Asia/Choibalsan", option: "Asia/Choibalsan" },
  { value: "Asia/Colombo", option: "Asia/Colombo" },
  { value: "Asia/Damascus", option: "Asia/Damascus" },
  { value: "Asia/Dhaka", option: "Asia/Dhaka" },
  { value: "Asia/Dili", option: "Asia/Dili" },
  { value: "Asia/Dubai", option: "Asia/Dubai" },
  { value: "Asia/Dushanbe", option: "Asia/Dushanbe" },
  { value: "Asia/Famagusta", option: "Asia/Famagusta" },
  { value: "Asia/Gaza", option: "Asia/Gaza" },
  { value: "Asia/Hebron", option: "Asia/Hebron" },
  { value: "Asia/Ho_Chi_Minh", option: "Asia/Ho Chi Minh" },
  { value: "Asia/Hong_Kong", option: "Asia/Hong Kong" },
  { value: "Asia/Hovd", option: "Asia/Hovd" },
  { value: "Asia/Irkutsk", option: "Asia/Irkutsk" },
  { value: "Asia/Jakarta", option: "Asia/Jakarta" },
  { value: "Asia/Jayapura", option: "Asia/Jayapura" },
  { value: "Asia/Jerusalem", option: "Asia/Jerusalem" },
  { value: "Asia/Kabul", option: "Asia/Kabul" },
  { value: "Asia/Kamchatka", option: "Asia/Kamchatka" },
  { value: "Asia/Karachi", option: "Asia/Karachi" },
  { value: "Asia/Kathmandu", option: "Asia/Kathmandu" },
  { value: "Asia/Khandyga", option: "Asia/Khandyga" },
  { value: "Asia/Kolkata", option: "Asia/Kolkata" },
  { value: "Asia/Krasnoyarsk", option: "Asia/Krasnoyarsk" },
  { value: "Asia/Kuala_Lumpur", option: "Asia/Kuala Lumpur" },
  { value: "Asia/Kuching", option: "Asia/Kuching" },
  { value: "Asia/Kuwait", option: "Asia/Kuwait" },
  { value: "Asia/Macau", option: "Asia/Macau" },
  { value: "Asia/Magadan", option: "Asia/Magadan" },
  { value: "Asia/Makassar", option: "Asia/Makassar" },
  { value: "Asia/Manila", option: "Asia/Manila" },
  { value: "Asia/Muscat", option: "Asia/Muscat" },
  { value: "Asia/Nicosia", option: "Asia/Nicosia" },
  { value: "Asia/Novokuznetsk", option: "Asia/Novokuznetsk" },
  { value: "Asia/Novosibirsk", option: "Asia/Novosibirsk" },
  { value: "Asia/Omsk", option: "Asia/Omsk" },
  { value: "Asia/Oral", option: "Asia/Oral" },
  { value: "Asia/Phnom_Penh", option: "Asia/Phnom Penh" },
  { value: "Asia/Pontianak", option: "Asia/Pontianak" },
  { value: "Asia/Pyongyang", option: "Asia/Pyongyang" },
  { value: "Asia/Qatar", option: "Asia/Qatar" },
  { value: "Asia/Qostanay", option: "Asia/Qostanay" },
  { value: "Asia/Qyzylorda", option: "Asia/Qyzylorda" },
  { value: "Asia/Riyadh", option: "Asia/Riyadh" },
  { value: "Asia/Sakhalin", option: "Asia/Sakhalin" },
  { value: "Asia/Samarkand", option: "Asia/Samarkand" },
  { value: "Asia/Seoul", option: "Asia/Seoul" },
  { value: "Asia/Shanghai", option: "Asia/Shanghai" },
  { value: "Asia/Singapore", option: "Asia/Singapore" },
  { value: "Asia/Srednekolymsk", option: "Asia/Srednekolymsk" },
  { value: "Asia/Taipei", option: "Asia/Taipei" },
  { value: "Asia/Tashkent", option: "Asia/Tashkent" },
  { value: "Asia/Tbilisi", option: "Asia/Tbilisi" },
  { value: "Asia/Tehran", option: "Asia/Tehran" },
  { value: "Asia/Thimphu", option: "Asia/Thimphu" },
  { value: "Asia/Tokyo", option: "Asia/Tokyo" },
  { value: "Asia/Tomsk", option: "Asia/Tomsk" },
  { value: "Asia/Ulaanbaatar", option: "Asia/Ulaanbaatar" },
  { value: "Asia/Urumqi", option: "Asia/Urumqi" },
  { value: "Asia/Ust-Nera", option: "Asia/Ust-Nera" },
  { value: "Asia/Vientiane", option: "Asia/Vientiane" },
  { value: "Asia/Vladivostok", option: "Asia/Vladivostok" },
  { value: "Asia/Yakutsk", option: "Asia/Yakutsk" },
  { value: "Asia/Yangon", option: "Asia/Yangon" },
  { value: "Asia/Yekaterinburg", option: "Asia/Yekaterinburg" },
  { value: "Asia/Yerevan", option: "Asia/Yerevan" },
  { value: "Atlantic/Azores", option: "Atlantic/Azores" },
  { value: "Atlantic/Bermuda", option: "Atlantic/Bermuda" },
  { value: "Atlantic/Canary", option: "Atlantic/Canary" },
  { value: "Atlantic/Cape_Verde", option: "Atlantic/Cape Verde" },
  { value: "Atlantic/Faroe", option: "Atlantic/Faroe" },
  { value: "Atlantic/Madeira", option: "Atlantic/Madeira" },
  { value: "Atlantic/Reykjavik", option: "Atlantic/Reykjavik" },
  { value: "Atlantic/South_Georgia", option: "Atlantic/South Georgia" },
  { value: "Atlantic/St_Helena", option: "Atlantic/St Helena" },
  { value: "Atlantic/Stanley", option: "Atlantic/Stanley" },
  { value: "Australia/Adelaide", option: "Australia/Adelaide" },
  { value: "Australia/Brisbane", option: "Australia/Brisbane" },
  { value: "Australia/Broken_Hill", option: "Australia/Broken Hill" },
  { value: "Australia/Currie", option: "Australia/Currie" },
  { value: "Australia/Darwin", option: "Australia/Darwin" },
  { value: "Australia/Eucla", option: "Australia/Eucla" },
  { value: "Australia/Hobart", option: "Australia/Hobart" },
  { value: "Australia/Lindeman", option: "Australia/Lindeman" },
  { value: "Australia/Lord_Howe", option: "Australia/Lord Howe" },
  { value: "Australia/Melbourne", option: "Australia/Melbourne" },
  { value: "Australia/Perth", option: "Australia/Perth" },
  { value: "Australia/Sydney", option: "Australia/Sydney" },
  { value: "Canada/Atlantic", option: "Canada/Atlantic" },
  { value: "Canada/Central", option: "Canada/Central" },
  { value: "Canada/Eastern", option: "Canada/Eastern" },
  { value: "Canada/Mountain", option: "Canada/Mountain" },
  { value: "Canada/Newfoundland", option: "Canada/Newfoundland" },
  { value: "Canada/Pacific", option: "Canada/Pacific" },
  { value: "Europe/Amsterdam", option: "Europe/Amsterdam" },
  { value: "Europe/Andorra", option: "Europe/Andorra" },
  { value: "Europe/Astrakhan", option: "Europe/Astrakhan" },
  { value: "Europe/Athens", option: "Europe/Athens" },
  { value: "Europe/Belgrade", option: "Europe/Belgrade" },
  { value: "Europe/Berlin", option: "Europe/Berlin" },
  { value: "Europe/Bratislava", option: "Europe/Bratislava" },
  { value: "Europe/Brussels", option: "Europe/Brussels" },
  { value: "Europe/Bucharest", option: "Europe/Bucharest" },
  { value: "Europe/Budapest", option: "Europe/Budapest" },
  { value: "Europe/Busingen", option: "Europe/Busingen" },
  { value: "Europe/Chisinau", option: "Europe/Chisinau" },
  { value: "Europe/Copenhagen", option: "Europe/Copenhagen" },
  { value: "Europe/Dublin", option: "Europe/Dublin" },
  { value: "Europe/Gibraltar", option: "Europe/Gibraltar" },
  { value: "Europe/Guernsey", option: "Europe/Guernsey" },
  { value: "Europe/Helsinki", option: "Europe/Helsinki" },
  { value: "Europe/Isle_of_Man", option: "Europe/Isle of Man" },
  { value: "Europe/Istanbul", option: "Europe/Istanbul" },
  { value: "Europe/Jersey", option: "Europe/Jersey" },
  { value: "Europe/Kaliningrad", option: "Europe/Kaliningrad" },
  { value: "Europe/Kiev", option: "Europe/Kiev" },
  { value: "Europe/Kirov", option: "Europe/Kirov" },
  { value: "Europe/Lisbon", option: "Europe/Lisbon" },
  { value: "Europe/Ljubljana", option: "Europe/Ljubljana" },
  { value: "Europe/London", option: "Europe/London" },
  { value: "Europe/Luxembourg", option: "Europe/Luxembourg" },
  { value: "Europe/Madrid", option: "Europe/Madrid" },
  { value: "Europe/Malta", option: "Europe/Malta" },
  { value: "Europe/Mariehamn", option: "Europe/Mariehamn" },
  { value: "Europe/Minsk", option: "Europe/Minsk" },
  { value: "Europe/Monaco", option: "Europe/Monaco" },
  { value: "Europe/Moscow", option: "Europe/Moscow" },
  { value: "Europe/Oslo", option: "Europe/Oslo" },
  { value: "Europe/Paris", option: "Europe/Paris" },
  { value: "Europe/Podgorica", option: "Europe/Podgorica" },
  { value: "Europe/Prague", option: "Europe/Prague" },
  { value: "Europe/Riga", option: "Europe/Riga" },
  { value: "Europe/Rome", option: "Europe/Rome" },
  { value: "Europe/Samara", option: "Europe/Samara" },
  { value: "Europe/San_Marino", option: "Europe/San Marino" },
  { value: "Europe/Sarajevo", option: "Europe/Sarajevo" },
  { value: "Europe/Saratov", option: "Europe/Saratov" },
  { value: "Europe/Simferopol", option: "Europe/Simferopol" },
  { value: "Europe/Skopje", option: "Europe/Skopje" },
  { value: "Europe/Sofia", option: "Europe/Sofia" },
  { value: "Europe/Stockholm", option: "Europe/Stockholm" },
  { value: "Europe/Tallinn", option: "Europe/Tallinn" },
  { value: "Europe/Tirane", option: "Europe/Tirane" },
  { value: "Europe/Ulyanovsk", option: "Europe/Ulyanovsk" },
  { value: "Europe/Uzhgorod", option: "Europe/Uzhgorod" },
  { value: "Europe/Vaduz", option: "Europe/Vaduz" },
  { value: "Europe/Vatican", option: "Europe/Vatican" },
  { value: "Europe/Vienna", option: "Europe/Vienna" },
  { value: "Europe/Vilnius", option: "Europe/Vilnius" },
  { value: "Europe/Volgograd", option: "Europe/Volgograd" },
  { value: "Europe/Warsaw", option: "Europe/Warsaw" },
  { value: "Europe/Zagreb", option: "Europe/Zagreb" },
  { value: "Europe/Zaporozhye", option: "Europe/Zaporozhye" },
  { value: "Europe/Zurich", option: "Europe/Zurich" },
  { value: "GMT", option: "GMT" },
  { value: "Indian/Antananarivo", option: "Indian/Antananarivo" },
  { value: "Indian/Chagos", option: "Indian/Chagos" },
  { value: "Indian/Christmas", option: "Indian/Christmas" },
  { value: "Indian/Cocos", option: "Indian/Cocos" },
  { value: "Indian/Comoro", option: "Indian/Comoro" },
  { value: "Indian/Kerguelen", option: "Indian/Kerguelen" },
  { value: "Indian/Mahe", option: "Indian/Mahe" },
  { value: "Indian/Maldives", option: "Indian/Maldives" },
  { value: "Indian/Mauritius", option: "Indian/Mauritius" },
  { value: "Indian/Mayotte", option: "Indian/Mayotte" },
  { value: "Indian/Reunion", option: "Indian/Reunion" },
  { value: "Pacific/Apia", option: "Pacific/Apia" },
  { value: "Pacific/Auckland", option: "Pacific/Auckland" },
  { value: "Pacific/Bougainville", option: "Pacific/Bougainville" },
  { value: "Pacific/Chatham", option: "Pacific/Chatham" },
  { value: "Pacific/Chuuk", option: "Pacific/Chuuk" },
  { value: "Pacific/Easter", option: "Pacific/Easter" },
  { value: "Pacific/Efate", option: "Pacific/Efate" },
  { value: "Pacific/Enderbury", option: "Pacific/Enderbury" },
  { value: "Pacific/Fakaofo", option: "Pacific/Fakaofo" },
  { value: "Pacific/Fiji", option: "Pacific/Fiji" },
  { value: "Pacific/Funafuti", option: "Pacific/Funafuti" },
  { value: "Pacific/Galapagos", option: "Pacific/Galapagos" },
  { value: "Pacific/Gambier", option: "Pacific/Gambier" },
  { value: "Pacific/Guadalcanal", option: "Pacific/Guadalcanal" },
  { value: "Pacific/Guam", option: "Pacific/Guam" },
  { value: "Pacific/Honolulu", option: "Pacific/Honolulu" },
  { value: "Pacific/Kiritimati", option: "Pacific/Kiritimati" },
  { value: "Pacific/Kosrae", option: "Pacific/Kosrae" },
  { value: "Pacific/Kwajalein", option: "Pacific/Kwajalein" },
  { value: "Pacific/Majuro", option: "Pacific/Majuro" },
  { value: "Pacific/Marquesas", option: "Pacific/Marquesas" },
  { value: "Pacific/Midway", option: "Pacific/Midway" },
  { value: "Pacific/Nauru", option: "Pacific/Nauru" },
  { value: "Pacific/Niue", option: "Pacific/Niue" },
  { value: "Pacific/Norfolk", option: "Pacific/Norfolk" },
  { value: "Pacific/Noumea", option: "Pacific/Noumea" },
  { value: "Pacific/Pago_Pago", option: "Pacific/Pago Pago" },
  { value: "Pacific/Palau", option: "Pacific/Palau" },
  { value: "Pacific/Pitcairn", option: "Pacific/Pitcairn" },
  { value: "Pacific/Pohnpei", option: "Pacific/Pohnpei" },
  { value: "Pacific/Port_Moresby", option: "Pacific/Port Moresby" },
  { value: "Pacific/Rarotonga", option: "Pacific/Rarotonga" },
  { value: "Pacific/Saipan", option: "Pacific/Saipan" },
  { value: "Pacific/Tahiti", option: "Pacific/Tahiti" },
  { value: "Pacific/Tarawa", option: "Pacific/Tarawa" },
  { value: "Pacific/Tongatapu", option: "Pacific/Tongatapu" },
  { value: "Pacific/Wake", option: "Pacific/Wake" },
  { value: "Pacific/Wallis", option: "Pacific/Wallis" },
  { value: "US/Alaska", option: "US/Alaska" },
  { value: "US/Arizona", option: "US/Arizona" },
  { value: "US/Central", option: "US/Central" },
  { value: "US/Eastern", option: "US/Eastern" },
  { value: "US/Hawaii", option: "US/Hawaii" },
  { value: "US/Mountain", option: "US/Mountain" },
  { value: "US/Pacific", option: "US/Pacific" },
  { value: "UTC", option: "UTC" }
];

export default timezones;
