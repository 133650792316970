import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Link from "@material-ui/core/Link";
import Check from "@material-ui/icons/Check";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import NumberFormat from "react-number-format";
import MerchantInfoCard from "./MerchantInfoCard";
import useStyles from "../styles";

const LoanInfoCard = ({ copy, loan }) => {
  const classes = useStyles();

  return (
    <>
      {/* -----Loan Section----- */}
      <Card raised elevation="4" className={classes.innerCard}>
        <CardHeader
          title={
            <Typography
              variant="body1"
              color="primary"
              className={classes.innerCardHeader}
            >
              {copy.line9}
            </Typography>
          }
          className={classes.offerCardHeader}
        />
        <CardContent className={classes.innerCardContent}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body1" className={classes.cardHeader}>
                {copy.line5}
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                className={classes.largeSubHeader}
                style={{ marginBottom: 10 }}
              >
                <NumberFormat
                  value={loan.funded_amount}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </Typography>

              {loan.status === "funded" && (
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Check color="secondary" />
                  {loan.date_funded ? (
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ marginLeft: 5, marginTop: 5 }}
                      className={classes.cardCase}
                    >
                      {copy.line7b}
                      <br />
                      {moment(loan.date_funded).format("DD-MM-YYYY")}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      color="primary"
                      style={{ marginLeft: 5, marginTop: 5 }}
                      className={classes.cardCase}
                    >
                      {copy.line7}
                    </Typography>
                  )}
                </div>
              )}

              {loan.status === "declined" && (
                <Typography
                  variant="caption"
                  color="error"
                  className={classes.cardCase}
                >
                  {copy.line8}
                </Typography>
              )}

              {loan.status !== "funded" && loan.status !== "declined" && (
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.cardCase}
                >
                  {copy.line2}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <div
                style={{
                  width: "100%",
                  textAlign: "center"
                }}
              >
                <div style={{ margin: "10px auto 20px" }}>
                  {loan.originator.image_url !== null ? (
                    <img
                      alt="Lender Logo"
                      title="Lender Logo"
                      src={loan.originator.image_url}
                    />
                  ) : (
                    <Typography
                      variant="h2"
                      align="center"
                      className={classes.header}
                    >
                      {loan.originator.name}
                    </Typography>
                  )}
                </div>

                <Link href={null} underline="none">
                  <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="Submit"
                    className={classes.cardButton}
                  >
                    <span style={{ fontSize: "0.7rem" }}>{copy.line3}</span>
                  </Fab>
                </Link>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* -----Merchant Section----- */}
      {loan.merchant && (
        <MerchantInfoCard merchant={loan.merchant} copy={copy.line4} />
      )}
    </>
  );
};

LoanInfoCard.propTypes = {
  loan: PropTypes.object.isRequired,
  copy: PropTypes.object.isRequired
};

export default LoanInfoCard;
