import { Lead, Token } from "../api/Agent";

export const CLEAR_LEAD = "CLEAR_LEAD";
export const SET_LEAD_LOADING = "SET_LEAD_LOADING";
export const SET_LEAD_SUCCESS = "SET_LEAD_SUCCESS";
export const SET_LEAD_ERROR = "SET_LEAD_ERROR";
export const GET_LEAD_LOADING = "GET_LEAD_LOADING";
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS";
export const GET_LEAD_ERROR = "GET_LEAD_ERROR";
export const GET_LEAD_ID_LOADING = "GET_LEAD_ID_LOADING";
export const GET_LEAD_ID_SUCCESS = "GET_LEAD_ID_SUCCESS";
export const GET_LEAD_ID_ERROR = "GET_LEAD_ID_ERROR";
// export const PING_LOADING = "PING_LOADING";
// export const PING_SUCCESS = "PING_SUCCESS";
// export const PING_ERROR = "PING_ERROR";
export const REAPPLY_LEAD_LOADING = "REAPPLY_LEAD_LOADING";
export const REAPPLY_LEAD_SUCCESS = "REAPPLY_LEAD_SUCCESS";
export const REAPPLY_LEAD_ERROR = "REAPPLY_LEAD_ERROR";
export const PUT_LEAD_LOADING = "PUT_LEAD_LOADING";
export const PUT_LEAD_SUCCESS = "PUT_LEAD_SUCCESS";
export const PUT_LEAD_ERROR = "PUT_LEAD_ERROR";
export const PATCH_LEAD_LOADING = "PATCH_LEAD_LOADING";
export const PATCH_LEAD_SUCCESS = "PATCH_LEAD_SUCCESS";
export const PATCH_LEAD_ERROR = "PATCH_LEAD_ERROR";

export const clearLead = () => ({
  type: CLEAR_LEAD
});

export const setLead = lead => async dispatch => {
  dispatch({
    type: SET_LEAD_LOADING
  });
  try {
    const response = await Lead.create(lead);
    dispatch({
      type: SET_LEAD_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: SET_LEAD_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const getLead = () => async dispatch => {
  dispatch({
    type: GET_LEAD_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Lead.get();
    dispatch({
      type: GET_LEAD_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_LEAD_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

// export const pingLead = id => async dispatch => {
//   dispatch({
//     type: PING_LOADING
//   });
//   try {
//     await Token.set(sessionStorage.getItem("jwt"));
//     await Lead.ping(id);
//     console.log("pinged");
//     // const pendingLead = setInterval(async () => {
//     //   const newResponse = await Lead.get();
//     //   if (
//     //     newResponse.results[0].status !== "initial" ||
//     //     newResponse.results[0].status !== "pending" ||
//     //     newResponse.results[0].status !== "processing"
//     //   ) {
//     //     dispatch({
//     //       type: PING_SUCCESS,
//     //       payload: newResponse
//     //     });
//     //     clearInterval(pendingLead);
//     //   }
//     //   console.log("get lead");
//     // }, 1000);
//     const newResponse = await Lead.get();
//     console.log("get lead");
//     dispatch({
//       type: PING_SUCCESS,
//       payload: newResponse
//     });
//   } catch (error) {
//     dispatch({
//       type: PING_ERROR,
//       payload: JSON.parse(error.response.text)
//     });
//   }
// };

// export const getLead = () => async dispatch => {
//   dispatch({
//     type: GET_LEAD_LOADING
//   });
//   try {
//     await Token.set(sessionStorage.getItem("jwt"));
//     const response = await Lead.get();
//     await Lead.ping(response.results[0].id);
//     const pendingLead = setInterval(async () => {
//       const newResponse = await Lead.get();
//       if (newResponse.results[0].status !== "processing") {
//         dispatch({
//           type: GET_LEAD_SUCCESS,
//           payload: newResponse
//         });
//         clearInterval(pendingLead);
//       }
//     }, 1000);
//   } catch (error) {
//     dispatch({
//       type: GET_LEAD_ERROR,
//       payload: JSON.parse(error.response.text)
//     });
//   }
// };

export const reapplyLead = id => async dispatch => {
  dispatch({
    type: REAPPLY_LEAD_LOADING
  });
  await Token.set(sessionStorage.getItem("jwt"));
  const response = await Lead.reapply(id);
  dispatch({
    type: REAPPLY_LEAD_SUCCESS,
    payload: response
  });
};

export const getLeadById = id => async dispatch => {
  dispatch({
    type: GET_LEAD_ID_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Lead.getById(id);
    dispatch({
      type: GET_LEAD_ID_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_LEAD_ID_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const putLead = (id, lead) => async dispatch => {
  dispatch({
    type: PUT_LEAD_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Lead.put(id, lead);
    dispatch({
      type: PUT_LEAD_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: PUT_LEAD_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const patchLead = (id, lead) => async dispatch => {
  dispatch({
    type: PATCH_LEAD_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Lead.patch(id, lead);
    dispatch({
      type: PATCH_LEAD_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: PATCH_LEAD_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};
