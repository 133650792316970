/* eslint-disable consistent-return */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { RenderTextField } from "../../utlis/input";
import { required, composeValidators, PhoneField } from "./utils";
import { Email, Phone } from "../../api/Agent";

const emailValidation = async (value) => {
  try {
    await Email.validate({ email: value });
  } catch (error) {
    if (error.response) return JSON.parse(error.response.text).email[0];
  }
};

const phoneValidation = async (value) => {
  try {
    await Phone.validate({ number: value });
  } catch (error) {
    if (error.response) return JSON.parse(error.response.text).number[0];
  }
};

const Page5 = ({ name, copy, auth, header, setPasswordValid }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
    setPasswordValid(true);
  }, [setPasswordValid]);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line2} {name}
      </Typography>
      <Typography variant="body1" color="primary" className={classes.text}>
        {copy.line3}
      </Typography>

      <Field
        name="phone"
        id="phone"
        type="tel"
        component={RenderTextField}
        label="Phone Number"
        validate={
          auth.isAuth ? required : composeValidators(required, phoneValidation)
        }
        InputProps={{
          inputComponent: PhoneField
        }}
      />
      <Error name="phone" {...classes} />
      <Field
        name="email"
        id="email"
        type="email"
        component={RenderTextField}
        label="Email"
        validate={
          auth.isAuth ? required : composeValidators(required, emailValidation)
        }
      />
      <Error name="email" {...classes} />
    </Wizard.Page>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired
};

Page5.defaultProps = {
  name: ""
};

Page5.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  setPasswordValid: PropTypes.func.isRequired
};

export default Page5;
