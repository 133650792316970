import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import useStyles from "./styles";

const Page3 = ({ copy }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wizard.Page>
      <Typography color="primary" className={classes.largeHeader}>
        {copy.line1}
      </Typography>
      <Typography className={classes.subHeader} style={{ fontSize: "1.5rem" }}>
        {copy.line2}
      </Typography>
      <Typography
        variant="body1"
        className={classes.text}
        style={{ marginBottom: 50 }}
      >
        {copy.line3}
      </Typography>
    </Wizard.Page>
  );
};

Page3.propTypes = {
  copy: PropTypes.object.isRequired
};

export default Page3;
