/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import formatString from "format-string-by-pattern";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { RenderTextField } from "../../utlis/input";
import { required, composeValidators } from "./utils";

const minValue = min => value => {
  if (
    !moment(value, "YYYY-MM-DD", true)._isValid &&
    moment(value, "MM-DD-YYYY").format("YYYY-MM-DD") === "Invalid date"
  ) {
    return "Enter a valid date.";
  }

  if (value.length >= 10) {
    const newDate = new Date().getFullYear() - parseInt(value.slice(-4), 10);
    return newDate >= min ? undefined : `Must be at least ${min} years old.`;
  }
  return "Required";
};

const Page4 = ({ copy, setPasswordValid, header }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setPasswordValid(true);
  }, [setPasswordValid]);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line2}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line3}
      </Typography>
      <Field
        name="first_name"
        id="first_name"
        type="text"
        component={RenderTextField}
        label="First Name"
        validate={required}
      />
      <Error name="first_name" {...classes} />
      <Field
        name="last_name"
        id="last_name"
        type="text"
        component={RenderTextField}
        label="Last Name"
        validate={required}
      />
      <Error name="last_name" {...classes} />
      <Field
        name="dob"
        id="dob"
        type="tel"
        component={RenderTextField}
        label="Date of Birth (mm-dd-yyyy)"
        parse={formatString("99-99-9999")}
        validate={composeValidators(required, minValue(18))}
      />
      <Error name="dob" {...classes} />
    </Wizard.Page>
  );
};

Page4.propTypes = {
  copy: PropTypes.object.isRequired,
  setPasswordValid: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired
};

export default Page4;
