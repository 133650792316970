/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import smoothscroll from "smoothscroll-polyfill";
import Error from "../Error";
import useStyles from "../styles";
import { RenderTextField } from "../../utlis/input";
import { required, PhoneField } from "../form/utils";
import { Phone, User } from "../../api/Agent";
import { getUser } from "../../actions";

const PhoneForm = ({ copy, ...user }) => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [result, setResult] = useState({});
  const [validation, setValidation] = useState(false);

  const dispatch = useDispatch();

  const submitPrimary = (phone) => {
    async function fetchData() {
      try {
        const res = await Phone.makePrimary(phone.id, phone);
        setResult(res);
        setError("");
      } catch (error) {
        setError(error.response.text);
        setValidation(true);
      }
    }
    fetchData();
  };

  const onSubmit = async (values) => {
    const newPhone = values.phone
      .replace(/[^\d]+/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    const phoneObj = {
      user,
      primary: false,
      number: newPhone
    };
    try {
      const res = await User.createPhoneNumber(phoneObj);
      setResult(res);
      setError("");
    } catch (error) {
      setError(JSON.parse(error.response.text));
      setValidation(false);
    }
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (Object.getOwnPropertyNames(result).length !== 0) {
        dispatch(getUser());
        setValidation(true);
        setResult({});
      }
    }
    fetchData();
  }, [dispatch, result]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h1"
              align="center"
              className={classes.settingHeader}
            >
              {copy.phone.line2}
            </Typography>
            <Field
              name="phone"
              id="phone"
              type="tel"
              component={RenderTextField}
              label="Phone Number"
              validate={required}
              InputProps={{
                inputComponent: PhoneField
              }}
            />
            <Error name="phone" {...classes} />

            {validation && (
              <Typography
                variant="caption"
                color="secondary"
                align="center"
                display="block"
              >
                {copy.phone.line3}
              </Typography>
            )}

            {error &&
              error.number.map((err, key) => (
                <Typography
                  variant="caption"
                  color="error"
                  align="center"
                  display="block"
                  key={key}
                >
                  {err}
                </Typography>
              ))}

            <Fab
              variant="extended"
              aria-label="Submit"
              color="secondary"
              type="submit"
              disabled={invalid || submitting}
              className={classes.submitButton}
            >
              {copy.button}
            </Fab>
          </form>
        )}
      />
      <Typography variant="h1" align="center" className={classes.settingHeader}>
        {copy.phone.line1}
      </Typography>
      {user.phones &&
        user.phones.map((phone, key) => (
          <div key={key}>
            <Typography
              variant="body1"
              align="center"
              className={classes.subHeader}
            >
              {phone.number}
            </Typography>

            {phone.primary ? (
              <Typography
                variant="body2"
                align="center"
                style={{ margin: "20px auto", fontWeight: 600 }}
                color="secondary"
              >
                {copy.primary}
              </Typography>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "20px auto"
                }}
              >
                <Fab
                  variant="extended"
                  aria-label="Submit"
                  type="submit"
                  onClick={() => submitPrimary(phone)}
                  className={classes.primaryLink}
                >
                  {copy.primaryLink}
                </Fab>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

PhoneForm.propTypes = {
  copy: PropTypes.object.isRequired
};

export default PhoneForm;
