/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import smoothscroll from "smoothscroll-polyfill";
import { editUserSettings, clearFetchState } from "../../actions";
import useStyles from "../styles";

const PasswordForm = ({ copy, ...user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");
  const [checked, setChecked] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const [initState, setInitState] = useState(false);

  const handleCheckBoxChange = event => {
    setChecked(event.target.checked);
  };

  const handleCurrentPasswordChange = event => {
    setCurrentPassword(event.target.value);
  };

  const handlePassword1Change = event => {
    setPassword1(event.target.value);
  };

  const handlePassword2Change = event => {
    setPassword2(event.target.value);
  };

  const handleBlur = event => {
    setInitState(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const newUser = {
      current_password: currentPassword,
      password: password1
    };
    dispatch(await editUserSettings(user.username, newUser));
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (checked) setPasswordType("text");
    else if (!checked) setPasswordType("password");

    if (
      user.fetchState !== "Success" ||
      user.fetchState !== "User Updated Successfully" ||
      user.fetchState !== "Loading"
    )
      setError(true);

    if (
      currentPassword === "" ||
      password1 === "" ||
      password2 === "" ||
      password1 !== password2
    ) {
      setError(false);
      dispatch(clearFetchState());
    }
  }, [
    checked,
    currentPassword,
    dispatch,
    password1,
    password2,
    user.fetchState
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h1" align="center" className={classes.settingHeader}>
        {copy.password}
      </Typography>
      <TextField
        id="currentPassword"
        type={passwordType}
        variant="outlined"
        fullWidth
        label="Current Password"
        value={currentPassword}
        onChange={handleCurrentPasswordChange}
        onBlur={handleBlur}
        style={{ marginBottom: 30 }}
      />

      {currentPassword === "" && initState && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          display="block"
          className={classes.error}
        >
          Required Field
        </Typography>
      )}
      {error &&
        user.fetchState.hasOwnProperty("current_password") &&
        user.fetchState.current_password.map((error, key) => (
          <Typography
            variant="caption"
            color="error"
            align="center"
            display="block"
            className={classes.error}
            key={key}
          >
            {error}
          </Typography>
        ))}

      <TextField
        id="password1"
        type={passwordType}
        variant="outlined"
        fullWidth
        label="Current Password"
        value={password1}
        onChange={handlePassword1Change}
        onBlur={handleBlur}
        style={{ marginBottom: 30 }}
      />

      {password1 === "" && initState && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          display="block"
          className={classes.error}
        >
          Required Field
        </Typography>
      )}

      <TextField
        id="password2"
        type={passwordType}
        variant="outlined"
        fullWidth
        label="Current Password"
        value={password2}
        onChange={handlePassword2Change}
        onBlur={handleBlur}
        style={{ marginBottom: 30 }}
      />

      {password2 === "" && initState && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          display="block"
          className={classes.error}
        >
          Required Field
        </Typography>
      )}
      {password2 !== password1 && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          display="block"
          className={classes.error}
        >
          Passwords do not match
        </Typography>
      )}
      {error &&
        user.fetchState.hasOwnProperty("password") &&
        user.fetchState.password.map((error, key) => (
          <Typography
            variant="caption"
            color="error"
            align="center"
            display="block"
            className={classes.error}
            key={key}
          >
            {error}
          </Typography>
        ))}

      {user.fetchState === "User Updated Successfully" && (
        <Typography
          variant="caption"
          color="secondary"
          align="center"
          display="block"
          className={classes.error}
        >
          Password Updated Successfully
        </Typography>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckBoxChange}
            value="checked"
          />
        }
        label="Show Password"
        style={{ marginBottom: 30 }}
      />
      <Fab
        variant="extended"
        aria-label="Submit"
        color="secondary"
        type="submit"
        disabled={!error}
        className={classes.submitButton}
      >
        {copy.button}
      </Fab>
    </form>
  );
};

PasswordForm.propTypes = {
  copy: PropTypes.object.isRequired
};

export default PasswordForm;
