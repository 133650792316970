/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/styles";
import LogRocket from "logrocket";
import WebFont from "webfontloader";
import ErrorBoundary from "./components/ErrorBoundary";
import AnalyticsWrapper from "./components/AnalyticsWrapper";
import FrontChat from "./components/FrontChat";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import theme from "./utlis/theme";
import indexRoutes from "./routes";
import history from "./myHistory";
import { logRocket_url } from "./utlis/sentry";
import { Helmet } from 'react-helmet';

const store = configureStore();

WebFont.load({
  google: {
    families: ["Lato", "sans-serif"]
  }
});

if (
  window.location.hostname === "www.paypossible.com" ||
  window.location.hostname === "paypossible.com"
) {
  LogRocket.init(logRocket_url);
}

function renderHelmet() {
    if (process.env.REACT_APP_FE === "enable") {
      return (
          <Helmet>
              <link rel="shortcut icon" href="enableFav.ico" />
              <meta name="description" content="Enable Financing Customer Application" />
              <link rel="apple-touch-icon" href="enableTouchIcon.png" />
              <title>Apply | Enable Financing</title>
          </Helmet>
      )
    } else {
        return (
            <>
                <Helmet>
                    <link rel="shortcut icon" href="payPossibleFav.ico" />
                    <meta name="description" content="PayPossible Customer Application" />
                    <link rel="apple-touch-icon" href="payPossibleTouchIcon.png" />
                    <title>Apply | PayPossible</title>
                </Helmet>
                <FrontChat chatId="6dfd7205a01d14892918f06f6840fd02" />
            </>
        );

    }

  }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__persistor} loading={null}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <AnalyticsWrapper page="app-customer">
            {renderHelmet()}
            <Router history={history}>
              <Switch>
                {indexRoutes.map((prop, key) => {
                  return (
                    <Route
                      exact
                      path={prop.path}
                      key={key}
                      component={prop.component}
                    />
                  );
                })}
              </Switch>
            </Router>
          </AnalyticsWrapper>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
