/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import smoothscroll from "smoothscroll-polyfill";
import Error from "./Error";
import Footer from "./layout/Footer";
import history from "../myHistory";
import useStyles from "./styles";
import { RenderTextField } from "../utlis/input";
import { required, composeValidators } from "./form/utils";
import { Email } from "../api/Agent";
import payPossibleLogo from "../images/payPossibleFooterLogo.png";
import enableLogo from "../images/enableLogo.png";

import {
  login,
  getUser,
  clearAuth,
  clearPing,
  clearLead,
  clearLoan,
  clearMerchant,
  clearUser,
  clearOrder
} from "../actions";

import copyEn from "../utlis/copyEn";
import copyEnable from "../utlis/copyEnable";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn;
const logo = (process.env.REACT_APP_FE === "enable") ? enableLogo : payPossibleLogo;


const emailValidation = (value) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return "Invalid email address";
  }
};

const SignIn = () => {
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [verify, setVerify] = useState(false);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onSubmit = async (values) => {
    dispatch(login(values.email, values.password)).then((res) => {
      dispatch(getUser());
    });
    setEmail(values.email);
  };

  useEffect(() => {
    smoothscroll.polyfill();
    dispatch(clearAuth());
    dispatch(clearPing());
    dispatch(clearLead());
    dispatch(clearLoan());
    dispatch(clearMerchant());
    dispatch(clearUser());
    dispatch(clearOrder());
    sessionStorage.removeItem("jwt");
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function verifyEmail() {
      await Email.sendConfirmation(email);
    }

    if (checked) setPasswordType("text");
    else if (!checked) setPasswordType("password");

    if (auth.fetchState === "Error") setApiErrors(auth.non_field_errors);

    if (auth.fetchState === "Success" && user.emails) {
      user.emails.forEach((emailEl) => {
        if (emailEl.id === email && emailEl.verified === true)
          history.push("account");
        else {
          setApiErrors([]);
          setVerify(true);
        }
      });
    }

    if (verify) {
      // --- send confirm password email---
      verifyEmail();
      dispatch(clearAuth());
      dispatch(clearLead());
      dispatch(clearMerchant());
      dispatch(clearUser());
      sessionStorage.removeItem("jwt");
    }
  }, [
    auth.fetchState,
    auth.non_field_errors,
    checked,
    dispatch,
    email,
    user.emails,
    verify
  ]);

  return (
    <>
      {auth.fetchState === "Loading" ||
        (user.fetchState === "Loading" && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              textAlign: "center",
              width: "100%",
              top: "30vh"
            }}
          >
            <CircularProgress color="secondary" size="120px" thickness={1} />
          </div>
        ))}
      <div className={classes.wizard} style={{ minHeight: "100vh" }}>
        <Card raised className={classes.card}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, invalid, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Link
                  color="secondary"
                  href={`${process.env.PUBLIC_URL}/`}
                  underline="none"
                >
                  <img
                    alt="Logo"
                    title="Logo"
                    src={logo}
                    style={{
                      maxWidth: 180,
                      margin: "20px auto 40px",
                      display: "block"
                    }}
                  />
                </Link>
                <Typography
                  variant="h1"
                  align="center"
                  className={classes.header}
                  style={{ fontWeight: 600 }}
                >
                  {copy.signin.line1}
                </Typography>

                <Field
                  name="email"
                  id="email"
                  type="email"
                  component={RenderTextField}
                  label="Email"
                  validate={composeValidators(required, emailValidation)}
                />
                <Error name="email" {...classes} />

                <Field
                  name="password"
                  id="password"
                  type={passwordType}
                  component={RenderTextField}
                  label="Password"
                  validate={required}
                />
                <Error name="password" {...classes} />
                {apiErrors &&
                  apiErrors.length > 0 &&
                  apiErrors.map((error, key) => (
                    <Typography
                      variant="caption"
                      color="error"
                      align="center"
                      display="block"
                      className={classes.error}
                      key={key}
                    >
                      {error}
                    </Typography>
                  ))}

                {apiErrors && apiErrors.length <= 0 && verify && (
                  <>
                    <Typography
                      variant="body1"
                      color="error"
                      align="center"
                      display="block"
                      className={classes.error}
                      style={{ marginBottom: 30 }}
                    >
                      {copy.signin.line3}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="error"
                      align="center"
                      display="block"
                      className={classes.error}
                    >
                      {copy.signin.line4}
                    </Typography>
                  </>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      value="checked"
                    />
                  }
                  label="Show Password"
                  style={{ marginBottom: 30 }}
                />
                <Fab
                  variant="extended"
                  aria-label="Submit"
                  color="secondary"
                  type="submit"
                  disabled={invalid || submitting || pristine}
                  className={classes.submitButton}
                >
                  {copy.signin.button}
                </Fab>
                <Link
                  color="secondary"
                  href={`${process.env.REACT_APP_SITE}/account/forgot-password`}
                  underline="none"
                >
                  <Typography
                    variant="body2"
                    align="center"
                    display="block"
                    className={classes.link}
                  >
                    {copy.signin.line2}
                  </Typography>
                </Link>
              </form>
            )}
          />
        </Card>
      </div>
      <Footer logo={logo} />
    </>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired
};

export default SignIn;
