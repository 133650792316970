const coBrand = {
  ppb: ["peakpb"],
  aurora: ["aurora"],
  curated: ["curated", "ingaugelabs"],
  zipLoans: [
    "healme",
    "mainline",
    "topsecret",
    "wheelcity",
    "qcustom",
    "offroad",
    "greenleaf",
    "nicefence",
    "paramount",
    "carolinafandc",
    "vipgroup",
    "roofingpro",
    "truecare",
    "coolair",
    "kinley",
    "hotwheels",
    "nice-smile",
    "toplegal",
    "handyvets",
    "floorsunlimited",
    "majestic",
    "goldndiamonds",
    "adamsauto",
    "adreamhome",
    "americanreno",
    "blaylock1",
    "carolinafloor",
    "champion",
    "cjrauto",
    "climate",
    "csi",
    "dandd",
    "davisroofing",
    "greatsmile",
    "detroitmec",
    "drysky",
    "faircloth",
    "flooringclearance",
    "highspeed",
    "housetop",
    "hydra",
    "jcstrailer",
    "nyjewelers",
    "servpronc",
    "mmflooring",
    "motoonmain",
    "ncshed",
    "pelagio",
    "911restore",
    "piedmont",
    "pistolpops",
    "powellsta",
    "presa",
    "racehead",
    "rexfloors",
    "stanly",
    "roofingguy",
    "ziploan",
    "goldman",
    "viva",
    "warrenhearing",
    "waterworks",
    "atlas"
  ],
  autoSales: ["qualitycars"],
  floridaPaver: ["flpaver", "getfinancingtest"],
  mapToClients: ["bizcoach"],
  innateHealthcare: ["innate"],
  kiserArenaSpecialist: ["kiser"],
  inspiredIdeaSolutions: ["wdcarroll", "inspired"],
  justiceTax: ["justicetax"],
  fitnessGrind: ["fitnessgrind"],
  valliani: [
    "valliani-greatmall",
    "valliani-westfield",
    "valliani-eastridge",
    "valliani-sunvalley",
    "valliani-northridge",
    "valliani-newpark",
    "valliani-serramonte",
    "valliani-vintagefaire",
    "valliani-westvalley",
    "valliani-fashionfair",
    "valliani-tanforan",
    "valliani-southland",
    "valliani-sherwood",
    "valliani-visalia",
    "valliani-valleyfair"
  ],
  lightRx: [
    "lrx-prequalified",
    "lrx-mesa",
    "lrx-coloradosprings",
    "lrx-chicago",
    "lrx-hoffman",
    "lrx-naperville",
    "lrx-orlandpark",
    "lrx-indianapolis",
    "lrx-louisville",
    "lrx-towson",
    "lrx-birmingham",
    "lrx-canton",
    "lrx-dearborn",
    "lrx-grandblanc",
    "lrx-novi",
    "lrx-okemos",
    "lrx-troy",
    "lrx-stlouis",
    "lrx-charlotte",
    "lrx-summerlin",
    "lrx-pittsburgh",
    "lrx-greenville",
    "lrx-knoxville",
    "lrx-stoneoak",
    "lrx-alexandria",
    "lrx-virginiabeach"
  ],
  kingWheel: [
    "kingwheel5",
    "kingwheel2",
    "kingwheel4",
    "kingwheel7",
    "kingwheel1",
    "kingwheel3"
  ],
  abc: ["abcsolutions"],
  transMedCare: ["transmedcare"],
  leCaprice: ["lecaprice"],
  budgetBlinds: ["budgetblinds"],
  everywhere: ["everywhere"],
  trinityProximity: ["trinityproximity"],
  medevateHealth: ["medevatehealth"],
  connellyPhoto: ["connellyphoto"],
  zg: ["zerogravity"],
  diamondSolutions: ["diamondsolutions360"],
  clearVision: ["clearvision"],
  theoutfit: ["theoutfit"],
  elegantOccasions: ["elegantoccasions", "lachri"],
  writeChoice: ["writechoiceconsult"],
  parkOutdoor: ["parkoutdoorfurn"],
  incrediHome: ["incredihome"],
  bigOffice: ["bosstore"],
  squareOne: ["squareone"],
  sfsbi: ["sfsbi"],
  nbc: ["nbc", "nbcservices"],
  bettyStudios: ["myphotos"],
  blockLegal: ["blocklegal"],
  customCoach: ["customcoach"],
  xFinity: ["xfinitymoving"],
  absolute: ["absoluteairflow"],
  springpools: ["springpools"],
  turfcenter: ["turfcenter"],
  rij: [
    "rij-perkins",
    "rij-bartlett",
    "rij-southaven",
    "rij-wolfchase",
    "rij-littlerock"
  ],
  eCells: ["ecells"],
  rok: ["rokfi", "rokfi-services", "hmcbride", "rpuri"],
  ayr: ["advanceyourreach"],
  weldscholars: ["weldscholars", "weldscholarsllc"],
  mindfulhabitcoaching: ["mindfulhabitcoaching"],
  recoverycircle: ["recoverycircle"],
  garnerfinancial: ["garnerfinancial"],
};

export default coBrand;
