import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import smoothscroll from "smoothscroll-polyfill";
import { RenderTextField } from "../../utlis/input";
import Wizard from "../Wizard";
import useStyles from "./styles";


const Page1 = ({ error, copy, merchantFetchState }) => {
    const classes = useStyles();

    useEffect(() => {
        smoothscroll.polyfill();
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <Wizard.Page>
            <Typography
                variant="h3"
                align="center"
                className={classes.header}
                style={{ marginBottom: "5vh" }}
            >
                {copy.line1}
            </Typography>
            <Typography
                color="primary"
                variant="h1"
                className={classes.largeHeader}
                style={{ lineHeight: "2.5rem" }}
            >
                {copy.line2}
            </Typography>
            <Typography
                className={classes.subHeader}
                variant="h1"
                style={{ fontSize: "1.5rem" }}
            >
                {copy.line3}
            </Typography>
            <Typography
                variant="body1"
                className={classes.text}
                style={{ marginBottom: 30 }}
            >
                {copy.line4}
            </Typography>
            <Field
                name="payId"
                id="payId"
                type="text"
                component={RenderTextField}
                label={copy.idTitle}
            />
            <Typography
                variant="body2"
                align="center"
                display="block"
                className={classes.fieldDisclaimer}
            >
                {copy.line5}
            </Typography>
            {merchantFetchState === "Loading" && (
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        margin: "-85px auto 5px"
                    }}
                >
                    <CircularProgress color="secondary" size="120px" thickness={1} />
                </div>
            )}
            {error && (
                <Typography
                    variant="caption"
                    color="error"
                    align="center"
                    display="block"
                    className={classes.error}
                >
                    {error}
                </Typography>
                )}
        </Wizard.Page>
    );
};

Error.propTypes = {
    name: PropTypes.string.isRequired
};

Page1.propTypes = {
    error: PropTypes.any.isRequired,
    copy: PropTypes.object.isRequired,
    merchantFetchState: PropTypes.string.isRequired
};

export default Page1;
