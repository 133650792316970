/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Error from "./Error";
import Footer from "./layout/Footer";
import payPossibleLogo from "../images/payPossibleFooterLogo.png";
import useStyles from "./styles";
import { RenderTextField } from "../utlis/input";
import { required, composeValidators } from "./form/utils";
import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const emailValidation = value => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return "Invalid email address";
  }
};

const ResetPassword = () => {
  const classes = useStyles();

  const [apiErrors] = useState([]);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  const onSubmit = async values => {
    // dispatch(login(values.email, values.password)).then(res => {
    //   dispatch(getUser());
    // });
    console.log(values);
  };

  return (
    <>
      <div className={classes.wizard} style={{ minHeight: "100vh" }}>
        <Card raised className={classes.card}>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, invalid, submitting, pristine }) => (
              <form onSubmit={handleSubmit}>
                <Link
                  color="secondary"
                  href={`${process.env.PUBLIC_URL}/`}
                  underline="none"
                >
                  <img
                    alt="Paypossible Logo"
                    title="Paypossible Logo"
                    src={payPossibleLogo}
                    style={{
                      maxWidth: 180,
                      margin: "20px auto 40px",
                      display: "block"
                    }}
                  />
                </Link>
                <Typography
                  variant="h1"
                  align="center"
                  className={classes.header}
                  style={{ fontWeight: 600 }}
                >
                  {copy.reset.line1}
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ marginBottom: 30 }}
                >
                  {copy.reset.line2}
                </Typography>

                <Field
                  name="email"
                  id="email"
                  type="email"
                  component={RenderTextField}
                  label="Email"
                  validate={composeValidators(required, emailValidation)}
                />
                <Error name="email" {...classes} />

                {apiErrors &&
                  apiErrors.length > 0 &&
                  apiErrors.map((error, key) => (
                    <Typography
                      variant="caption"
                      color="error"
                      align="center"
                      display="block"
                      className={classes.error}
                      key={key}
                    >
                      {error}
                    </Typography>
                  ))}

                {!apiErrors && (
                  <Typography
                    variant="caption"
                    color="secondary"
                    align="center"
                    display="block"
                    className={classes.error}
                  >
                    {copy.reset.line4}
                  </Typography>
                )}

                <Fab
                  variant="extended"
                  aria-label="Submit"
                  color="secondary"
                  type="submit"
                  disabled={invalid || submitting || pristine}
                  className={classes.submitButton}
                  style={{ marginTop: 30 }}
                >
                  {copy.reset.button}
                </Fab>

                <Typography
                  variant="caption"
                  align="center"
                  display="block"
                  color="primary"
                  style={{ marginBottom: 5 }}
                >
                  {copy.reset.line3}
                </Typography>
                <Link color="secondary" href="/sign-in" underline="none">
                  <Typography
                    variant="body1"
                    align="center"
                    display="block"
                    className={classes.link}
                  >
                    {copy.reset.line5}
                  </Typography>
                </Link>
              </form>
            )}
          />
        </Card>
      </div>
      <Footer logo={payPossibleLogo} />
    </>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired
};

export default ResetPassword;
