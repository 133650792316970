import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Fade from "react-reveal/Fade";
import useStyles from "../styles";

const OfferDetailCard = ({
  filterLoanCard,
  setOffer,
  setPage,
  setViewAll,
  copy,
  offer1,
  offer2,
  setOpen,
  ...offer
}) => {
  const classes = useStyles();

  function handleDisplay(event) {
    filterLoanCard(offer.id);
    setViewAll(true);
  }

  function handleSelect(event) {
    setOffer(offer);
    setPage(2);
  }

  function handleOpen(event) {
    setOpen();
  }

  return (
    <Fade up distance="50px">
      <Card raised elevation={4} className={classes.innerCard}>
        <CardHeader
          title={
            <Typography
              variant="h1"
              align="center"
              className={classes.innerCardHeader}
            >
              {offer.term}{offer.originator.key === "lightstream" && ("*")}
            </Typography>
          }
          action={
            <IconButton
              aria-label="settings"
              className={classes.iconButton}
              onClick={handleDisplay}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          className={classes.offerCardHeader}
        />
        <CardContent className={classes.innerCardContent}>
          <Typography
            variant="body1"
            align="center"
            color="primary"
            className={classes.cardHeader}
            style={{ fontSize: "1.6rem", marginBottom: 30 }}
          >
            {offer.monthly_payment}
          </Typography>
          <Typography align="center" variant="body2" color="primary">
            {copy.line3}:{" "}
            <span className={classes.header} style={{ fontWeight: 600 }}>
              {offer.amount}
            </span>
          </Typography>
          <Typography align="center" variant="body2" color="primary">
            {copy.line4}:{" "}
            <span className={classes.header} style={{ fontWeight: 600 }}>
              {offer.apr}{offer.originator.key === "lightstream" && ("* w/AutoPay")}
            </span>
          </Typography>
          {offer.originator.image_url !== null && (
            <div style={{ width: "100%", textAlign: "center", marginTop: 30 }}>
              <img
                alt="Lender Logo"
                title="Lender Logo"
                src={offer.originator.image_url}
              />
            </div>
          )}
          {offer.originator.image_url === null && (
            <Typography
              variant="h3"
              align="center"
              className={classes.header}
              style={{ margin: "30px auto", fontWeight: 600 }}
            >
              {offer.originator.name}
            </Typography>
          )}
        </CardContent>

        <CardActions style={{ justifyContent: "space-around" }}>
          {offer1 && (
            <>
              <Fab
                variant="extended"
                aria-label="Submit"
                color="secondary"
                type="submit"
                onClick={handleSelect}
              >
                {copy.button1}
              </Fab>
            </>
          )}
          {offer2 && (
            <Fab
              variant="extended"
              aria-label="Submit"
              color="secondary"
              type="submit"
              onClick={handleOpen}
            >
              {offer.originator.name === "Finwise Bank"
                ? copy.button4
                : copy.button3}
            </Fab>
          )}
        </CardActions>
      </Card>
    </Fade>
  );
};

OfferDetailCard.defaultProps = {
  offer1: false,
  offer2: false,
  filterLoanCard: () => {},
  setOffer: () => {},
  setPage: () => {},
  setViewAll: () => {},
  setOpen: () => {}
};

OfferDetailCard.propTypes = {
  filterLoanCard: PropTypes.func,
  setOffer: PropTypes.func,
  setPage: PropTypes.func,
  setViewAll: PropTypes.func,
  copy: PropTypes.object.isRequired,
  offer1: PropTypes.bool,
  offer2: PropTypes.bool,
  setOpen: PropTypes.func
};

export default OfferDetailCard;
