import React from "react";
import App from "../components/App";
import AuthWrapper from "../components/AuthWrapper";
import SignIn from "../components/SignIn";
import ResetPassword from "../components/ResetPassword";
import Home from "../components/Home";
import Offers from "../components/Offers";
import Settings from "../components/Settings";
import Loan from "../components/Loan";
import Loans from "../components/Loans";
import Order from "../components/Order";
import Orders from "../components/Orders";
import Error from "./Error";

const WrappedHome = () => (
    <AuthWrapper>
        <Home />
    </AuthWrapper>
);

const WrappedOffers = () => (
    <AuthWrapper>
        <Offers />
    </AuthWrapper>
);

const WrapperSettings = () => (
    <AuthWrapper>
        <Settings />
    </AuthWrapper>
);

const WrappedLoan = () => (
    <AuthWrapper>
        <Loan />
    </AuthWrapper>
);

const WrappedLoans = () => (
    <AuthWrapper>
        <Loans />
    </AuthWrapper>
);

const WrappedOrder = () => (
    <AuthWrapper>
        <Order />
    </AuthWrapper>
);

const WrappedOrders = () => (
    <AuthWrapper>
        <Orders />
    </AuthWrapper>
);

/*
const AuthError = () => (
    <AuthWrapper>
        <Error />
    </AuthWrapper>
);
 */


const indexRoutes = [
    { path: `${process.env.PUBLIC_URL}/`, component: App },
    { path: `${process.env.PUBLIC_URL}/sign-in`, component: SignIn },
    {
        path: `${process.env.PUBLIC_URL}/reset-password`,
        component: ResetPassword
    },
    { path: `${process.env.PUBLIC_URL}/account`, component: WrappedHome },
    { path: `${process.env.PUBLIC_URL}/offers`, component: WrappedOffers },
    { path: `${process.env.PUBLIC_URL}/settings`, component: WrapperSettings },
    { path: `${process.env.PUBLIC_URL}/loans`, component: WrappedLoans },
    { path: `${process.env.PUBLIC_URL}/loans/:id`, component: WrappedLoan },
    { path: `${process.env.PUBLIC_URL}/error/`, component: Error },
    { path: `${process.env.PUBLIC_URL}/orders`, component: WrappedOrders },
    { path: `${process.env.PUBLIC_URL}/orders/:id`, component: WrappedOrder },
    { path: `${process.env.PUBLIC_URL}/m/:mid`, component: App },
    { path: `${process.env.PUBLIC_URL}/m/:mid/:pid`, component: App },
];

export default indexRoutes;
