import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import { Form } from "react-final-form";

const styles = {
  nextButton: {
    display: "block",
    margin: "30px auto",
    minWidth: "80%"
  },
  submitButton: {
    fontSize: "0.8rem",
    margin: "30px auto",
    minWidth: "80%"
  },
  nextButtonTop: {
    display: "block",
    margin: "30px auto",
    minWidth: "80%"
  }
};

class Wizard extends Component {
  static Page = ({ children }) => {
    return <>{children}</>;
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      buttonValue: ""
    };
  }

  componentDidUpdate() {
    const { page, values } = this.state;
    const { setDisplayBack, setName, setUserName } = this.props;
    if (page > 0) setDisplayBack(false);
    else setDisplayBack(true);
    if (Object.getOwnPropertyNames(values).first_name !== 0)
      setName(values.first_name);
    if (Object.getOwnPropertyNames(values).email !== 0)
      setUserName(values.email);
  }

  // Variant event handles
  setRent = () => {
    this.setState({
      buttonValue: "rent"
    });
  };

  setOwn = () => {
    this.setState({
      buttonValue: "own"
    });
  };

  setOwnOutright = () => {
    this.setState({
      buttonValue: "own_outright"
    });
  };

  setOwnMortgage = () => {
    this.setState({
      buttonValue: "own_with_mortgage"
    });
  };

  setEmployed = () => {
    this.setState({
      buttonValue: "employed"
    });
  };

  setMilitary = () => {
    this.setState({
      buttonValue: "military"
    });
  };

  setRetired = () => {
    this.setState({
      buttonValue: "retired"
    });
  };

  setSelfEmployed = () => {
    this.setState({
      buttonValue: "self_employed"
    });
  };

  setOther = () => {
    this.setState({
      buttonValue: "other"
    });
  };

  setNotEmployed = () => {
    this.setState({
      buttonValue: "not_employed"
    });
  };

  setWeekly = () => {
    this.setState({
      buttonValue: "weekly"
    });
  };

  setBiWeekly = () => {
    this.setState({
      buttonValue: "biweekly"
    });
  };

  setTwiceMonthly = () => {
    this.setState({
      buttonValue: "twice_monthly"
    });
  };

  setMonthly = () => {
    this.setState({
      buttonValue: "monthly"
    });
  };

  setOtherTwo = () => {
    this.setState({
      buttonValue: "other_two"
    });
  };

  setDirectDeposit = () => {
    this.setState({
      buttonValue: "direct_deposit"
    });
  };

  setCheck = () => {
    this.setState({
      buttonValue: "check"
    });
  };

  setCash = () => {
    this.setState({
      buttonValue: "cash"
    });
  };

  setExcellent = () => {
    this.setState({
      buttonValue: "excellent"
    });
  };

  setGood = () => {
    this.setState({
      buttonValue: "good"
    });
  };

  setFair = () => {
    this.setState({
      buttonValue: "fair"
    });
  };

  setPoor = () => {
    this.setState({
      buttonValue: "poor"
    });
  };
  // End variant event handles

  next = (values, page) => {
    const { children } = this.props;
    this.setState((state) => ({
      page: Math.min(state.page + page, children.length - 1),
      values
    }));
  };

  previous = () => {
    const { page } = this.state;
    if (page === 9) {
      this.setState((state) => ({
        page: Math.max(state.page - 2, 0)
      }));
    } else {
      this.setState((state) => ({
        page: Math.max(state.page - 1, 0)
      }));
    }
  };

  validate = (values) => {
    const { children } = this.props;
    const { page } = this.state;
    const activePage = Children.toArray(children)[page];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values) => {
    const { children, onSubmit } = this.props;
    const { page, buttonValue } = this.state;
    const isLastPage = page === Children.count(children) - 1;

    if (isLastPage) {
      return onSubmit(values);
    }
    // Variant submits
    if (buttonValue === "rent") {
      values = { ...values, status: buttonValue };
      return this.next(values, 2);
    }
    if (buttonValue === "own_outright" || buttonValue === "own_with_mortgage") {
      values = { ...values, status: buttonValue };
      return this.next(values, 1);
    }
    if (
        buttonValue === "employed" ||
        buttonValue === "military" ||
        buttonValue === "retired" ||
        buttonValue === "self_employed" ||
        buttonValue === "other" ||
        buttonValue === "not_employed"
    ) {
      values = { ...values, employment: { status: buttonValue } };
      return this.next(values, 1);
    }

    if (
        buttonValue === "weekly" ||
        buttonValue === "biweekly" ||
        buttonValue === "twice_monthly" ||
        buttonValue === "monthly"
    ) {
      values = { ...values, payroll_frequency: buttonValue };
      return this.next(values, 1);
    }

    if (buttonValue === "other_two") {
      values = { ...values, payroll_frequency: "other" };
      return this.next(values, 1);
    }

    if (
        buttonValue === "direct_deposit" ||
        buttonValue === "cash" ||
        buttonValue === "check"
    ) {
      values = { ...values, payroll_type: buttonValue };
      return this.next(values, 1);
    }

    if (
        buttonValue === "excellent" ||
        buttonValue === "good" ||
        buttonValue === "fair" ||
        buttonValue === "poor"
    ) {
      values = { ...values, credit_score: buttonValue };
      return this.next(values, 1);
    }
    // End variant submits
    return this.next(values, 1);
  };

  render() {
    const {
      children,
      initWizard,
      passwordValid,
      copy,
      agree,
      authFetchState,
      leadFetchState,
      userFetchState,
      merchantFetchState
    } = this.props;
    const { page, values } = this.state;
    const activePage = Children.toArray(children)[page];
    const isLastPage = page === Children.count(children) - 1;
    return (
        <Form
            initialValues={values}
            validate={this.validate}
            onSubmit={this.handleSubmit}
        >
          {({ handleSubmit, invalid, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {activePage}
                {!isLastPage &&
                page !== 7 &&
                page !== 8 &&
                page !== 9 &&
                page !== 11 &&
                page !== 12 &&
                page !== 13 && (
                    <Fab
                        variant="extended"
                        aria-label="Submit"
                        color="secondary"
                        type="submit"
                        disabled={invalid || submitting || !passwordValid}
                        style={styles.nextButton}
                    >
                      {copy.buttonNext}
                    </Fab>
                )}
                {isLastPage && !initWizard && (
                    <Fab
                        variant="extended"
                        aria-label="Submit"
                        color="secondary"
                        type="submit"
                        disabled={
                          invalid ||
                          submitting ||
                          !agree ||
                          authFetchState === "Loading" ||
                          leadFetchState === "Loading" ||
                          userFetchState === "Loading" ||
                          authFetchState === "Error" ||
                          leadFetchState === "Error" ||
                          userFetchState === "Error"
                        }
                        style={styles.submitButton}
                    >
                      {copy.buttonSumbit}
                    </Fab>
                )}
                {isLastPage && initWizard && (
                    <Fab
                        variant="extended"
                        aria-label="Submit"
                        color="secondary"
                        type="submit"
                        disabled={
                          invalid || submitting || merchantFetchState === "Loading"
                        }
                        style={styles.nextButton}
                    >
                      {copy.buttonNext}
                    </Fab>
                )}

                {/* Variant Buttons */}
                {page === 7 && (
                    <div style={styles.buttonRow}>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setRent}
                      >
                        {copy.buttons.page9.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButtonTop}
                          onClick={this.setOwn}
                      >
                        {copy.buttons.page9.button2}
                      </Fab>
                    </div>
                )}

                {page === 8 && (
                    <div style={styles.buttonRow}>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setOwnMortgage}
                      >
                        {copy.buttons.page10.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButtonTop}
                          onClick={this.setOwnOutright}
                      >
                        {copy.buttons.page10.button2}
                      </Fab>
                    </div>
                )}

                {page === 9 && (
                    <>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setEmployed}
                      >
                        {copy.buttons.page11.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setMilitary}
                      >
                        {copy.buttons.page11.button2}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setRetired}
                      >
                        {copy.buttons.page11.button3}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setSelfEmployed}
                      >
                        {copy.buttons.page11.button4}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setOther}
                      >
                        {copy.buttons.page11.button5}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setNotEmployed}
                      >
                        {copy.buttons.page11.button6}
                      </Fab>
                    </>
                )}

                {page === 11 && (
                    <>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setWeekly}
                      >
                        {copy.buttons.page13.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setBiWeekly}
                      >
                        {copy.buttons.page13.button2}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setTwiceMonthly}
                      >
                        {copy.buttons.page13.button3}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setMonthly}
                      >
                        {copy.buttons.page13.button4}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setOtherTwo}
                      >
                        {copy.buttons.page13.button5}
                      </Fab>
                    </>
                )}

                {page === 12 && (
                    <div className="buttonRow">
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setDirectDeposit}
                      >
                        {copy.buttons.page14.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setCheck}
                      >
                        {copy.buttons.page14.button2}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setCash}
                      >
                        {copy.buttons.page14.button3}
                      </Fab>
                    </div>
                )}

                {page === 13 && (
                    <div className="buttonRow">
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setExcellent}
                      >
                        {copy.buttons.page15.button1}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setGood}
                      >
                        {copy.buttons.page15.button2}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setFair}
                      >
                        {copy.buttons.page15.button3}
                      </Fab>
                      <Fab
                          variant="extended"
                          aria-label="Submit"
                          color="secondary"
                          type="submit"
                          style={styles.nextButton}
                          onClick={this.setPoor}
                      >
                        {copy.buttons.page15.button4}
                      </Fab>
                    </div>
                )}
                {/* End Variant Buttons */}

                {page > 0 && (
                    <Fab
                        aria-label="Submit"
                        onClick={this.previous}
                        color="primary"
                        style={{ marginTop: 10 }}
                    >
                      <NavigateBeforeIcon />
                    </Fab>
                )}
              </form>
          )}
        </Form>
    );
  }
}

Wizard.defaultProps = {
  initialValues: {},
  initWizard: false,
  setName: () => {},
  setUserName: () => {},
  passwordValid: false,
  agree: false,
  authFetchState: "",
  userFetchState: "",
  leadFetchState: "",
  merchantFetchState: ""
};

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setDisplayBack: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
  children: PropTypes.any.isRequired,
  initWizard: PropTypes.bool,
  setName: PropTypes.func,
  setUserName: PropTypes.func,
  passwordValid: PropTypes.bool,
  copy: PropTypes.object.isRequired,
  agree: PropTypes.bool,
  authFetchState: PropTypes.string,
  leadFetchState: PropTypes.string,
  userFetchState: PropTypes.string,
  merchantFetchState: PropTypes.string
};

export default Wizard;
