import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import useStyles from "../styles";

const MerchantInfoCard = ({ merchant, copy }) => {
  const classes = useStyles();

  return (
    <Card raised elevation="4" className={classes.innerCard}>
      <CardHeader
        title={
          <Typography
            variant="body1"
            color="primary"
            className={classes.innerCardHeader}
          >
            {copy}
          </Typography>
        }
        className={classes.offerCardHeader}
      />
      <CardContent className={classes.innerCardContent}>
        {merchant.name && (
          <Typography
            variant="body1"
            className={classes.cardHeader}
            style={{ marginBottom: 10 }}
          >
            {merchant.name}
          </Typography>
        )}
        {merchant.phone && (
          <Typography variant="body2" className={classes.cardText}>
            <PhoneIcon
              color="secondary"
              style={{ marginBottom: -10, marginRight: 10 }}
            />
            {merchant.phone.number}
          </Typography>
        )}
        {merchant.email && (
          <Typography variant="body2" className={classes.cardText}>
            <EmailIcon
              color="secondary"
              style={{ marginBottom: -10, marginRight: 10 }}
            />
            {merchant.email.email}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

MerchantInfoCard.propTypes = {
  merchant: PropTypes.object.isRequired,
  copy: PropTypes.string.isRequired
};

export default MerchantInfoCard;
