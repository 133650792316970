import React from "react";
import PropTypes from "prop-types";
import NavWrapper from "./NavWrapper";
import FooterWrapper from "./FooterWrapper";

const Layout = ({ children }) => {
  return (
    <>
      <NavWrapper />
      {children}
      <FooterWrapper />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired
};

export default Layout;
