import { Auth, User } from "../api/Agent";

export const CLEAR_USER = "CLEAR_USER";

export const CLEAR_FETCH_STATE = "CLEAR_FETCH_STATE";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const EDIT_USER_SETTINGS_LOADING = "EDIT_USER_SETTINGS_LOADING";
export const EDIT_USER_SETTINGS_SUCCESS = "EDIT_USER_SETTINGS_SUCCESS";
export const EDIT_USER_SETTINGS_ERROR = "EDIT_USER_SETTINGS_ERROR";

export const VALIDATE_EMAIL_LOADING = "VALIDATE_EMAIL_LOADING";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_ERROR = "VALIDATE_EMAIL_ERROR";

export const clearUser = () => ({
  type: CLEAR_USER
});

export const clearFetchState = () => ({
  type: CLEAR_FETCH_STATE
});

export const getUser = () => async dispatch => {
  dispatch({
    type: GET_USER_LOADING
  });
  try {
    const result = await Auth.current();
    dispatch({
      type: GET_USER_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const editUserSettings = (
  username,
  user,
  settings
) => async dispatch => {
  dispatch({
    type: EDIT_USER_SETTINGS_LOADING
  });
  try {
    if (typeof settings !== "undefined") {
      await User.settings(username, settings);
    }
    const result = await User.save(username, user);
    dispatch({
      type: EDIT_USER_SETTINGS_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: EDIT_USER_SETTINGS_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const validateEmail = () => async dispatch => {
  dispatch({
    type: VALIDATE_EMAIL_LOADING
  });
  try {
    const result = await Auth.current();
    dispatch({
      type: GET_USER_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};
