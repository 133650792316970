const footerIndexEnable = {
    headers: {
        header1: "Customers",
        header2: "Merchants",
        header3: "Links",
        header4: "Terms & Policies"
    },
    row1: [
        { path: `${process.env.PUBLIC_URL}/`, name: "Apply", external: false },
        {
            path: `${process.env.PUBLIC_URL}/sign-in`,
            name: "Sign In",
            external: false
        },
        {
            path: "https://www.enablefinancing.com/faq",
            name: "FAQ",
            external: true
        }
    ],
    row2: [
        {
            path: `${process.env.PUBLIC_URL}/sign-in`,
            name: "Sign In",
            external: false
        },
        {
            path: "https://www.enablefinancing.com/faq",
            name: "FAQ",
            external: true
        }
    ],
    row3: [
        {
            path: "https://www.enablefinancing.com/credit-authorization-agreement",
            name: "Credit Authorization Agreement",
            external: true
        },
        {
            path: "https://www.enablefinancing.com/telephone-and-email-consent",
            name: "Telephone & Email Consent",
            external: true
        }
    ],
    row4: [
        {
            path: "https://www.enablefinancing.com/privacy-policy",
            name: "Privacy Policy",
            external: true
        },
        {
            path: "https://www.enablefinancing.com/terms-of-use",
            name: "Terms of Use",
            external: true
        },
        {
            path: "https://www.enablefinancing.com/terms-of-service",
            name: "Terms of Service",
            external: true
        }
    ]
};

export default footerIndexEnable;