import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import useStyles from "../styles";

const LoanListCard = ({ copy, ...loan }) => {
  const classes = useStyles();

  return (
    <Card className={classes.innerCard} raised style={{ marginBottom: 20 }}>
      <CardHeader
        title={
          <Typography
            variant="h1"
            align="center"
            className={classes.innerCardHeader}
          >
            <NumberFormat
              value={loan.funded_amount}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </Typography>
        }
        className={classes.offerCardHeader}
      />
      <CardContent className={classes.innerCardContent}>
        <Grid container>
          <Grid item xs={6} style={{ textAlign: "center" }}>
            <>
              {loan.originator.image_url !== null ? (
                <img
                  alt="Lender Logo"
                  title="Lender Logo"
                  src={loan.originator.image_url}
                />
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  className={classes.header}
                >
                  {loan.originator.name}
                </Typography>
              )}
            </>
          </Grid>
          <Grid item xs={6}>
            <>
              {loan.status === "funded" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {loan.date_funded ? (
                    <Typography
                      variant="body2"
                      color="secondary"
                      align="center"
                      style={{ marginLeft: 5 }}
                    >
                      {copy.line5}
                      <br />
                      {moment(loan.date_funded).format("DD-MM-YYYY")}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="secondary"
                      align="center"
                      style={{ marginLeft: 5 }}
                    >
                      {copy.line5b}
                    </Typography>
                  )}
                </div>
              ) : loan.status === "declined" ? (
                <Typography align="center" variant="body2" color="error">
                  {copy.line7}
                </Typography>
              ) : (
                <Typography align="center" variant="body2" color="secondary">
                  {copy.line6}
                </Typography>
              )}
            </>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Link href={`loans/${loan.id}`} underline="none">
            <Fab variant="extended" color="secondary" aria-label="Submit">
              <span style={{ fontSize: "0.7rem" }}>{copy.line4}</span>
            </Fab>
          </Link>
        </div>
      </CardActions>
    </Card>
  );
};

LoanListCard.propTypes = {
  copy: PropTypes.object.isRequired
};

export default LoanListCard;
