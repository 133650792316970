import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CircularProgress from "@material-ui/core/CircularProgress";
import Check from "@material-ui/icons/Check";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import NumberFormat from "react-number-format";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "./layout/Layout";
import useStyles from "./styles";
import { approveOrder, clearApproveOrder, getOrderById } from "../actions";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Order = () => {
  const classes = useStyles();

  const order = useSelector((state) => state.order);
  const [error, setError] = useState("");
  const [fetch, setFetch] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
    dispatch(clearApproveOrder());
  }, [dispatch]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(order.approveState).length !== 0)
      setError(order.approveState[Object.keys(order.approveState)[0]]);
    else setError("");
  }, [order.approveState]);

  useEffect(() => {
    async function fetchOrder() {
      dispatch(await getOrderById(window.location.pathname.split("/").pop()));
    }
    if (fetch) {
      fetchOrder();
      setFetch(false);
    }
  }, [dispatch, fetch]);

  async function handleApprove() {
    dispatch(await approveOrder(order.currentOrder.actions.approve)).then(
      () => {
        setFetch(true);
      }
    );
  }

  return (
    <Layout>
      {order.fetchState === "Order by Id Error" && (
        <Redirect to={`${process.env.PUBLIC_URL}/account`} />
      )}
      {order.fetchState === "Order by Id Success" ? (
        <div className={classes.wizard}>
          <Typography
            variant="body1"
            color="primary"
            style={{ marginBottom: 50 }}
          >
            {copy.account.order.line1}
          </Typography>
          <Card className={classes.card} raised>
            <CardContent>
              <>
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.cardHeader}
                >
                  {copy.account.order.line3}
                </Typography>
                <hr style={{ opacity: "0.4", marginBottom: 10 }} />
                {order.currentOrder.items.map((item, key) => (
                  <Typography
                    key={key}
                    variant="body2"
                    className={classes.cardDisclaimer}
                  >
                    {item.quantity} {item.description}
                  </Typography>
                ))}

                <Typography
                  variant="h1"
                  align="center"
                  className={classes.cardHeader}
                  style={{ marginTop: 30 }}
                >
                  {copy.account.order.line4}
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  align="center"
                  style={{ fontSize: "1.8rem" }}
                >
                  <NumberFormat
                    value={order.currentOrder.total}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                  />
                </Typography>

                <div style={{ textAlign: "center", margin: "10px auto" }}>
                  {order.currentOrder.actions.approve !== null && (
                    <>
                      <Fab
                        variant="extended"
                        color="secondary"
                        aria-label="Submit"
                        onClick={handleApprove}
                        style={{ marginBottom: 20 }}
                      >
                        <span style={{ fontSize: "0.65rem" }}>
                          {copy.account.order.line5}
                        </span>
                      </Fab>
                      <Typography variant="body2" color="error">
                        {copy.account.order.line6}
                      </Typography>
                    </>
                  )}

                  {order.currentOrder.actions.approve === null &&
                    order.currentOrder.status === "approved" && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Check color="secondary" />
                        <Typography
                          variant="body1"
                          color="secondary"
                          style={{ marginLeft: 5 }}
                        >
                          {copy.account.order.line7}
                        </Typography>
                      </div>
                    )}

                  {order.currentOrder.actions.approve === null &&
                    order.currentOrder.status === "paid" && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Check color="secondary" />
                        <Typography variant="body1" color="secondary">
                          {copy.account.order.line11}
                        </Typography>
                      </div>
                    )}

                  {order.currentOrder.actions.approve === null &&
                    order.currentOrder.status === "refunded" && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography variant="body1" color="secondary">
                          {copy.account.order.line12}
                        </Typography>
                      </div>
                    )}

                  {order.currentOrder.actions.approve === null &&
                    order.currentOrder.status === "cancelled" && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Typography variant="body1" color="error">
                          {copy.account.order.line8}
                        </Typography>
                      </div>
                    )}

                  {order.currentOrder.actions.approve === null &&
                    order.currentOrder.status !== "cancelled" &&
                    order.currentOrder.status !== "approved" &&
                    order.currentOrder.status !== "paid" &&
                    order.currentOrder.status !== "refunded" && (
                      <Typography
                        align="center"
                        variant="body1"
                        color="secondary"
                      >
                        {copy.account.order.line10}
                      </Typography>
                    )}
                  <Typography
                    variant="caption"
                    color="error"
                    align="center"
                    display="block"
                    className={classes.error}
                    style={{ marginTop: 10 }}
                  >
                    {error}
                  </Typography>
                  <Link href={null} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                      style={{ marginBottom: 20 }}
                    >
                      <SaveAltIcon style={{ marginRight: 10 }} />
                      <span style={{ fontSize: "0.65rem" }}>
                        {copy.account.order.line11}
                      </span>
                    </Fab>
                  </Link>
                </div>
              </>

              <Typography
                variant="body1"
                color="primary"
                className={classes.cardHeader}
              >
                {copy.account.order.line2}
              </Typography>
              <hr style={{ opacity: "0.4", marginBottom: 30 }} />
              {order.currentOrder.merchant && (
                <>
                  {order.currentOrder.merchant.name && (
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: 600 }}
                    >
                      {order.currentOrder.merchant.name}
                    </Typography>
                  )}
                  {order.currentOrder.merchant.address && (
                    <Typography
                      variant="body2"
                      className={classes.cardDisclaimer}
                    >
                      {order.currentOrder.merchant.address.street1},
                      {order.currentOrder.merchant.address.street2}
                      <br />
                      {order.currentOrder.merchant.address.city},
                      {order.currentOrder.merchant.address.state}{" "}
                      {order.currentOrder.merchant.address.zip}
                    </Typography>
                  )}
                  {order.currentOrder.merchant.phone && (
                    <Typography
                      variant="body2"
                      className={classes.cardDisclaimer}
                    >
                      <PhoneIcon
                        color="secondary"
                        style={{ marginBottom: -10, marginRight: 10 }}
                      />
                      {order.currentOrder.merchant.phone.number}
                    </Typography>
                  )}
                  {order.currentOrder.merchant.email && (
                    <Typography
                      variant="body2"
                      className={classes.cardDisclaimer}
                    >
                      <EmailIcon
                        color="secondary"
                        style={{ marginBottom: -10, marginRight: 10 }}
                      />
                      {order.currentOrder.merchant.email.email}
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
          </Card>
          <Link href={`${process.env.PUBLIC_URL}/account`} underline="none">
            <Fab
              color="primary"
              aria-label="Submit"
              style={{ marginBottom: 50 }}
            >
              <NavigateBeforeIcon />
            </Fab>
          </Link>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            margin: "10vh auto",
            minHeight: "100vh"
          }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}
    </Layout>
  );
};

export default Order;
