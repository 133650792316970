const filterLoan = (filter, data) => {
  switch (filter) {
    case "MO_PAYMENT":
      return data.sort(
        (a, b) =>
          parseFloat(a.monthly_payment.replace(/\$|,/g, "")) -
          parseFloat(b.monthly_payment.replace(/\$|,/g, ""))
      );
    case "LOAN_TERM":
      return data.sort((a, b) => parseFloat(a.term) - parseFloat(b.term));
    case "APR":
      return data.sort((a, b) => parseFloat(a.apr) - parseFloat(b.apr));
    case "MAX_LOAN":
      return data.sort(
        (a, b) =>
          parseFloat(a.amount.replace(/\$|,/g, "")) -
          parseFloat(b.amount.replace(/\$|,/g, ""))
      );
    default:
      return data;
  }
};

export default filterLoan;
