import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import leadReducer from "./lead.reducer";
import loanReducer from "./loan.reducer";
import merchantReducer from "./merchant.reducer";
import pingReducer from "./ping.reducer";
import orderReducer from "./order.reducer";
import userReducer from "./user.reducer";
import utilsReducer from "./utils.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  lead: leadReducer,
  loan: loanReducer,
  merchantInfo: merchantReducer,
  ping: pingReducer,
  order: orderReducer,
  user: userReducer,
  redirect: utilsReducer
});

export default rootReducer;
