import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

export const required = (value) => (value ? undefined : "Required Field");

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const CurrencyFormat = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values && values.formattedValue) {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      thousandSeparator
      prefix="$ "
    />
  );
};

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export const PhoneField = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values && values.formattedValue) {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      format="###-###-####"
    />
  );
};

PhoneField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export const ZipField = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values && values.formattedValue) {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      format="#####-####"
    />
  );
};

ZipField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export const AgeField = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values && values.formattedValue) {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      thousandSeparator
    />
  );
};

AgeField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export const SsnField = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (values && values.formattedValue) {
          onChange({
            target: {
              value: values.value
            }
          });
        }
      }}
      format="###-##-####"
      mask="#"
    />
  );
};

SsnField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
