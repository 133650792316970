import { Order, Token } from "../api/Agent";

export const CLEAR_ORDER = "CLEAR_ORDER";
export const GET_ORDER_LOADING = "GET_ORDER_LOADING";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";
export const GET_ORDER_ID_LOADING = "GET_ORDER_ID_LOADING";
export const GET_ORDER_ID_SUCCESS = "GET_ORDER_ID_SUCCESS";
export const GET_ORDER_ID_ERROR = "GET_ORDER_ID_ERROR";
export const APPROVE_ORDER_LOADING = "APPROVE_ORDER_LOADING";
export const APPROVE_ORDER_SUCCESS = "APPROVE_ORDER_SUCCESS";
export const APPROVE_ORDER_ERROR = "APPROVE_ORDER_ERROR";
export const CLEAR_APPROVE_ORDER = "CLEAR_APPROVE_ORDER";

export const clearOrder = () => ({
  type: CLEAR_ORDER
});

export const clearApproveOrder = () => ({
  type: CLEAR_APPROVE_ORDER
});

export const getOrder = () => async dispatch => {
  dispatch({
    type: GET_ORDER_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Order.get();
    dispatch({
      type: GET_ORDER_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const getOrderById = id => async dispatch => {
  dispatch({
    type: GET_ORDER_ID_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Order.getById(id);
    dispatch({
      type: GET_ORDER_ID_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_ID_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const approveOrder = url => async dispatch => {
  dispatch({
    type: APPROVE_ORDER_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Order.approve(url);
    dispatch({
      type: APPROVE_ORDER_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: APPROVE_ORDER_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};
