const copyEn = {
  buttonNext: "next",
  buttonPrev: "back",
  buttonSumbit: "Search Financing Options",
  buttonAccount: "Account",
  nav: {
    link1: "Account",
    link2: "Settings",
    link3: "Financing",
    link4: "Purchases",
    link5: "Sign Out"
  },
  greet: "Hi",
  buttons: {
    page9: {
      button1: "Rent",
      button2: "Own"
    },
    page10: {
      button1: "Yes",
      button2: "No"
    },
    page11: {
      button1: "Employed",
      button2: "Military",
      button3: "Retired",
      button4: "Self Employed",
      button5: "Other",
      button6: "Not Employed"
    },
    page13: {
      button1: "Weekly",
      button2: "Every two weeks",
      button3: "Twice a month",
      button4: "Once a month",
      button5: "Other"
    },
    page14: {
      button1: "Direct Deposit",
      button2: "Check",
      button3: "Cash"
    },
    page15: {
      button1: "Excellent - 720+",
      button2: "Good - 660-719",
      button3: "Fair - 600-659",
      button4: "Poor - Below 600"
    }
  },
  modals: {
    signOut: {
      line1: "Are you sure you want to sign out?",
      button1: "Yes",
      button2: "No"
    },
    exit: {
      default: {
        line1:
            "You will now be redirected from PayPossible to the lender's website.",
        line2: "Please select Ok to continue."
      },
      custom: {
        line1: {
          line1: "The following information will be required:",
          items: [
            "Pay Date Information",
            "Banking Information",
            "Valid Debit Card"
          ],
          line2:
              "You will be required to sign in to your account. Use the email and password you set during the application to sign in."
        }
      }
    }
  },
  application: {
    initHeader: "Let's start with the basics",
    creditProfileHeader: "Let's create your profile",
    page1: {
      idTitle: "PayPossible ID",
      line1: "Welcome to PayPossible",
      line2: "Financing made",
      line3: "delightfully easy",
      line4:
          "First, enter the PayPossible ID of the business where you are looking to make a purchase.",
      line5: "If not sure, just leave blank.",
      error: "Please enter a valid PayPossible ID.",
      line6: "Completing the profile will NOT affect your credit score.",
      steps: [
        {
          title: "1",
          body: "Complete your profile."
        },
        {
          title: "2",
          body: "Select a financing option."
        },
        {
          title: "3",
          body: "Finalize your financing with the selected provider."
        }
      ]
    },
    page2: {
      line0: "Tell us more",
      line0b: "Welcome to",
      line1: "about your purchase from",
      line1b: "about your purchase",
      line2: "What good/service are you purchasing?",
      line3: "If not sure, select 'Other'.",
      line3b: "Which product or service are you interested in purchasing?",
      line4: "What’s the total purchase price?",
      line4b: "Don't forget sales tax.",
      line5: "Enter a value between $500 and $100,000.",
      line6: "If your purchase information is correct,",
      line6b: "click Next to continue",
      line7: "Products/Services",
      line7b: "Total Cost",
      line8: "Order Details",
      loanPurpose: [
        {
          value: "auto",
          option: "Auto"
        },
        {
          value: "baby",
          option: "Baby"
        },
        {
          value: "boat",
          option: "Boat"
        },
        {
          value: "business",
          option: "Business"
        },
        {
          value: "debt_consolidation",
          option: "Debt Consolidation"
        },
        {
          value: "green",
          option: "Green Loan"
        },
        {
          value: "home_improvement",
          option: "Home Improvement"
        },
        {
          value: "household_expenses",
          option: "Household Expenses"
        },
        {
          value: "large_purchases",
          option: "Large Purchase"
        },
        {
          value: "medical_dental",
          option: "Medical/Dental"
        },
        {
          value: "moving_relocation",
          option: "Moving/Relocation"
        },
        {
          value: "student_loan",
          option: "Student Loan"
        },
        {
          value: "taxes",
          option: "Taxes"
        },
        {
          value: "vacation",
          option: "Vacation"
        },
        {
          value: "wedding",
          option: "Wedding/Special Occasion"
        },
        {
          value: "other",
          option: "Other"
        }
      ]
    },
    page3: {
      line1: "Perfect!",
      line2: "Now let’s create your profile",
      line3:
          "We’ll need to ask you a few questions to verify your identity and find the available financing options for your purchase."
    },
    page4: {
      line1: "Let's start with the basics",
      line2: "Nice to meet you!",
      line3: "Provide a few details to help us confirm your identity."
    },
    page5: {
      line1: "Let's start with the basics",
      line2: "Hi",
      line3: "Provide a few details to help us confirm your identity."
    },
    page6: {
      line1: "Let's start with the basics",
      line2: "Thanks",
      line3: "Please create a password for your account.",
      line4:
          "We’ll only use this info to communicate financing offers and complete your purchase."
    },
    page7: {
      line1: "Identity",
      line2: "What's your current address?"
    },
    page8: {
      line1: "Identity",
      line2: "How long have you lived there?"
    },
    page9: {
      line1: "Identity",
      line2: "Do you rent or own?"
    },
    page10: {
      line1: "Identity",
      line2: "Are you paying mortgage?"
    },
    page11: {
      line1: "Income",
      line2: "Which of these best describes your current work status?"
    },
    page12: {
      line1: "Income",
      line2: "How much do you make a year?",
      line3:
          "Total amount you make per year, before taxes. You must be able to verify it."
    },
    page13: {
      line1: "Income",
      line2: "How often do you get paid?"
    },
    page14: {
      line1: "Income",
      line2: "How do you receive your income?"
    },
    page15: {
      line1: "Credit Score",
      line2: "How's your credit score?"
    },
    page16: {
      line1: "Final Step",
      line2: "Please enter your social security number",
      line3:
        "We’ll use your social security number to verify your identity and offer products and services, including offers of credit to help you make purchases from our partner merchants.",
      line4: "Creating your profile WILL NOT affect your credit score.",
      line5: "By submitting this form, I consent to",
      line6: "PayPossible's E-Sign Agreement",
      line7: "Credit Authorization Agreement",
      line8: "Privacy Policy",
      line9: "Terms of Service",
      line10:
          "and am providing written consent for us to share with others your information, including your social security number, date of birth, and income, for the purpose of offering products and services.  You also direct PayPossible to share your information with other lenders and partners who may use that information to consider whether to offer financing to you. I agree to be contacted by PayPossible and its partners at the telephone number(s) I have provided above to explore the offer of such products and services, including contact through automatic dialing systems, artificial or pre-recorded voice messaging, or text message. I understand my consent is not required as a condition to purchase any goods or services from anyone.",
      line11:
          "An email will be sent to you after you submit to verify your account."
    }
  },
  signin: {
    line1: "Sign in",
    line2: "Forgot Password?",
    line3: "Verify Your Email Address",
    line4:
        "We have sent an email to you for verification. Follow the link provided to finalize the signup process. Please contact us if you do not receive it within a few minutes.",
    button: "Sign in"
  },
  reset: {
    line1: "Forgot password?",
    line2:
        "Enter your email address below. We will send you an email with the link to reset it.",
    line3: "Please contact us if you have any trouble resetting your password.",
    line4:
        "We have sent an email to the address your provided. Please contact us if you do not receive it within a few minutes.",
    line5: "Sign in?",
    button: "Reset Password"
  },
  account: {
    signin: {
      line1: "Please wait while we...",
      line2: "Gather your information &",
      line3: "Connect with lenders in our network.",
      line4: "Loading your purchase..."
    },
    steps: [
      "Select a financing option",
      "Finalize loan and order details",
      "Complete your purchase"
    ],
    offers1: {
      line1: "Financing Options",
      line2:
          "The financing options below are based upon the general criteria you  provided on the initial PayPossible application. Please note  that all terms are subject to change upon  completing the application with the respective provider who made the offer, and that additional verification  of the applicant’s financial history will occur.",
      line3: "View All",
      line4: "Filter Options",
      line5: "Sort by",
      line6: "Account",
      filterOffers: ["Monthly Payment", "Loan Term", "APR", "Max Loan Amount"]
    },
    loancard: {
      line1: "/mo",
      line2: "For",
      line3: "Amount: ",
      line4: "APR: ",
      button1: "Select",
      button2: "Not Interested",
      button3: "Proceed to Lender",
      button4: "Continue"
    },
    offers2: {
      line1: "Finalize",
      line2:
          "Finalize the details of your financing with the lender. Once complete, sign back into your PayPossible account and complete your purchase.",
      line3:
          "To determine the exact $ amount that you’ve been approved up to and your payment terms, please select Continue",
      line5: "Offers"
    },
    offercard: {
      line1:
          "Next, review your options and select an offer. Click view offers to continue.",
      line2: "View Offers",
      line3: "Purchase Details",
      line4: "Pre-qualified Amount",
      line5: "Congratulations",
      line6: "You Received Offers!"
    },
    ordercard: {
      line1a:
          "Click the View Details button below to check the status of the purchase or financing details.",
      line1b:
          "Click the Complete Purchase button below to approve your order, or click the View Details button to check the financing details.",
      line2: "Purchase Information",
      line3: "Financing Details",
      line4: "Amount",
      line5: "Complete Purchase",
      line6: "Funded",
      line6b: "Funded on",
      line7: "Declined",
      line8: "In Progress",
      line9: "View Details",
      line10: "Products",
      line11: "Approved",
      line12: "Cancelled",
      line13: "In Progress",
      line14: "Completed",
      line15: "Refunded"
    },
    loan: {
      line1:
          "Review your financing below. Click the contact button if you would like to get in touch with your lender.",
      line2: "In Progress",
      line3: "Contact",
      line4: "Purchase Details",
      line5: "Amount",
      line6: "Account",
      line7: "Funded",
      line7b: "Funded on",
      line8: "Declined",
      line9: "Financing Details",
      line10: "Lender",
      line11: "Financing Information",
      declinedline1:
          "We’re sorry, your application was declined by the financing provider below for financing. The good news is you can re-apply in",
      declinedline2: "days.",
      declinedline3:
          "Click the Contact button if you’d like to get in touch with the financing provider.",
      fundedline1:
          "Congratulations, your loan was successfully funded! Click the contact button if you’d like to get in touch with the lender."
    },
    loans: {
      line1: "Financing",
      line2: "Account",
      line3: "Amount",
      line4: "Details",
      line5: "Funded on",
      line5b: "Funded",
      line6: "In Progress",
      line7: "Declined",
      line8:
          "You have no loans available at this time. Please click on the button below to return to your account."
    },
    order: {
      line1:
          "The details of your purchase are displayed below. If the order needs your approval, click the Complete Purchase button to approve.",
      line2: "Purchase Details",
      line3: "Description of Goods/Services",
      line4: "Total Cost:",
      line5: "Complete Purchase",
      line6: "If your order is incorrect, please contact us.",
      line7: "Approved",
      line8: "Cancelled",
      line9: "Account",
      line10: "In Progress",
      line11: "Completed",
      line12: "Refunded"
    },
    orders: {
      line1: "Purchases",
      line2: "Account",
      line3: "Total Cost",
      line4: "Details",
      line5: "Approved",
      line6: "Needs Approval",
      line7: "Cancelled",
      line8: "Products",
      line9: "Approve / Cancel",
      line10:
          "You have no orders available at this time. Please click on the button below to return to your account.",
      line11: "In Progress",
      line12: "Completed",
      line13: "Refunded"
    },
    nooffercard: {
      line1:
          "Looks like you did not receive any offers to complete your purchase at this time. The good news is that you are eligible to reapply for financing in",
      line2:
          "You may apply again for financing. Please select one of the options below.",
      line3: "Days",
      line4: "Improve your credit score",
      line5: "Use my previous profile, make no changes.",
      line6: "Review/edit my profile and reapply.",
      line7: "Great news",
      line8: "The countdown is over.",
      button1: "Apply Now",
      button2: "Edit Profile",
      button3: "Reapply"
    },
    purchasecard: {
      line1:
          "Final step! Just verify the details and click complete to finish your purchase.",
      line2: "from",
      line3: "Complete",
      line4: "Amount"
    },
    fundedcard: {
      line1:
          "Congratulations, your loan has been funded! You can apply again for financing after the number of days shown in the card below.",
      line2: "Apply again in",
      line3: "Days"
    }
  },
  settings: {
    options: [
      { value: "personal", label: "Personal" },
      { value: "password", label: "Password" },
      { value: "address", label: "Address" },
      { value: "phone", label: "Phone Number" },
      { value: "email", label: "Email" }
    ],
    personal: "Change Personal Information",
    password: "Change Password",
    address: {
      line1: "Mailing Addresses",
      line2: "Add New Mailing Address",
      line3: "Address Updated Successfully"
    },
    phone: {
      line1: "Phone Numbers",
      line2: "Add New Phone Numbers",
      line3: "Phone Updated Successfully"
    },
    email: {
      line1: "Email Address",
      line2: "Add New Email Address",
      line3:
          "Email Updated Successfully. We have sent an email to the address your provided for verification. Follow the link in the email to finalize the signup process. Please contact us if you do not receive it within a few minutes."
    },
    button: "Save",
    primary: "Primary",
    primaryLink: "Make Primary"
  },
  errorBoundary: {
    line1: "Sorry, something went wrong with the application.",
    line3: "Our team has been notified, and you may",
    line4: "contact us",
    line5: "for further assistance.",
    button: "Home"
  }
};

export default copyEn;
