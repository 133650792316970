const states = [
  { value: "AL", option: "Alabama" },
  { value: "AK", option: "Alaska" },
  { value: "AS", option: "American Somoa" },
  { value: "AZ", option: "Arizona" },
  { value: "AR", option: "Arkansas" },
  { value: "CA", option: "California" },
  { value: "CO", option: "Colorado" },
  { value: "CT", option: "Connecticut" },
  { value: "DE", option: "Delaware" },
  { value: "DC", option: "District Of Columbia" },
  { value: "FM", option: "Federated States of Micronesia" },
  { value: "FL", option: "Florida" },
  { value: "GA", option: "Georgia" },
  { value: "GU", option: "Guam" },
  { value: "HI", option: "Hawaii" },
  { value: "ID", option: "Idaho" },
  { value: "IL", option: "Illinois" },
  { value: "IN", option: "Indiana" },
  { value: "IA", option: "Iowa" },
  { value: "KS", option: "Kansas" },
  { value: "KY", option: "Kentucky" },
  { value: "LA", option: "Louisiana" },
  { value: "ME", option: "Maine" },
  { value: "MH", option: "Marshall Islands" },
  { value: "MD", option: "Maryland" },
  { value: "MA", option: "Massachusetts" },
  { value: "MI", option: "Michigan" },
  { value: "MN", option: "Minnesota" },
  { value: "MS", option: "Mississippi" },
  { value: "MO", option: "Missouri" },
  { value: "MT", option: "Montana" },
  { value: "NE", option: "Nebraska" },
  { value: "NV", option: "Nevada" },
  { value: "NH", option: "New Hampshire" },
  { value: "NJ", option: "New Jersey" },
  { value: "NM", option: "New Mexico" },
  { value: "NY", option: "New York" },
  { value: "NC", option: "North Carolina" },
  { value: "ND", option: "North Dakota" },
  { value: "MP", option: "Northern Mariana" },
  { value: "OH", option: "Ohio" },
  { value: "OK", option: "Oklahoma" },
  { value: "OR", option: "Oregon" },
  { value: "PW", option: "Palau" },
  { value: "PA", option: "Pennsylvania" },
  { value: "PR", option: "Puerto Rico" },
  { value: "RI", option: "Rhode Island" },
  { value: "SC", option: "South Carolina" },
  { value: "SD", option: "South Dakota" },
  { value: "TN", option: "Tennessee" },
  { value: "TX", option: "Texas" },
  { value: "AA", option: "U.S. Armed Forces - Americas" },
  { value: "AE", option: "U.S. Armed Forces - Europe" },
  { value: "AP", option: "U.S. Armed Forces - Pacific" },
  { value: "UT", option: "Utah" },
  { value: "VT", option: "Vermont" },
  { value: "VI", option: "Virgin Islands" },
  { value: "VA", option: "Virginia" },
  { value: "WA", option: "Washington" },
  { value: "WV", option: "West Virginia" },
  { value: "WI", option: "Wisconsin" },
  { value: "WY", option: "Wyoming" }
];

export default states;
