import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Field } from "react-final-form";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import useStyles from "./styles";
import Error from "../Error";
import { RenderTextField } from "../../utlis/input";
import { required, composeValidators, CurrencyFormat } from "./utils";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";

const minValue = (min) => (value) =>
  value >= min ? undefined : `Amount should be greater than $${min}`;

const maxValue = (max) => (value) =>
  value <= max ? undefined : `Amount should be less than $${max}`;

const Page12 = ({ header, copy }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line1}
      </Typography>
      <Typography variant="body2" display="block" className={classes.text}>
        {copy.line2}&nbsp;
          <Tooltip placement={"bottom-start"} title={"Current or reasonably expected salary, wages, bonuses, commission, interest, dividends, annuities, social security, disability, public assistance, retirement benefits, rental income, alimony, child support, separate maintenance, or any other that you wish to be considered"}>
            <HelpOutline style={{ fontSize: 12, color: "#19C27F" }} />
          </Tooltip>
      </Typography>
      <Field
        name="annual_income"
        id="annual_income"
        type="tel"
        component={RenderTextField}
        label="Annual Income"
        validate={composeValidators(
          required,
          minValue(9600),
          maxValue(2147483647)
        )}
        InputProps={{
          inputComponent: CurrencyFormat
        }}
      />
      <Error name="annual_income" {...classes} />
    </Wizard.Page>
  );
};

Page12.propTypes = {
  header: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
};

export default Page12;
