import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "./layout/Layout";
import { LoanInfoCard } from "./card";
import useStyles from "./styles";
import { getLoanById } from "../actions";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Loan = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loan = useSelector((state) => state.loan);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchLoan() {
      dispatch(await getLoanById(window.location.pathname.split("/").pop()));
    }
    fetchLoan();
  }, [dispatch]);

  return (
    <Layout>
      {loan.fetchState === "Loan by Id Error" && (
        <Redirect to={`${process.env.PUBLIC_URL}/account`} />
      )}
      {loan.fetchState === "Loan by Id Success" ? (
        <div className={classes.wizard}>
          <Card raised className={classes.card}>
            <Typography
              color="primary"
              variant="h1"
              className={classes.largeHeader}
            >
              {copy.account.loan.line11}
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              style={{ margin: "15px auto" }}
            >
              {copy.account.loan.line1}
            </Typography>
            <LoanInfoCard copy={copy.account.loan} loan={loan.currentLoan} />
          </Card>
          <Link href={`${process.env.PUBLIC_URL}/account`} underline="none">
            <Fab
              color="primary"
              aria-label="Submit"
              style={{ marginBottom: 50 }}
            >
              <NavigateBeforeIcon />
            </Fab>
          </Link>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            margin: "10vh auto",
            minHeight: "100vh"
          }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}
    </Layout>
  );
};

export default Loan;
