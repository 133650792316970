import React, { useEffect, useState } from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Card from "@material-ui/core/Card";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import smoothscroll from "smoothscroll-polyfill";
import OfferDetailCard from "./OfferDetailCard";
import { RenderSelectField } from "../../utlis/input";
import useStyles from "../styles";
import filterLoan from "../../utlis/filterLoan";

const Offer1 = ({ copy, offers, setOffer, setPage }) => {
  const classes = useStyles();

  const [filterOffers, setFilterOffers] = useState("MO_PAYMENT");
  const [loanOffers, setLoanOffers] = useState(null);
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setLoanOffers(offers);
  }, [offers]);

  function handleViewAll(event) {
    setLoanOffers(offers);
    setViewAll(false);
  }

  function handleChange(event) {
    setFilterOffers(event.target.value);
    const result = filterLoan(event.target.value, loanOffers);
    setLoanOffers(result);
  }

  function filterLoanCard(id) {
    setLoanOffers(loanOffers.filter((offer) => offer.id !== id));
  }

  return (
      <div className={classes.wizard}>
        <Card raised className={classes.card}>
          <Typography
              variant="h1"
              color="primary"
              className={classes.largeHeader}
          >
            {copy.account.offers1.line1}
          </Typography>
          <Typography variant="caption" className={classes.disclaimerSmall}>
            {copy.account.offers1.line2}
          </Typography>


          {loanOffers && loanOffers.length > 1 && (
              <div style={{ marginTop: 30 }}>
                <RenderSelectField
                    name="filter offers"
                    label={copy.account.offers1.line5}
                    values={filterOffers}
                    onChange={handleChange}
                >
                  <MenuItem value="MO_PAYMENT">
                    {copy.account.offers1.filterOffers[0]}
                  </MenuItem>
                  <MenuItem value="LOAN_TERM">
                    {copy.account.offers1.filterOffers[1]}
                  </MenuItem>
                  <MenuItem value="APR">
                    {copy.account.offers1.filterOffers[2]}
                  </MenuItem>
                  <MenuItem value="MAX_LOAN">
                    {copy.account.offers1.filterOffers[3]}
                  </MenuItem>
                </RenderSelectField>
              </div>
          )}

          {loanOffers && loanOffers.length >= 1 && loanOffers[0].originator.key === "lightstream" && (
              <Typography
                  variant={"h5"}
                  color={"caption"}
                  className={classes.disclaimerSmall}
              >
                <strong>*Lender Disclaimer:</strong>&nbsp;&nbsp;
                You are not yet approved or pre-approved for a loan or a specific rate. Please click 'Select' to complete your LightStream application to see exactly what LightStream can offer you. Your APR may differ based on loan purpose, amount, term, and your credit profile. Excellent credit is required to qualify for lowest rates. Rate is quoted with AutoPay discount, which is only available when you select AutoPay prior to loan funding. Rates under the invoicing option may be higher. Conditions and limitations apply. Advertised rates and terms are subject to change without notice. Not a commitment to lend. Payment example: Monthly payments for a $10,000 loan at 5.95% APR with a term of 3 years would result in 36 monthly payments of $303.99.

                © 2021 Truist Financial Corporation. SunTrust, Truist, LightStream, the LightStream logo, and the SunTrust logo are service marks of Truist Financial Corporation. All other trademarks are the property of their respective owners. Lending services provided by Truist Bank.
              </Typography>
          )}

          {loanOffers !== null &&
          loanOffers.map((offer, key) => (
              <OfferDetailCard
                  key={key}
                  {...offer}
                  filterLoanCard={filterLoanCard}
                  setOffer={setOffer}
                  setPage={setPage}
                  setViewAll={setViewAll}
                  copy={copy.account.loancard}
                  offer1
              />
          ))}

          {viewAll && (
              <div style={{ width: 300, margin: "50px auto 0px" }}>
                <Fab
                    variant="extended"
                    aria-label="Submit"
                    color="secondary"
                    type="submit"
                    className={classes.submitButton}
                    onClick={handleViewAll}
                >
                  {copy.account.offers1.line3}
                </Fab>
              </div>
          )}
          <Link href={`${process.env.PUBLIC_URL}/account`} underline="none">
            <Fab color="primary" aria-label="Submit" style={{ marginBottom: 50 }}>
              <NavigateBeforeIcon />
            </Fab>
          </Link>
        </Card>
      </div>
  );
};

Offer1.propTypes = {
  copy: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  setOffer: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

export default Offer1;
