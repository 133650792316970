import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import NumberFormat from "react-number-format";
import NoOfferCard from "./NoOfferCard";
import MerchantInfoCard from "./MerchantInfoCard";
import useStyles from "../styles";

const OfferCard = ({ user, result, copy }) => {
    const classes = useStyles();

    const [isGenesis, setIsGenesis] = useState(false);
    const [isAffLto, setIsAffLto] = useState(false);

    useEffect(() => {
        if (result && result.offers.length >= 1) {
            const originator = result.offers[0].originator.key;
            if (result.offers.length === 1) {
                if (originator === "genesis") setIsGenesis(true);
                if (originator === "americanfirst") setIsAffLto(true);
            }
        }
    }, [result]);

    function showOffers (event) {
        if(isGenesis || isAffLto) {
            const jwt = sessionStorage.getItem("jwt");
            const url = (jwt) ? `${result.offers[0].apply_url}?token=${jwt}` : result.offers[0].apply_url;
            window.location.replace(url);
        } else {
            window.location.replace(`${process.env.PUBLIC_URL}/offers`);
        }
    }

    return result && result.actions.reapply !== null ?
        ( <NoOfferCard personal={user} result={result} /> ) : ( (isGenesis || isAffLto) ?
                <>
                    <Card className={classes.card} raised>
                        <Typography
                            variant={"body1"}
                            color={"primary"}
                            className={classes.cardName}
                        >
                            Hi {result.personal.first_name},
                        </Typography>
                        <Typography
                            color={"primary"}
                            variant={"h1"}
                            classes={classes.largeHeader}
                        >
                            Congratulations
                        </Typography>
                        <Typography
                            variant={"h1"}
                            color={"secondary"}
                            className={classes.largeSubHeader}
                            style={{marginTop: 5}}
                        >
                            You've Received an Offer!
                        </Typography>
                        <Typography
                            variant="body2"
                            color="primary"
                            style={{ margin: "30px auto" }}
                        >
                            Next, you'll be redirected to view the offer details, terms & conditions, and disclosures. Click
                            'View Offer' to continue.
                        </Typography>
                        <Card raised elevation="4" className={classes.innerCard}>

                            <CardHeader
                                title={
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                        className={classes.innerCardHeader}
                                    >
                                        {isGenesis ? ("Pre-Approved Credit Limit") : ("Approved Up To")}
                                    </Typography>
                                }
                                className={classes.offerCardHeader}
                            />
                            <CardContent className={classes.innerCardContent}>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    color="primary"
                                    className={classes.largeHeader}
                                >
                                    <NumberFormat
                                        value={result.amount}
                                        displayType="text"
                                        thousandSeparator
                                        prefix="$"
                                    />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <div
                                    style={{ width: "100%", textAlign: "center", marginBottom: 30 }}
                                >
                                    <Fab onClick={showOffers} variant="extended" color="secondary" aria-label="Submit">
                                        <span style={{ fontSize: "0.7rem" }}>View Offer</span>
                                    </Fab>
                                </div>
                            </CardActions>
                        </Card>
                        {result.merchant && (
                            <MerchantInfoCard merchant={result.merchant} copy={copy.line3} />
                        )}
                    </Card>
                </> :
                <>
                    <Card className={classes.card} raised>
                        <Typography
                            variant="body1"
                            color="primary"
                            className={classes.cardName}
                        >
                            Hi {result.personal.first_name},
                        </Typography>
                        <Typography
                            color="primary"
                            variant="h1"
                            className={classes.largeHeader}
                        >
                            {copy.line5}
                        </Typography>
                        <Typography
                            color="secondary"
                            variant="h1"
                            className={classes.largeSubHeader}
                        >
                            {copy.line6}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="primary"
                            style={{ margin: "30px auto" }}
                        >
                            {copy.line1}
                        </Typography>
                        <Card raised elevation="4" className={classes.innerCard}>
                            <CardHeader
                                title={
                                    <Typography
                                        variant="body1"
                                        color="primary"
                                        className={classes.innerCardHeader}
                                    >
                                        {copy.line4}
                                    </Typography>
                                }
                                className={classes.offerCardHeader}
                            />
                            <CardContent className={classes.innerCardContent}>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    color="primary"
                                    className={classes.largeHeader}
                                >
                                    <NumberFormat
                                        value={result.amount}
                                        displayType="text"
                                        thousandSeparator
                                        prefix="$"
                                    />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <div
                                    style={{ width: "100%", textAlign: "center", marginBottom: 30 }}
                                >
                                    <Fab onClick={showOffers} variant="extended" color="secondary" aria-label="Submit">
                                        <span style={{ fontSize: "0.7rem" }}>{copy.line2}</span>
                                    </Fab>
                                </div>
                            </CardActions>
                        </Card>

                        {/* -----Merchant Section----- */}
                        {result.merchant && (
                            <MerchantInfoCard merchant={result.merchant} copy={copy.line3} />
                        )}
                    </Card>
                </>
        );
};

OfferCard.propTypes = {
    user: PropTypes.object.isRequired,
    result: PropTypes.object.isRequired,
    copy: PropTypes.object.isRequired
};

export default OfferCard;
