import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoanInfoCard from "./LoanInfoCard";
import NoOfferCard from "./NoOfferCard";
import useStyles from "../styles";

const LoanCard = ({ loan, user, lead, ...copy }) => {
  const classes = useStyles();

  const [currentLoan, setCurrentLoan] = useState({});

  useEffect(() => {
    // iterate through loans, if any loans are in progress set that as currentloan, otherwise set currentloan as latest loan
    const tempArray = [];
    loan.forEach((el) => {
      if (el.status !== "declined" && el.status !== "funded")
        tempArray.push(el);
    });
    if (tempArray.length > 0) setCurrentLoan(tempArray[0]);
    else setCurrentLoan(loan[0]);
  }, [loan]);

  return (
    <>
      {/* ---Loading loan--- */}
      {Object.getOwnPropertyNames(currentLoan).length === 0 && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            margin: "10vh auto",
            minHeight: "100vh"
          }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}

      {/* ---Loan loaded, there is a loan in progress--- */}
      {Object.getOwnPropertyNames(currentLoan).length !== 0 &&
        currentLoan.status !== "declined" &&
        currentLoan.status !== "funded" && (
          <div className={classes.wizard}>
            <Card raised className={classes.card}>
              <Typography
                variant="body1"
                color="primary"
                className={classes.largeHeader}
              >
                Hi {user.first_name},
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ margin: "15px auto" }}
              >
                {copy.account.loan.line1}
              </Typography>
              <LoanInfoCard copy={copy.account.loan} loan={currentLoan} />
            </Card>
          </div>
        )}

      {/* ---Loan loaded, loan funded and lead not expired--- */}
      {Object.getOwnPropertyNames(currentLoan).length !== 0 &&
        currentLoan.status === "funded" &&
        lead &&
        lead.actions.reapply === null && (
          <div className={classes.wizard}>
            <Card raised className={classes.card}>
              <Typography
                variant="body1"
                color="primary"
                className={classes.largeHeader}
              >
                Hi {user.first_name},
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ margin: "15px auto" }}
              >
                {copy.account.loan.fundedline1}{" "}
              </Typography>
              <LoanInfoCard copy={copy.account.loan} loan={currentLoan} />
            </Card>
          </div>
        )}

      {/* ---Loan loaded, loan funded and lead expired--- */}
      {Object.getOwnPropertyNames(currentLoan).length !== 0 &&
        currentLoan.status === "funded" &&
        lead &&
        lead.actions.reapply !== null && (
          <NoOfferCard personal={user} result={lead} />
        )}

      {/* ---Loan loaded, loan declined and lead not expired--- */}
      {Object.getOwnPropertyNames(currentLoan).length !== 0 &&
        currentLoan.status === "declined" &&
        lead &&
        lead.actions.reapply === null && (
          <div className={classes.wizard}>
            <Card raised className={classes.card}>
              <Typography
                variant="body1"
                color="primary"
                className={classes.largeHeader}
              >
                Hi {user.first_name},
              </Typography>
              <Typography
                variant="body2"
                color="primary"
                style={{ margin: "15px auto" }}
              >
                {copy.account.loan.declinedline1}{" "}
                <span className={classes.header}>
                  {lead.reapply_countdown} {copy.account.loan.declinedline2}
                </span>{" "}
                {copy.account.loan.declinedline3}
              </Typography>
              <LoanInfoCard copy={copy.account.loan} loan={currentLoan} />
            </Card>
          </div>
        )}

      {/* ---Loan loaded, loan declined and lead expired--- */}
      {Object.getOwnPropertyNames(currentLoan).length !== 0 &&
        currentLoan.status === "declined" &&
        lead &&
        lead.actions.reapply !== null && (
          <NoOfferCard personal={user} result={lead} />
        )}
    </>
  );
};

LoanCard.propTypes = {
  loan: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired
};

export default LoanCard;
