/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import smoothscroll from "smoothscroll-polyfill";
import Error from "../Error";
import useStyles from "../styles";
import { RenderTextField, RenderSelectField } from "../../utlis/input";
import { required, composeValidators, ZipField } from "../form/utils";
import { Address, User } from "../../api/Agent";
import states from "../../utlis/states";

const postcode = require("postcode-validator");

const checkZip = value =>
  postcode.validate(value, "US") ? undefined : "Invalid zip code";

const AddressForm = ({ copy, ...user }) => {
  const classes = useStyles();

  const [state, setState] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [result, setResult] = useState({});
  const [error, setError] = useState("");
  const [validation, setValidation] = useState(false);

  function handleChange(event) {
    setState(event.target.value);
  }

  const submitPrimary = async address => {
    async function fetchData() {
      try {
        const res = await Address.makePrimary(address.id, address);
        setResult(res);
      } catch (error) {
        setError(error.response.text);
      }
    }
    fetchData();
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await User.getAddresses();
      setAddresses(res.results);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (Object.getOwnPropertyNames(result).length !== 0) {
        const res = await User.getAddresses();
        setAddresses(res.results);
        setValidation(true);
      }
    }
    fetchData();
  }, [copy.line3, result]);

  const onSubmit = async values => {
    const newAddress = {
      user,
      primary: false,
      ...values
    };
    try {
      const res = await User.createAddress(newAddress);
      setResult(res);
    } catch (error) {
      setError(error.response.text);
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h1"
              align="center"
              className={classes.settingHeader}
            >
              {copy.address.line2}
            </Typography>
            <Field
              name="street1"
              id="street1"
              type="text"
              component={RenderTextField}
              label="Steet Address"
              validate={required}
            />
            <Error name="street1" {...classes} />
            <Field
              name="street2"
              id="street2"
              type="text"
              component={RenderTextField}
              label="Suite/Apartment"
            />
            <Error name="street2" {...classes} />
            <Field
              name="city"
              id="city"
              type="text"
              component={RenderTextField}
              label="City"
              validate={required}
            />
            <Error name="city" {...classes} />
            <Field
              name="state"
              label="State"
              component={RenderSelectField}
              values={state}
              onChange={handleChange}
              validate={required}
            >
              {states.map((state, key) => (
                <MenuItem key={key} value={state.value}>
                  {state.option}
                </MenuItem>
              ))}
            </Field>
            <Error name="state" {...classes} />
            <Field
              name="zip"
              id="zip"
              type="tel"
              component={RenderTextField}
              label="Zip Code"
              InputProps={{
                inputComponent: ZipField
              }}
              validate={composeValidators(required, checkZip)}
            />
            <Error name="zip" {...classes} />

            {validation && (
              <Typography
                variant="caption"
                color="secondary"
                align="center"
                display="block"
              >
                {copy.address.line3}
              </Typography>
            )}

            <Typography
              variant="caption"
              color="error"
              align="center"
              display="block"
            >
              {error}
            </Typography>

            <Fab
              variant="extended"
              aria-label="Submit"
              color="secondary"
              type="submit"
              disabled={invalid || submitting}
              className={classes.submitButton}
            >
              {copy.button}
            </Fab>
          </form>
        )}
      />
      <Typography variant="h1" align="center" className={classes.settingHeader}>
        {copy.address.line1}
      </Typography>
      {addresses &&
        addresses.map((address, key) => (
          <div key={key}>
            <Typography
              variant="body1"
              align="center"
              className={classes.subHeader}
            >
              {address.street1},{address.street2}
              <br />
              {address.city}, {address.state} {address.zip}
            </Typography>
            {address.type && (
              <Typography
                variant="body2"
                align="center"
                className={classes.disclaimer}
              >
                {address.type}
              </Typography>
            )}
            {address.primary ? (
              <Typography
                variant="body2"
                align="center"
                style={{ margin: "20px auto", fontWeight: 600 }}
                color="secondary"
              >
                {copy.primary}
              </Typography>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "20px auto"
                }}
              >
                <Fab
                  variant="extended"
                  aria-label="Submit"
                  color="primary"
                  type="submit"
                  onClick={() => submitPrimary(address)}
                  className={classes.primaryLink}
                >
                  {copy.primaryLink}
                </Fab>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

AddressForm.propTypes = {
  copy: PropTypes.object.isRequired
};

export default AddressForm;
