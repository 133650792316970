import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import useStyles from "../styles";

const OrderListCard = ({ copy, ...order }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} raised style={{ marginBottom: 20 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <div style={{ borderRight: "1px solid grey" }}>
              <Typography variant="body2" className={classes.cardSubHeader}>
                {order.merchant.name}
              </Typography>
              {order.items.length > 0 && (
                <Typography variant="body2" className={classes.cardDisclaimer}>
                  {order.items.length} {copy.line8}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={6}>
            <>
              <Typography
                variant="body1"
                align="center"
                className={classes.cardSubHeader}
              >
                <NumberFormat
                  value={order.total}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </Typography>
              {order.actions.approve !== null && (
                <Typography
                  align="center"
                  variant="body2"
                  className={classes.primaryColor}
                >
                  {copy.line6}
                </Typography>
              )}
              {order.actions.approve === null && order.status === "approved" && (
                <Typography
                  variant="body2"
                  color="secondary"
                  align="center"
                  style={{ marginBottom: 10 }}
                >
                  {copy.line5}
                </Typography>
              )}
              {order.actions.approve === null && order.status === "cancelled" && (
                <Typography
                  align="center"
                  variant="body2"
                  color="error"
                  style={{ marginBottom: 10 }}
                >
                  {copy.line7}
                </Typography>
              )}
              {order.actions.approve === null && order.status === "paid" && (
                <Typography
                  variant="body2"
                  color="secondary"
                  align="center"
                  style={{ marginBottom: 10 }}
                >
                  {copy.line12}
                </Typography>
              )}
              {order.actions.approve === null && order.status === "refunded" && (
                <Typography
                  variant="body2"
                  color="secondary"
                  align="center"
                  style={{ marginBottom: 10 }}
                >
                  {copy.line13}
                </Typography>
              )}
              {order.actions.approve === null &&
                order.status !== "cancelled" &&
                order.status !== "approved" &&
                order.status !== "paid" &&
                order.status !== "refunded" && (
                  <Typography
                    align="center"
                    variant="body2"
                    color="secondary"
                    style={{ marginBottom: 10 }}
                  >
                    {copy.line11}
                  </Typography>
                )}
            </>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <div style={{ width: "100%", textAlign: "center" }}>
          {order.actions.approve !== null ? (
            <Link href={`orders/${order.id}`} underline="none">
              <Fab variant="extended" color="secondary" aria-label="Submit">
                <span style={{ fontSize: "0.7rem" }}>{copy.line9}</span>
              </Fab>
            </Link>
          ) : (
            <Link href={`orders/${order.id}`} underline="none">
              <Fab variant="extended" color="secondary" aria-label="Submit">
                <span style={{ fontSize: "0.7rem" }}>{copy.line4}</span>
              </Fab>
            </Link>
          )}
        </div>
      </CardActions>
    </Card>
  );
};

OrderListCard.propTypes = {
  copy: PropTypes.object.isRequired
};

export default OrderListCard;
