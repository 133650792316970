import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import useStyles from "./styles";

const Page11 = ({ header, copy }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line1}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line2}
      </Typography>
    </Wizard.Page>
  );
};

Page11.propTypes = {
  header: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
};

export default Page11;
