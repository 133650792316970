import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field } from "react-final-form";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import useStyles from "./styles";
import Error from "../Error";
import { RenderTextField } from "../../utlis/input";
import { required, composeValidators, SsnField } from "./utils";
import HelpOutline from "@material-ui/icons/HelpOutline";

const minValue = min => value => (value.length >= min ? undefined : "Required");

const Page16 = ({
  copy,
  header,
  setAgree,
  authFetchState,
  userFetchState,
  leadFetchState
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (checked) setAgree(true);
    else if (!checked) setAgree(false);
  }, [checked, setAgree]);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line1}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line2}
      </Typography>
      <Typography
        variant="body2"
        display="block"
        className={classes.disclaimer}
        style={{ marginBottom: 30 }}
      >
        {copy.line3}
      </Typography>
      <Field
        name="ssn"
        id="ssn"
        type="tel"
        component={RenderTextField}
        label="Social Security Number"
        validate={composeValidators(required, minValue(9))}
        InputProps={{
          inputComponent: SsnField
        }}
      />
      <Error name="ssn" {...classes} />
      {(authFetchState === "Loading" ||
        leadFetchState === "Loading" ||
        userFetchState === "Loading") && (
        <div
          style={{ width: "100%", textAlign: "center", margin: "-50px auto" }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}
      <Typography
        variant="body1"
        align="center"
        display="block"
        className={classes.subHeader}
      >
        {copy.line4}
          <Tooltip placement="bottom-start" title="If you accept an offer of credit, a hard inquiry may appear on your credit">
              <HelpOutline style={{ fontSize: 12, color: "#19C27F" }} />
          </Tooltip>
      </Typography>
      <div style={{ float: "left" }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} value />}
        />
      </div>
      <Typography
        variant="body1"
        align="left"
        display="block"
        className={classes.smallDisclaimer}
        style={{ marginBottom: 50 }}
      >
        {copy.line5}{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypossible.com/electronic-consent"
          color="secondary"
          underline="none"
        >
          {copy.line6}
        </Link>
        ,{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypossible.com/credit-authorization-agreement"
          color="secondary"
          underline="none"
        >
          {copy.line7}
        </Link>
        ,{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypossible.com/privacy-policy"
          color="secondary"
          underline="none"
        >
          {copy.line8}
        </Link>
        ,{" "}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.paypossible.com/account-tos"
          color="secondary"
          underline="none"
        >
          {copy.line9}
        </Link>{" "}
        {copy.line10}

      </Typography>
    </Wizard.Page>
  );
};

Page16.propTypes = {
  copy: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  setAgree: PropTypes.func.isRequired,
  authFetchState: PropTypes.string.isRequired,
  leadFetchState: PropTypes.string.isRequired,
  userFetchState: PropTypes.string.isRequired
};

export default Page16;
