import React from "react";
import PropTypes from "prop-types";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import Typography from "@material-ui/core/Typography";
import MenuList from "@material-ui/core/MenuList";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import footerIndex from "../../utlis/footerIndex";
import useStyles from "../styles";

const Footer = ({ logo }) => {
  const classes = useStyles();

  const renderMenu = (link, key) => {
    if (link.external) {
      return (
        <div key={key}>
          <a
            href={link.path}
            className={classes.link}
            id="link"
            style={{ textDecoration: "none" }}
          >
            <Typography
              color="inherit"
              className={classes.footerLink}
              id="footerLink"
            >
              {link.name}
            </Typography>
          </a>
        </div>
      );
    }
    return (
      <div key={key}>
        <Link href={link.path} underline="none">
          <Typography
            color="inherit"
            className={classes.footerLink}
            id="footerLink"
          >
            {link.name}
          </Typography>
        </Link>
      </div>
    );
  };

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item md={3} xs={12}>
            <img
              alt="PayPossible Logo"
              title="PayPossible Logo"
              src={logo}
              className={classes.logoFooter}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <MenuList>
              <label className={classes.footerMenuHeader}>
                {footerIndex.headers.header1}
              </label>
              {footerIndex.row1.map((link, col1Key) =>
                renderMenu(link, col1Key)
              )}
            </MenuList>
          </Grid>
          <Grid item md={2} xs={12}>
            <MenuList>
              <label className={classes.footerMenuHeader}>
                {footerIndex.headers.header2}
              </label>
              {footerIndex.row2.map((link, col2Key) =>
                renderMenu(link, col2Key)
              )}
            </MenuList>
          </Grid>
          <Grid item md={2} xs={12}>
            <MenuList>
              <label className={classes.footerMenuHeader}>
                {footerIndex.headers.header3}
              </label>
              {footerIndex.row3.map((link, col3Key) =>
                renderMenu(link, col3Key)
              )}
            </MenuList>
          </Grid>
          <Grid item md={3} xs={12} className={classes.socialCol}>
            <a
              href="https://www.facebook.com/paypossible"
              target="_blank"
              rel="noopener noreferrer"
              id="link"
            >
              <FacebookIcon color="primary" className={classes.socialIcon} />
            </a>
            <a
              href="https://www.instagram.com/paypossible/"
              target="_blank"
              rel="noopener noreferrer"
              id="link"
            >
              <InstagramIcon color="primary" className={classes.socialIcon} />
            </a>
            <a
              href="https://twitter.com/paypossible"
              target="_blank"
              rel="noopener noreferrer"
              id="link"
            >
              <TwitterIcon color="primary" className={classes.socialIcon} />
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  logo: PropTypes.string.isRequired
};

export default Footer;
