import React from "react";
import Typography from "@material-ui/core/Typography";

const brand = (process.env.REACT_APP_FE === "enable") ? "Enable Financing" : "PayPossible";
const MarketingDisclosure = () => {
    return (
        <Typography style={{ fontSize: "0.5rem", fontWeight: 600, paddingLeft: 10, paddingTop: 45 }} align="center">
            You authorize ({brand} “us”) to market products and services to you as permitted by law. This includes authorization for us to share with others your information, including your social security number, date of birth, and income, to offer such products and services including offers of credit to help you make purchases from our partner merchants.
        </Typography>
    );
}

export default MarketingDisclosure;
