import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NoOfferCard, OfferCard, OrderCard, LoanCard } from "./card";
import useStyles from "./styles";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Loaded = () => {
  const classes = useStyles();

  const lead = useSelector((state) => state.lead);
  const loan = useSelector((state) => state.loan);
  const order = useSelector((state) => state.order);
  const user = useSelector((state) => state.user);

  const [ordersCheck, setOrdersCheck] = useState(false);

  useEffect(() => {
    if (
      order.order.hasOwnProperty("results") &&
      order.order.results.length > 0
    ) {
      order.order.results.forEach((order) => {
        if (order.status !== "cancelled") setOrdersCheck(true);
      });
    }
  });

  return (
    <div className={classes.wizard}>
      {/* --- Orders (not all cancelled) --- */}
      {order.order.hasOwnProperty("results") &&
        order.order.results.length > 0 &&
        ordersCheck && (
          <OrderCard
            order={order.order.results}
            lead={lead.lead.results[0]}
            copy={copy.account.ordercard}
            user={user}
          />
        )}

      {/* --- All Orders cancelled --- */}
      {order.order.hasOwnProperty("results") &&
        order.order.results.length > 0 &&
        !ordersCheck && (
          <LoanCard
            loan={loan.loan.results}
            lead={lead.lead.results[0]}
            user={user}
            {...copy}
          />
        )}

      {/* --- Loans --- */}
      {order.order.hasOwnProperty("results") &&
        order.order.results.length <= 0 &&
        loan.loan.hasOwnProperty("results") &&
        loan.loan.results.length > 0 &&
        lead.lead.hasOwnProperty("results") && (
          <LoanCard
            loan={loan.loan.results}
            lead={lead.lead.results[0]}
            user={user}
            {...copy}
          />
        )}

      {/* --- Offers / No Loans --- */}
      {order.order.hasOwnProperty("results") &&
        order.order.results.length <= 0 &&
        loan.loan.hasOwnProperty("results") &&
        loan.loan.results.length <= 0 &&
        lead.lead.hasOwnProperty("results") &&
        lead.lead.results[0].hasOwnProperty("offers") &&
        lead.lead.results[0].offers.length > 0 && (
          <>
            <OfferCard
              result={lead.lead.results[0]}
              copy={copy.account.offercard}
              user={user}
            />
          </>
        )}

      {/* --- No Offers --- */}
      {order.order.hasOwnProperty("results") &&
        order.order.results.length <= 0 &&
        loan.loan.hasOwnProperty("results") &&
        loan.loan.results.length <= 0 &&
        lead.lead.hasOwnProperty("results") &&
        lead.lead.results[0].hasOwnProperty("offers") &&
        lead.lead.results[0].offers.length <= 0 && (
          <NoOfferCard personal={user} result={lead.lead.results[0]} />
        )}
    </div>
  );
};

export default Loaded;
