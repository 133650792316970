/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import smoothscroll from "smoothscroll-polyfill";
import Error from "../Error";
import useStyles from "../styles";
import { RenderTextField } from "../../utlis/input";
import { required, composeValidators } from "../form/utils";
import { Email, User } from "../../api/Agent";
import { getUser } from "../../actions";

const emailValidation = (value) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return "Invalid email address";
  }
  return null;
};

const EmailForm = ({ copy, ...user }) => {
  const classes = useStyles();

  const [error, setError] = useState("");
  const [result, setResult] = useState({});
  const [validation, setValidation] = useState(false);

  const dispatch = useDispatch();

  const submitPrimary = (email) => {
    async function fetchData() {
      try {
        const res = await Email.makePrimary(email.id, email);
        setResult(res);
        setError("");
      } catch (error) {
        setError(error.response.text);
        setValidation(true);
      }
    }

    fetchData();
  };

  const onSubmit = async (values) => {
    const emailObj = {
      user,
      primary: false,
      ...values
    };

    try {
      const res = await User.createEmail(emailObj);
      setResult(res);
      setError("");
    } catch (error) {
      setError(JSON.parse(error.response.text));
      setValidation(false);
    } finally {
      await Email.sendConfirmation(values.email);
    }
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (Object.getOwnPropertyNames(result).length !== 0) {
        dispatch(getUser());
        setValidation(true);
        setResult({});
      }
    }
    fetchData();
  }, [dispatch, result]);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Typography
              variant="h1"
              align="center"
              className={classes.settingHeader}
            >
              {copy.email.line2}
            </Typography>
            <Field
              name="email"
              id="email"
              type="email"
              component={RenderTextField}
              label="Email"
              validate={composeValidators(required, emailValidation)}
            />
            <Error name="email" {...classes} />

            {validation && (
              <Typography
                variant="caption"
                color="secondary"
                align="center"
                display="block"
              >
                {copy.email.line3}
              </Typography>
            )}

            {error &&
              error.email.map((err, key) => (
                <Typography
                  variant="caption"
                  color="error"
                  align="center"
                  display="block"
                  key={key}
                >
                  {err}
                </Typography>
              ))}

            <Fab
              variant="extended"
              aria-label="Submit"
              color="secondary"
              type="submit"
              disabled={invalid || submitting}
              className={classes.submitButton}
            >
              {copy.button}
            </Fab>
          </form>
        )}
      />
      <Typography variant="h1" align="center" className={classes.settingHeader}>
        {copy.email.line1}
      </Typography>
      {user.emails &&
        user.emails.map((email, key) => (
          <div key={key}>
            <Typography
              variant="body1"
              align="center"
              className={classes.subHeader}
            >
              {email.email}
            </Typography>

            {email.primary ? (
              <Typography
                variant="body2"
                align="center"
                style={{ margin: "20px auto", fontWeight: 600 }}
                color="secondary"
              >
                {copy.primary}
              </Typography>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "20px auto"
                }}
              >
                <Fab
                  variant="extended"
                  aria-label="Submit"
                  type="submit"
                  onClick={() => submitPrimary(email)}
                  className={classes.primaryLink}
                >
                  {copy.primaryLink}
                </Fab>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

EmailForm.propTypes = {
  copy: PropTypes.object.isRequired
};

export default EmailForm;
