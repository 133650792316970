import * as actions from "../actions";

const initValues = {
  ping: {},
  fetchState: ""
};

export default (ping = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_PING:
      return { ...initValues, fetchState: "" };
    case actions.PING_LOADING:
      return { ...ping, fetchState: "Loading" };
    case actions.PING_SUCCESS:
      return { ping: action.payload, fetchState: "Success" };
    case actions.PING_ERROR:
      return { ping: action.payload, fetchState: "Error" };
    default:
      return ping;
  }
};
