import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import Layout from "./layout/Layout";
import {
  AddressForm,
  EmailForm,
  PersonalForm,
  PasswordForm,
  PhoneForm
} from "./settings-forms";
import { RenderSelectField } from "../utlis/input";
import useStyles from "./styles";
import { clearFetchState } from "../actions";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Settings = () => {
  const classes = useStyles();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [settings, setSettings] = useState("personal");

  function handleChange(event) {
    setSettings(event.target.value);
  }

  useEffect(() => {
    dispatch(clearFetchState());
  }, [dispatch]);

  return (
    <Layout>
      <div
        className={classes.wizard}
        style={{ marginTop: 30, minHeight: "100vh" }}
      >
        <Card raised className={classes.card}>
          <RenderSelectField
            name="settings"
            label="Settings"
            values={settings}
            onChange={handleChange}
          >
            {copy.settings.options.map((option, key) => (
              <MenuItem key={key} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </RenderSelectField>
          {settings === "personal" && (
            <PersonalForm copy={copy.settings} {...user} />
          )}
          {settings === "password" && (
            <PasswordForm copy={copy.settings} {...user} />
          )}
          {settings === "address" && (
            <AddressForm copy={copy.settings} {...user} />
          )}
          {settings === "phone" && <PhoneForm copy={copy.settings} {...user} />}
          {settings === "email" && <EmailForm copy={copy.settings} {...user} />}
        </Card>
      </div>
    </Layout>
  );
};

export default Settings;
