/* eslint-disable consistent-return */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { refreshToken, verifyToken, logout } from "../actions";
import history from "../myHistory";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  useInterval(
    async () => {
      dispatch(await refreshToken());
    },
    auth.fetchState !== "Error" ? 300000 : null
  );

  useEffect(() => {
    if (!auth.isAuth) {
      dispatch(logout());
      history.push(`${process.env.PUBLIC_URL}`);
    }
  }, [auth.fetchState, auth.isAuth, dispatch]);


  return auth.isAuth ? (
    children
  ) : (
    <div style={{ width: "100%", textAlign: "center", margin: "20vh auto" }}>
      <CircularProgress color="secondary" size="120px" thickness={1} />
    </div>
  );
};

AuthWrapper.propTypes = {
  children: PropTypes.any.isRequired
};

export default AuthWrapper;
