import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  appPadding: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "10vw",
      paddingRight: "10vw",
      paddingTop: "1vh"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "1vh",
      paddingBottom: "2vh"
    }
  },
  header: {
    color: theme.palette.primary.dark,
    marginBottom: 30,
    fontWeight: 600,
    lineHeight: "1.7rem"
  },
  subHeader: {
    color: theme.palette.primary.light,
    marginBottom: 30,
    fontWeight: 600,
    lineHeight: "1.7rem"
  },
  settingHeader: {
    color: theme.palette.primary.dark,
    margin: "30px auto",
    fontSize: "1.2rem",
    fontWeight: 600
  },
  largeHeader: {
    fontSize: "1.5rem",
    lineHeight: "2.5rem",
    fontWeight: 600
  },
  largeSubHeader: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: theme.palette.secondary.main
  },
  disclaimer: {
    color: theme.palette.primary.light,
    marginBottom: 30
  },
  primaryColor: {
    color: "#137db0"
  },
  logoWidget: {
    padding: "10px 0 0 25px"
  },
  logoHeader: {
    cursor: "pointer",
    maxHeight: 36,
    [theme.breakpoints.down("md")]: {
      maxWidth: 160,
      paddingTop: 10
    }
  },
  logoFooter: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 15
    },
    maxWidth: 200,
    maxHeight: 100
  },
  navLink: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid #fff`,
    transition: "all 0.2s",
    "&:hover": {
      color: theme.palette.primary.light,
      borderBottom: `2px solid ${theme.palette.secondary.light}`
    },
    background: "transparent",
    fontSize: "1rem",
    fontWeight: 600
  },
  ppWidget: {
    cursor: "pointer",
    display: "inline"
  },
  modalButton: {
    backgroundColor: "transparent",
    color: theme.palette.secondary.light,
    border: `2px solid ${theme.palette.secondary.light}`,
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: "#fff"
    }
  },
  submitButton: {
    display: "block",
    margin: "20px auto",
    minWidth: "80%"
  },
  wizard: {
    width: 450,
    maxWidth: "95%",
    margin: "30px auto",
    marginTop: "4vh",
    minHeight: "100vh"
  },
  stepsSection: {
    marginBottom: 10,
    marginTop: 50
  },
  step: {
    paddingTop: 5
  },
  stepsIcon: {
    fontSize: "1.8rem",
    marginRight: 5
  },
  footer: {
    flexGrow: 1,
    padding: "2vh 0 0",
    background: theme.palette.primary.main
  },
  footerLink: {
    color: "#fff",
    "&:hover": {
      color: theme.palette.secondary.light,
      background: "transparent !important"
    },
    fontSize: "1rem !important",
    transition: "all 0.2s !important",
    marginTop: 20
  },
  footerMenuHeader: {
    color: "#fff",
    fontSize: "1.1rem",
    fontWeight: 600
  },
  icon: {
    transition: "all 0.2s",
    margin: "0 16px",
    "&:hover": {
      color: theme.palette.secondary.light
    }
  },
  socialCol: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.up("md")]: {
      paddingTop: 15
    }
  },
  socialIcon: {
    color: "#fff",
    fontSize: "2rem",
    transition: "all 0.2s",
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  error: {
    marginTop: -20,
    marginBottom: 20
  },
  card: {
    minHeight: "30vh",
    margin: "20px auto 50px",
    display: "block",
    padding: "30px 15px",
    [theme.breakpoints.up("sm")]: {
      padding: 50
    }
  },
  innerCard: {
    margin: "20px auto 50px",
    display: "block",
    paddingBottom: "10px"
  },
  innerCardHeader: {
    color: "#fff",
    fontWeight: 600
  },
  innerCardContent: {
    padding: "30px"
  },
  cardHeader: {
    fontWeight: 600,
    color: theme.palette.primary.dark
  },
  cardText: {
    color: theme.palette.primary.main,
    marginBottom: 10
  },
  cardDisclaimer: {
    color: theme.palette.primary.light,
    marginBottom: 10,
    marginTop: 10
  },
  cardCase: {
    textTransform: "uppercase"
  },
  cardButton: {
    padding: "0 30px"
  },
  cardName: {
    fontWeight: 600,
    color: theme.palette.primary.light,
    lineHeight: "2rem"
  },
  disclaimerSmall: {
    color: theme.palette.primary.light,
    fontSize: "0.7rem"
  },
  exPanel: {
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, .18) !important"
  },
  link: {
    cursor: "pointer",
    transition: "all .3s",
    "&:hover": {
      color: theme.palette.secondary.dark
    }
  },
  primaryLink: {
    fontSize: ".65rem",
    border: `2px solid ${theme.palette.info.main}`,
    background: "transparent",
    color: theme.palette.info.main,
    "&:hover": {
      background: theme.palette.info.main,
      color: "#fff"
    }
  },
  userItem: {
    color: theme.palette.secondary.main,
    opacity: "1 !important",
    fontWeight: "600 !important",
    fontSize: "0.9rem !important",
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
  },
  offerCardHeader: {
    background: theme.palette.info.main
  },
  iconButton: {
    color: "#fff",
    transition: "all 0.3s"
  }
}));

export default useStyles;
