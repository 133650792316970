import * as actions from "../actions";

const initValues = true;

export default (redirect = initValues, action) => {
  switch (action.type) {
    case actions.REDIRECT:
      return action.payload;
    default:
      return redirect;
  }
};
