import * as actions from "../actions";

const initValues = {
  fetchState: "",
  isAuth: false
};

export default (auth = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_AUTH:
      return { ...initValues };
    case actions.REGISTER_LOADING:
      return { ...auth, fetchState: "Loading" };
    case actions.REGISTER_SUCCESS:
      return { fetchState: "Success", isAuth: true };
    case actions.REGISTER_ERROR:
      return { ...action.payload, fetchState: "Error", isAuth: false };
    case actions.LOGIN_LOADING:
      return { ...auth, fetchState: "Loading" };
    case actions.LOGIN_SUCCESS:
      return { fetchState: "Success", isAuth: true };
    case actions.LOGIN_ERROR:
      return { ...action.payload, fetchState: "Error", isAuth: false };
    case actions.VERIFY_LOADING:
      return { ...auth, fetchState: "Loading" };
    case actions.VERIFY_SUCCESS:
      return { fetchState: "Success", isAuth: true };
    case actions.VERIFY_ERROR:
      return { ...action.payload, fetchState: "Error", isAuth: false };
    case actions.REFRESH_LOADING:
      return { ...auth, fetchState: "Loading" };
    case actions.REFRESH_SUCCESS:
      return { fetchState: "Success", isAuth: true };
    case actions.REFRESH_ERROR:
      return { ...action.payload, fetchState: "Error", isAuth: false };
    case actions.SESSION_LOADING:
      return { ...auth, fetchState: "Loading" };
    case actions.SESSION_SUCCESS:
      return { fetchState: "Session Success", isAuth: true };
    case actions.SESSION_ERROR:
      return { ...action.payload, fetchState: "Error", isAuth: false };
    default:
      return auth;
  }
};
