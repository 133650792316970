import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.dark,
    marginBottom: 20,
    fontWeight: 600
  },
  largeHeader: {
    fontSize: "1.5rem",
    fontWeight: 600
  },
  subHeader: {
    color: theme.palette.secondary.light,
    lineHeight: "1.7rem",
    marginBottom: 40,
    fontWeight: 600
  },
  text: {
    color: theme.palette.primary.main,
    marginBottom: 30,
    marginTop: -10
  },
  fieldDisclaimer: {
    color: theme.palette.primary.light,
    marginTop: -20,
    marginBottom: 30
  },
  disclaimer: {
    color: theme.palette.primary.light
  },
  smallDisclaimer: {
    color: theme.palette.primary.light,
    fontSize: ".75rem"
  },
  error: {
    marginTop: -20,
    marginBottom: 20
  },
  select: {
    marginLeft: 5
  }
}));

export default useStyles;
