import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";

import payPossibleLogo from "../../images/payPossibleLogo.png";
import enableLogo from "../../images/enableLogo.png";
import absoluteLogo from "../../images/absoluteLogo.png";
import abcLogo from "../../images/abcLogo.jpg";
import ayrLogo from "../../images/ayrLogo.png";
import bettyStudiosLogo from "../../images/bettyStudiosLogo.jpg";
import auroraLogo from "../../images/auroraLogo.png";
import autoSalesLogo from "../../images/autoSalesLogo.png";
import bigOfficeLogo from "../../images/bigOfficeLogo.png";
import blockLegalLogo from "../../images/blockLegalLogo.png";
import budgetBlindsLogo from "../../images/budgetBlindsLogo.jpg";
import clearVisionLogo from "../../images/clearVisionLogo.png";
import connellyPhotoLogo from "../../images/connellyPhotoLogo.jpg";
import curatedLogo from "../../images/curatedLogo.jpg";
import customCoachLogo from "../../images/customCoachLogo.jpg";
import diamondSolutionsLogo from "../../images/diamondSolutionsLogo.png";
import eCellsLogo from "../../images/eCellsLogo.jpg";
import elegantOccasionsLogo from "../../images/elegantOccasionsLogo.jpg";
import everywhereLogo from "../../images/everywhereLogo.png";
import fitnessGrindLogo from "../../images/fitnessGrindLogo.png";
import floridaPaverLogo from "../../images/floridaPaverLogo.jpg";
import incrediHomeLogo from "../../images/incrediHomeLogo.png";
import innateHealthCareLogo from "../../images/innateHealthCareLogo.png";
import inspiredIdeaSolutionsLogo from "../../images/inspiredIdeaSolutionsLogo.jpg";
import justiceTaxLogo from "../../images/justiceTaxLogo.png";
import kingWheelLogo from "../../images/kingWheelLogo.png";
import kiserLogo from "../../images/kiserLogo.png";
import leCapriceLogo from "../../images/leCapriceLogo.png";
import lightRxLogo from "../../images/lightRxLogo.png";
import nbcLogo from "../../images/nbcLogo.png";
import medevateHealthLogo from "../../images/medevateHealthLogo.jpg";
import parkOutdoorLogo from "../../images/parkOutdoorLogo.png";
import mapToClientsLogo from "../../images/mapToClientsLogo.png";
import sfsbiLogo from "../../images/sfsbiLogo.png";
import ppbLogo from "../../images/PPBLogo.jpg";
import rijLogo from "../../images/rijLogo.jpg";
import rokLogo from "../../images/rokLogo.png";
import squareOneLogo from "../../images/squareOneLogo.jpg";
import tfoLogo from "../../images/tfoLogo.png";
import transMedCareLogo from "../../images/transMedCareLogo.png";
import trinityProximityLogo from "../../images/trinityProximityLogo​.png";
import weldScholarsLogo from "../../images/weldScholarsLogo.png";
import writeChoiceLogo from "../../images/writeChoiceLogo.png";
import xFinityLogo from "../../images/xFinityLogo.jpg";
import zgLogo from "../../images/zgLogo.png";
import zipLoansLogo from "../../images/zipLoansLogo.png";
import turfCenterLogo from "../../images/turfCenterLogo.png";
import springPoolsLogo from "../../images/springPoolsLogo.png";
//import mindfulHabitLogo from "../../images/mindfulHabitLogo.png";
import mindfulHabitLogo from "../../images/mindfulHabitCoachingLogo.png";
import recoverycircle from "../../images/recoverycircle.png";
import garnerfinancial from "../../images/garnerfinancial.png";
import valliani from "../../images/valliani.png";
import coBrand from "../../utlis/coBrand";

const NavWrapper = () => {
  const lead = useSelector((state) => state.lead);
  const merchant = useSelector((state) => state.merchantInfo);

  const [brand, setBrand] = useState("");

  useEffect(() => {
    // --------Co brand--------//
    function setCoBrand(code) {
      coBrand.curated.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("curated");
      });

      coBrand.zipLoans.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("zipLoans");
      });

      coBrand.innateHealthcare.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("innateHealthcare");
      });

      coBrand.inspiredIdeaSolutions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("inspiredIdeaSolutions");
      });

      coBrand.kiserArenaSpecialist.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("kiserArenaSpecialist");
      });

      coBrand.aurora.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("aurora");
      });

      coBrand.autoSales.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("autoSales");
      });

      coBrand.floridaPaver.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("floridaPaver");
      });

      coBrand.mapToClients.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("mapToClients");
      });

      coBrand.justiceTax.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("justiceTax");
      });

      coBrand.fitnessGrind.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("fitnessGrind");
      });

      coBrand.ppb.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("ppb");
      });

      coBrand.lightRx.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("lightRx");
      });

      coBrand.kingWheel.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("kingWheel");
      });

      coBrand.abc.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("abc");
      });

      coBrand.transMedCare.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("transMedCare");
      });

      coBrand.leCaprice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("leCaprice");
      });

      coBrand.budgetBlinds.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("budgetBlinds");
      });

      coBrand.everywhere.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("everywhere");
      });

      coBrand.trinityProximity.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("trinityProximity");
      });

      coBrand.medevateHealth.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("medevateHealth");
      });

      coBrand.medevateHealth.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("medevateHealth");
      });

      coBrand.connellyPhoto.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("connellyPhoto");
      });

      coBrand.zg.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("zg");
      });

      coBrand.recoverycircle.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("recoverycircle");
      });
      
      coBrand.garnerfinancial.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("garnerfinancial");
      });

      coBrand.diamondSolutions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("diamondSolutions");
      });

      coBrand.clearVision.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("clearVision");
      });

      coBrand.elegantOccasions.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("elegantOccasions");
      });

      coBrand.theoutfit.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("tfo");
      });

      coBrand.writeChoice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("writeChoice");
      });

      coBrand.parkOutdoor.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("parkOutdoor");
      });

      coBrand.incrediHome.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("incrediHome");
      });

      coBrand.bigOffice.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("bigOffice");
      });

      coBrand.squareOne.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("squareOne");
      });

      coBrand.sfsbi.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("sfsbi");
      });

      coBrand.nbc.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("nbc");
      });

      coBrand.bettyStudios.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("bettyStudios");
      });

      coBrand.blockLegal.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("blockLegal");
      });

      coBrand.customCoach.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("customCoach");
      });

      coBrand.xFinity.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("xFinity");
      });

      coBrand.absolute.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("absolute");
      });

      coBrand.springpools.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("springpools");
      });

      coBrand.turfcenter.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("turfcenter");
      });

      coBrand.rij.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("rij");
      });

      coBrand.eCells.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("eCells");
      });

      coBrand.rok.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("rok");
      });

      coBrand.ayr.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase()) setBrand("ayr");
      });

      coBrand.weldscholars.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("weldscholars");
      });

      coBrand.valliani.forEach((id) => {
        if (code && code.toLowerCase() === id.toLowerCase())
          setBrand("valliani");
      });

      coBrand.mindfulhabitcoaching.forEach((id) => {
        if (code && code.toLowerCase() === id.toLocaleLowerCase())
          setBrand("mindfulhabitcoaching");
      });

      if (code === "") setBrand("");
    }
    // --------End Co brand--------//

    if (merchant.fetchState === "Success") {
      setCoBrand(merchant.merchant.code);
    } else {
      setCoBrand("");
    }

    if (
      Object.getOwnPropertyNames(lead).length !== 0 &&
      lead.lead.hasOwnProperty("results") &&
      lead.lead.results.length > 0 &&
      lead.lead.results[0].merchant
    ) {
      setCoBrand(lead.lead.results[0].merchant.code);
    }

    if (process.env.REACT_APP_FE === "enable") {
      setBrand("enable");
    }
  }, [lead, merchant.fetchState, merchant.merchant.code]);

  return (
    <Navbar
      logo={
        brand === "aurora"
          ? auroraLogo
          : brand === "curated"
          ? curatedLogo
            : brand === "valliani"
          ? valliani
          : brand === "zipLoans"
          ? zipLoansLogo
          : brand === "innateHealthcare"
          ? innateHealthCareLogo
          : brand === "recoverycircle"
          ? recoverycircle
          : brand === "garnerfinancial"
          ? garnerfinancial
          : brand === "inspiredIdeaSolutions"
          ? inspiredIdeaSolutionsLogo
          : brand === "kiserArenaSpecialist"
          ? kiserLogo
          : brand === "autoSales"
          ? autoSalesLogo
          : brand === "floridaPaver"
          ? floridaPaverLogo
          : brand === "mapToClients"
          ? mapToClientsLogo
          : brand === "ppb"
          ? ppbLogo
          : brand === "justiceTax"
          ? justiceTaxLogo
          : brand === "fitnessGrind"
          ? fitnessGrindLogo
          : brand === "lightRx"
          ? lightRxLogo
          : brand === "kingWheel"
          ? kingWheelLogo
          : brand === "abc"
          ? abcLogo
          : brand === "transMedCare"
          ? transMedCareLogo
          : brand === "leCaprice"
          ? leCapriceLogo
          : brand === "budgetBlinds"
          ? budgetBlindsLogo
          : brand === "everywhere"
          ? everywhereLogo
          : brand === "trinityProximity"
          ? trinityProximityLogo
          : brand === "medevateHealth"
          ? medevateHealthLogo
          : brand === "connellyPhoto"
          ? connellyPhotoLogo
          : brand === "zg"
          ? zgLogo
          : brand === "diamondSolutions"
          ? diamondSolutionsLogo
          : brand === "clearVision"
          ? clearVisionLogo
          : brand === "elegantOccasions"
          ? elegantOccasionsLogo
          : brand === "theoutfit"
          ? tfoLogo
          : brand === "writeChoice"
          ? writeChoiceLogo
          : brand === "parkOutdoor"
          ? parkOutdoorLogo
          : brand === "incrediHome"
          ? incrediHomeLogo
          : brand === "bigOffice"
          ? bigOfficeLogo
          : brand === "squareOne"
          ? squareOneLogo
          : brand === "sfsbi"
          ? sfsbiLogo
          : brand === "nbc"
          ? nbcLogo
          : brand === "bettyStudios"
          ? bettyStudiosLogo
          : brand === "blockLegal"
          ? blockLegalLogo
          : brand === "customCoach"
          ? customCoachLogo
          : brand === "xFinity"
          ? xFinityLogo
          : brand === "absolute"
          ? absoluteLogo
          : brand === "rij"
          ? rijLogo
          : brand === "eCells"
          ? eCellsLogo
          : brand === "enable"
          ? enableLogo
          : brand === "rok"
          ? rokLogo
          : brand === "ayr"
          ? ayrLogo
          : brand === "springpools"
          ? springPoolsLogo
          : brand === "turfcenter"
          ? turfCenterLogo
          : brand === "mindfulhabitcoaching"
          ? mindfulHabitLogo
          : brand === "weldscholars"
          ? weldScholarsLogo
          : payPossibleLogo
      }
    />
  );
};

export default NavWrapper;
