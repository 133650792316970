import * as actions from "../actions";

const initValues = {
  order: {
    results: []
  },
  currentOrder: {},
  fetchState: "",
  approveState: {}
};

export default (order = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_ORDER:
      return { ...initValues, fetchState: "", approveState: {} };
    case actions.GET_ORDER_LOADING:
      return { ...order, fetchState: "Loading" };
    case actions.GET_ORDER_SUCCESS:
      return { ...order, order: action.payload, fetchState: "Order Success" };
    case actions.GET_ORDER_ERROR:
      return { ...order, order: action.payload, fetchState: "Order Error" };
    case actions.GET_ORDER_ID_LOADING:
      return { ...order, fetchState: "Loading" };
    case actions.GET_ORDER_ID_SUCCESS:
      return {
        ...order,
        currentOrder: action.payload,
        fetchState: "Order by Id Success"
      };
    case actions.GET_ORDER_ID_ERROR:
      return {
        ...order,
        currentOrder: action.payload,
        fetchState: "Order by Id Error"
      };
    case actions.APPROVE_ORDER_LOADING:
      return { ...order, fetchState: "Loading", approveState: {} };
    case actions.APPROVE_ORDER_SUCCESS:
      return {
        ...order,
        fetchState: "Order Approve Success",
        approveState: {}
      };
    case actions.APPROVE_ORDER_ERROR:
      return {
        ...order,
        fetchState: "Order Approve Error",
        approveState: action.payload
      };
    case actions.CLEAR_APPROVE_ORDER:
      return { ...order, approveState: {} };
    default:
      return order;
  }
};
