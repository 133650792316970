import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { RenderTextField, RenderSelectField } from "../../utlis/input";
import { required, AgeField } from "./utils";

const Page8 = ({ header, copy }) => {
  const classes = useStyles();

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  const [months, setMonths] = useState("");

  function handleChange(event) {
    setMonths(event.target.value);
  }

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line1}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line2}
      </Typography>

      <Field
        name="years"
        id="years"
        type="text"
        component={RenderTextField}
        label="Years"
        InputProps={{
          inputComponent: AgeField
        }}
        validate={required}
      />
      <Error name="years" {...classes} />
      <Field
        name="months"
        label="Months"
        component={RenderSelectField}
        values={months}
        onChange={handleChange}
        validate={required}
      >
        {[
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12"
        ].map((item, key) => (
          <MenuItem key={key} value={item}>
            {item}
          </MenuItem>
        ))}
      </Field>
      <Error name="months" {...classes} />
    </Wizard.Page>
  );
};

Page8.propTypes = {
  header: PropTypes.string.isRequired,
  copy: PropTypes.object.isRequired
};

export default Page8;
