import * as actions from "../actions";

const initValues = {
  merchant: {},
  fetchState: ""
};

export default (merchantInfo = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_MERCHANT:
      return { ...initValues };
    case actions.GET_MERCHANT_LOADING:
      return { ...merchantInfo, fetchState: "Loading" };
    case actions.GET_MERCHANT_SUCCESS:
      return { merchant: action.payload, fetchState: "Success" };
    case actions.GET_MERCHANT_ERROR:
      return { ...merchantInfo, fetchState: "Error" };
    default:
      return merchantInfo;
  }
};
