import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "./layout/Layout";
import { Offer1, Offer2 } from "./card";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Offers = () => {
  const lead = useSelector((state) => state.lead);

  const [page, setPage] = useState(1);
  const [offer, setOffer] = useState({});

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  return (
      <Layout>
        {page === 1 && (
            <Offer1
                setPage={setPage}
                copy={copy}
                offers={lead.lead.results[0].offers}
                setOffer={setOffer}
            />
        )}
        {page === 2 && <Offer2 copy={copy} {...offer} />}
      </Layout>
  );
};

export default Offers;
