import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from "react-number-format";
import moment from "moment";
import NoOfferCard from "./NoOfferCard";
import useStyles from "../styles";

const OrderCard = ({ order, copy, user, lead }) => {
  const classes = useStyles();

  // const [revOrder, setRevOrder] = useState([{}]);
  const [currentOrder, setCurrentOrder] = useState({});
  const [orderCheck, setOrderCheck] = useState(false);

  useEffect(() => {
    // iterate through orders, if all status are cancelled or paid, set orderCheck as true
    const tempArray = [];
    order.forEach((el) => {
      if (el.status !== "cancelled" && el.status !== "paid") tempArray.push(el);
    });
    if (tempArray.length > 0) setOrderCheck(false);
    else setOrderCheck(true);
  }, [order]);

  useEffect(() => {
    // --- Get 1st order with approve !== null and set as currentOrder
    let tempOrder = {};
    let tempCheck = false;
    order.forEach((orderEl) => {
      if (orderEl.actions.approve !== null) {
        tempOrder = orderEl;
        tempCheck = true;
      }
    });
    if (tempCheck) {
      setCurrentOrder(tempOrder);
    }
    // ---Otherwise set latest order in array at currentOrder
    if (!tempCheck) {
      const filteredOrders = order.filter((el) => el.status !== "cancelled");
      setCurrentOrder(filteredOrders[0]);
    }
  }, [order]);

  return (
    <>
      {/* ---Loading orders--- */}
      {Object.getOwnPropertyNames(currentOrder).length === 0 && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            margin: "10vh auto",
            minHeight: "100vh"
          }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}

      {/* ---Order loaded, orderCheck is false (there are pending orders)--- */}
      {!orderCheck && Object.getOwnPropertyNames(currentOrder).length !== 0 && (
        // eslint-disable-next-line react/jsx-indent
        <>
          <Typography
            variant="body1"
            color="primary"
            style={{ fontSize: "1.2rem", marginBottom: 10 }}
          >
            Hi {user.first_name},
          </Typography>
          {currentOrder.actions.approve !== null ? (
            <Typography
              variant="body2"
              color="primary"
              style={{ margin: "20px auto 50px" }}
            >
              {copy.line1b}
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="primary"
              style={{ margin: "20px auto 50px" }}
            >
              {copy.line1a}
            </Typography>
          )}

          <Card className={classes.card} raised>
            <CardContent>
              {/* ----- Order Section ----- */}
              <Typography
                variant="body1"
                color="primary"
                className={classes.cardHeader}
              >
                {copy.line2}
              </Typography>
              <hr style={{ opacity: "0.4" }} />

              <Typography variant="body1" className={classes.cardDisclaimer}>
                {currentOrder.merchant.name}
              </Typography>
              {currentOrder.items.length > 0 && (
                <Typography variant="body2" className={classes.cardDisclaimer}>
                  {currentOrder.items.length} {copy.line10}
                </Typography>
              )}

              {/* --- Order Status ---- */}
              {currentOrder.actions.approve === null &&
                currentOrder.status === "approved" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Check color="secondary" />
                    <Typography
                      variant="body1"
                      color="secondary"
                      style={{ marginLeft: 5 }}
                    >
                      {copy.line11}
                    </Typography>
                  </div>
                )}

              {currentOrder.actions.approve === null &&
                currentOrder.status === "cancelled" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body1" color="error">
                      {copy.line12}
                    </Typography>
                  </div>
                )}

              {currentOrder.actions.approve === null &&
                currentOrder.status === "refunded" && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body1" color="secondary">
                      {copy.line15}
                    </Typography>
                  </div>
                )}

              {currentOrder.actions.approve === null &&
                currentOrder.status !== "cancelled" &&
                currentOrder.status !== "approved" &&
                currentOrder.status !== "refunded" && (
                  <Typography align="center" variant="body1" color="secondary">
                    {copy.line13}
                  </Typography>
                )}

              {/* -------link button-------- */}
              {currentOrder.actions.approve !== null ? (
                <div style={{ textAlign: "center", margin: "20px auto 50px" }}>
                  <Link href={`orders/${currentOrder.id}`} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                    >
                      <span style={{ fontSize: "0.65rem" }}>{copy.line5}</span>
                    </Fab>
                  </Link>
                </div>
              ) : (
                <div style={{ textAlign: "center", margin: "20px auto 50px" }}>
                  <Link href={`orders/${currentOrder.id}`} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                    >
                      <span style={{ fontSize: "0.7rem" }}>{copy.line9}</span>
                    </Fab>
                  </Link>
                </div>
              )}

              {/* -----Loan Section----- */}
              <Typography
                variant="body1"
                color="primary"
                className={classes.cardHeader}
              >
                {copy.line3}
              </Typography>

              <hr style={{ opacity: "0.4" }} />

              <Typography variant="body1" className={classes.cardHeader}>
                {copy.line4}
              </Typography>
              <Typography
                variant="body1"
                color="secondary"
                style={{ fontSize: "1.8rem", marginBottom: 20 }}
              >
                <NumberFormat
                  value={currentOrder.loan.funded_amount}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                />
              </Typography>

              {currentOrder.loan.originator.image_url !== null ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "30px auto"
                  }}
                >
                  <img
                    alt="Lender Logo"
                    title="Lender Logo"
                    src={currentOrder.loan.originator.image_url}
                  />
                </div>
              ) : (
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.header}
                  style={{ marginBottom: 30 }}
                >
                  {currentOrder.loan.originator.name}
                </Typography>
              )}

              {currentOrder.loan.status === "funded" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Check color="secondary" />
                  {currentOrder.loan.date_funded ? (
                    <Typography
                      variant="body2"
                      color="secondary"
                      align="center"
                      style={{ marginLeft: 5 }}
                    >
                      {copy.line6b}
                      <br />
                      {moment(currentOrder.loan.date_funded).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="secondary"
                      align="center"
                      style={{ marginLeft: 5 }}
                    >
                      {copy.line6}
                    </Typography>
                  )}
                </div>
              ) : currentOrder.loan.status === "declined" ? (
                <Typography align="center" variant="body2" color="error">
                  {copy.line7}
                </Typography>
              ) : (
                <Typography align="center" variant="body2" color="secondary">
                  {copy.line8}
                </Typography>
              )}

              {/* -------link button-------- */}
              <div style={{ textAlign: "center", margin: "20px auto" }}>
                <Link href={`loans/${currentOrder.loan.id}`} underline="none">
                  <Fab variant="extended" color="secondary" aria-label="Submit">
                    <span style={{ fontSize: "0.7rem" }}>{copy.line9}</span>
                  </Fab>
                </Link>
              </div>
            </CardContent>
          </Card>
        </>
      )}

      {/* ---Order loaded, orderCheck is true (all orders are final), lead cannot reapply --- */}
      {orderCheck &&
        Object.getOwnPropertyNames(currentOrder).length !== 0 &&
        lead &&
        lead.actions.reapply === null && (
          // eslint-disable-next-line react/jsx-indent
          <>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontSize: "1.2rem", marginBottom: 10 }}
            >
              Hi {user.first_name},
            </Typography>
            {currentOrder.actions.approve !== null ? (
              <Typography variant="body2" color="primary">
                {copy.line1b}
              </Typography>
            ) : (
              <Typography variant="body1" color="primary">
                {copy.line1a}
              </Typography>
            )}

            <Card className={classes.card} raised>
              <CardContent>
                {/* ----- Order Section ----- */}
                <Typography variant="body1" className={classes.cardSubHeader}>
                  {copy.line2}
                </Typography>
                <hr style={{ opacity: "0.4" }} />

                <Typography variant="body1" className={classes.cardDisclaimer}>
                  {currentOrder.merchant.name}
                </Typography>
                {currentOrder.items.length > 0 && (
                  <Typography
                    variant="body2"
                    className={classes.cardDisclaimer}
                  >
                    {currentOrder.items.length} {copy.line10}
                  </Typography>
                )}

                {/* --- Order Status --- */}
                {currentOrder.actions.approve === null &&
                  currentOrder.status === "paid" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Check color="secondary" />
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{ marginLeft: 5 }}
                      >
                        {copy.line14}
                      </Typography>
                    </div>
                  )}

                {/* -------link button-------- */}
                {currentOrder.actions.approve !== null ? (
                  <div
                    style={{ textAlign: "center", margin: "20px auto 30px" }}
                  >
                    <Link href={`orders/${currentOrder.id}`} underline="none">
                      <Fab
                        variant="extended"
                        color="secondary"
                        aria-label="Submit"
                      >
                        <span style={{ fontSize: "0.65rem" }}>
                          {copy.line5}
                        </span>
                      </Fab>
                    </Link>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", margin: "20px auto" }}>
                    <Link href={`orders/${currentOrder.id}`} underline="none">
                      <Fab
                        variant="extended"
                        color="secondary"
                        aria-label="Submit"
                      >
                        <span style={{ fontSize: "0.7rem" }}>{copy.line9}</span>
                      </Fab>
                    </Link>
                  </div>
                )}

                {/* -----Loan Section----- */}
                <Typography variant="body1" className={classes.cardSubHeader}>
                  {copy.line3}
                </Typography>
                <hr style={{ opacity: "0.4" }} />

                <Typography variant="body1" className={classes.cardHeader}>
                  {copy.line4}
                </Typography>
                <Typography
                  variant="body1"
                  color="secondary"
                  style={{ fontSize: "1.8rem", marginBottom: 20 }}
                >
                  <NumberFormat
                    value={currentOrder.loan.funded_amount}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                  />
                </Typography>

                {currentOrder.loan.originator.image_url !== null ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "30px auto"
                    }}
                  >
                    <img
                      alt="Lender Logo"
                      title="Lender Logo"
                      src={currentOrder.loan.originator.image_url}
                    />
                  </div>
                ) : (
                  <Typography
                    variant="h3"
                    align="center"
                    className={classes.header}
                    style={{ marginBottom: 30 }}
                  >
                    {currentOrder.loan.originator.name}
                  </Typography>
                )}

                {currentOrder.loan.status === "funded" ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Check color="secondary" />
                    {currentOrder.loan.date_funded ? (
                      <Typography
                        variant="body2"
                        color="secondary"
                        align="center"
                        style={{ marginLeft: 5 }}
                      >
                        {copy.line6b}
                        <br />
                        {moment(currentOrder.loan.date_funded).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        color="secondary"
                        align="center"
                        style={{ marginLeft: 5 }}
                      >
                        {copy.line6}
                      </Typography>
                    )}
                  </div>
                ) : currentOrder.loan.status === "declined" ? (
                  <Typography align="center" variant="body2" color="error">
                    {copy.line7}
                  </Typography>
                ) : (
                  <Typography align="center" variant="body2" color="secondary">
                    {copy.line8}
                  </Typography>
                )}

                {/* -------link button-------- */}
                <div style={{ textAlign: "center", margin: "20px auto" }}>
                  <Link href={`loans/${currentOrder.loan.id}`} underline="none">
                    <Fab
                      variant="extended"
                      color="secondary"
                      aria-label="Submit"
                    >
                      <span style={{ fontSize: "0.7rem" }}>{copy.line9}</span>
                    </Fab>
                  </Link>
                </div>
              </CardContent>
            </Card>
          </>
        )}

      {/* ---Order loaded, orderCheck is true (all orders are final), lead can reapply --- */}
      {orderCheck &&
        Object.getOwnPropertyNames(currentOrder).length !== 0 &&
        lead &&
        lead.actions.reapply !== null && (
          <NoOfferCard personal={user} result={lead} />
        )}
    </>
  );
};

OrderCard.propTypes = {
  order: PropTypes.array.isRequired,
  copy: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired
};

export default OrderCard;
