import * as actions from "../actions";

const initValues = {
  loan: {
    results: []
  },
  currentLoan: {},
  fetchState: ""
};

export default (loan = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_LOAN:
      return { ...initValues, fetchState: "" };
    case actions.GET_LOAN_LOADING:
      return { ...loan, fetchState: "Loading" };
    case actions.GET_LOAN_SUCCESS:
      return { ...loan, loan: action.payload, fetchState: "Loan Success" };
    case actions.GET_LOAN_ERROR:
      return { ...loan, loan: action.payload, fetchState: "Loan Error" };
    case actions.GET_LOAN_ID_LOADING:
      return { ...loan, fetchState: "Loading" };
    case actions.GET_LOAN_ID_SUCCESS:
      return {
        ...loan,
        currentLoan: action.payload,
        fetchState: "Loan by Id Success"
      };
    case actions.GET_LOAN_ID_ERROR:
      return {
        ...loan,
        currentLoan: action.payload,
        fetchState: "Loan by Id Error"
      };
    default:
      return loan;
  }
};
