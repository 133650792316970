/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import smoothscroll from "smoothscroll-polyfill";
import Layout from "./layout/Layout";
import useStyles from "./styles";
import { LoanListCard } from "./card";
import { getLoan } from "../actions";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Loans = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loan = useSelector((state) => state.loan);
  const user = useSelector((state) => state.user);
  const [revLoan, setRevLoan] = useState([]);

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function fetchLoan() {
      dispatch(await getLoan());
    }
    fetchLoan();
  }, [dispatch]);

  useEffect(() => {
    if (loan.loan.results.length > 0) {
      setRevLoan([...loan.loan.results].reverse());
    }
  }, [loan.loan.results]);

  return (
    <Layout>
      {loan.fetchState === "Loan Error" && (
        <Redirect to={`${process.env.PUBLIC_URL}/account`} />
      )}
      {loan.fetchState === "Loan Success" && loan.loan.results.length > 0 ? (
        <div className={classes.wizard}>
          <Typography variant="h1" color="primary">
            {copy.account.loans.line1}
          </Typography>

          {revLoan.map((loan) => (
            <LoanListCard key={loan.id} copy={copy.account.loans} {...loan} />
          ))}

          <Link href={`${process.env.PUBLIC_URL}/account`} underline="none">
            <Fab
              color="primary"
              aria-label="Submit"
              style={{ marginBottom: 50 }}
            >
              <NavigateBeforeIcon />
            </Fab>
          </Link>
        </div>
      ) : loan.fetchState === "Loan Success" &&
        loan.loan.results.length <= 0 ? (
        <div className={classes.wizard}>
          <Container>
            <Typography
              variant="body1"
              color="primary"
              style={{ fontSize: "1.2rem", marginBottom: 10 }}
            >
              Hi {user.first_name},
            </Typography>
            <Typography variant="body1" color="primary">
              {copy.account.loans.line8}
            </Typography>
            <Link href={`${process.env.PUBLIC_URL}/account`} underline="none">
              <Fab
                color="primary"
                aria-label="Submit"
                style={{ marginTop: "50vh" }}
              >
                <NavigateBeforeIcon />
              </Fab>
            </Link>
          </Container>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            margin: "10vh auto",
            minHeight: "100vh"
          }}
        >
          <CircularProgress color="secondary" size="120px" thickness={1} />
        </div>
      )}
    </Layout>
  );
};

export default Loans;
