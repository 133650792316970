import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Field } from "react-final-form";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { RenderTextField, RenderSelectField } from "../../utlis/input";
import { required, composeValidators, CurrencyFormat } from "./utils";

const minValue = (min) => (value) =>
  value >= min ? undefined : `Amount should be greater than $${min}`;

const maxValue = (max) => (value) =>
  value <= max ? undefined : `Amount should be less than $${max}`;

const Page2 = ({ copy, pId, setProdUrl }) => {
  const classes = useStyles();
  const merchant = useSelector((state) => state.merchantInfo);
  const [purpose, setPurpose] = useState("");
  const [product, setProduct] = useState("");
  const [productId, setProductId] = useState({});

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    let tempProd = {};
    if (
      pId &&
      merchant.fetchState === "Success" &&
      merchant.merchant.hasOwnProperty("products") &&
      merchant.merchant.products.length > 0
    ) {
      merchant.merchant.products.forEach((product) => {
        if (product.code === pId) tempProd = product;
      });
    }
    setProductId(tempProd);
  }, [merchant.fetchState, merchant.merchant, pId]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(productId).length !== 0) {
      setProdUrl(productId.url);
    }
  });

  function handlePurposeChange(event) {
    setPurpose(event.target.value);
  }

  function handleProductChange(event) {
    setProduct(event.target.value);
  }

  return (
    <Wizard.Page>
      {merchant.fetchState === "Error" && (
        <Redirect to={`${process.env.PUBLIC_URL}`} />
      )}

      {merchant.fetchState !== "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 && (
          <>
            <Typography color="primary" className={classes.largeHeader}>
              {copy.line0}
            </Typography>
            <Typography
              className={classes.subHeader}
              style={{ fontSize: "1.5rem" }}
            >
              {copy.line1b}
            </Typography>
          </>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 && (
          <>
            {/* <Typography color="primary" style={{ fontSize: "1.5rem" }}>
              {copy.line0}
            </Typography>
            <Typography
              className={classes.subHeader}
              style={{ fontSize: "1.5rem" }}
            >
              {copy.line1}
            </Typography> */}
            <Typography
              variant="h3"
              className={classes.header}
              align="center"
              style={{ marginBottom: "5vh" }}
            >
              {copy.line0b} {merchant.merchant.name}
            </Typography>
            <Typography color="primary" style={{ fontSize: "1.5rem" }}>
              {copy.line0}
            </Typography>
            <Typography
              className={classes.subHeader}
              style={{ fontSize: "1.5rem" }}
            >
              {copy.line1b}
            </Typography>
          </>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 &&
        (merchant.merchant.code === "test" ||
          merchant.merchant.code === "nbc") && (
          <Typography
            variant="h1"
            align="center"
            className={classes.header}
            style={{ marginBottom: 50 }}
          >
            {merchant.merchant.name}
            <br />
            {copy.line1alt}
          </Typography>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length !== 0 && (
          <>
            {merchant.merchant.name && (
              <Typography
                variant="h3"
                className={classes.header}
                align="center"
                style={{ marginBottom: "5vh" }}
              >
                {copy.line0b} {merchant.merchant.name}
              </Typography>
            )}
            <Typography
              variant="h1"
              align="center"
              className={classes.subHeader}
            >
              {copy.line8}
            </Typography>
            <Typography variant="body2" color="primary">
              {copy.line7}
            </Typography>
            <hr style={{ opacity: "0.4" }} />
            <Typography
              variant="body1"
              className={classes.header}
              style={{ marginBottom: 30 }}
            >
              {productId.description}
            </Typography>
            <Typography variant="body2" color="primary">
              {copy.line7b}
            </Typography>
            <hr style={{ opacity: "0.4" }} />
            <Typography
              variant="h2"
              className={classes.header}
              style={{ marginBottom: 30 }}
            >
              <NumberFormat
                value={productId.price}
                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </Typography>
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.disclaimer}
            >
              {copy.line6}
              <br />
              {copy.line6b}
            </Typography>
          </>
        )}

      {merchant.fetchState !== "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 && (
          <>
            <Field
              name="purpose"
              label="Good/Service"
              component={RenderSelectField}
              values={purpose}
              onChange={handlePurposeChange}
              validate={required}
            >
              {copy.loanPurpose.map((loan, key) => (
                <MenuItem key={key} value={loan.value}>
                  {loan.option}
                </MenuItem>
              ))}
            </Field>
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line3}
            </Typography>
            <Error name="purpose" {...classes} />
            <Field
              name="amount"
              id="amount"
              type="tel"
              component={RenderTextField}
              label={
                merchant.merchant.code === "test" ||
                merchant.merchant.code === "nbc"
                  ? "Loan amount"
                  : "How much will it cost?"
              }
              validate={composeValidators(
                required,
                minValue(500),
                maxValue(100000)
              )}
              InputProps={{
                inputComponent: CurrencyFormat
              }}
            />
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line5}
            </Typography>
            <Error name="amount" {...classes} />
          </>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 &&
        merchant.merchant.hasOwnProperty("products") &&
        merchant.merchant.products.length > 0 && (
          <div style={{ marginBottom: "6vh" }}>
            <Field
              name="product"
              label="Select Product"
              component={RenderSelectField}
              values={product}
              onChange={handleProductChange}
              validate={required}
            >
              {merchant.merchant.products.map((product) => (
                <MenuItem key={product.id} value={product.url}>
                  {product.description} -{" "}
                  <span className={classes.select}>${product.price}</span>
                </MenuItem>
              ))}
            </Field>
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line3b}
            </Typography>
            <Error name="product" {...classes} />
          </div>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 &&
        merchant.merchant.hasOwnProperty("products") &&
        merchant.merchant.products.length <= 0 &&
        merchant.merchant.selectable_loan_type && (
          <>
            <Field
              name="purpose"
              label="Good/Service"
              component={RenderSelectField}
              values={purpose}
              onChange={handlePurposeChange}
              validate={required}
            >
              {copy.loanPurpose.map((loan, key) => (
                <MenuItem key={key} value={loan.value}>
                  {loan.option}
                </MenuItem>
              ))}
            </Field>
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line3}
            </Typography>
            <Error name="purpose" {...classes} />
            <Field
              name="amount"
              id="amount"
              type="tel"
              component={RenderTextField}
              label={
                merchant.merchant.code === "test" ||
                merchant.merchant.code === "nbc"
                  ? "Loan amount"
                  : "How much will it cost?"
              }
              validate={composeValidators(
                required,
                minValue(500),
                maxValue(100000)
              )}
              InputProps={{
                inputComponent: CurrencyFormat
              }}
            />
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line5}
            </Typography>
            <Error name="amount" {...classes} />
          </>
        )}

      {merchant.fetchState === "Success" &&
        Object.getOwnPropertyNames(productId).length === 0 &&
        merchant.merchant.hasOwnProperty("products") &&
        merchant.merchant.products.length <= 0 &&
        !merchant.merchant.selectable_loan_type && (
          <div style={{ marginBottom: "6vh" }}>
            <Field
              name="amount"
              id="amount"
              type="tel"
              component={RenderTextField}
              label={
                merchant.merchant.code === "test" ||
                merchant.merchant.code === "nbc"
                  ? "Loan amount"
                  : "How much will it cost?"
              }
              validate={composeValidators(
                required,
                minValue(500),
                maxValue(100000)
              )}
              InputProps={{
                inputComponent: CurrencyFormat
              }}
            />
            <Typography
              variant="body2"
              align="center"
              display="block"
              className={classes.fieldDisclaimer}
            >
              {copy.line5}
            </Typography>
            <Error name="amount" {...classes} />
          </div>
        )}
    </Wizard.Page>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired
};

Page2.defaultProps = {
  loan_type: "",
  selectable_loan_type: true,
  pId: ""
};

Page2.propTypes = {
  loan_type: PropTypes.string,
  selectable_loan_type: PropTypes.bool,
  copy: PropTypes.object.isRequired,
  pId: PropTypes.string,
  setProdUrl: PropTypes.func.isRequired
};

export default Page2;
