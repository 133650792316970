import * as actions from "../actions";

const initValues = {
  lead: {
    results: []
  },
  fetchState: ""
};

export default (lead = initValues, action) => {
  switch (action.type) {
    case actions.CLEAR_LEAD:
      return { ...initValues, fetchState: "" };
    case actions.SET_LEAD_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.SET_LEAD_SUCCESS:
      return { lead: action.payload, fetchState: "Success" };
    case actions.SET_LEAD_ERROR:
      return { lead: action.payload, fetchState: "Error" };
    case actions.GET_LEAD_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.GET_LEAD_SUCCESS:
      return { lead: action.payload, fetchState: "Lead Success" };
    case actions.GET_LEAD_ERROR:
      return { lead: action.payload, fetchState: "Error" };
    // case actions.PING_LOADING:
    //   return { ...lead, fetchState: "Loading" };
    // case actions.PING_SUCCESS:
    //   return { lead: action.payload, fetchState: "Lead Success" };
    // case actions.PING_ERROR:
    //   return { lead: action.payload, fetchState: "Error" };
    case actions.GET_LEAD_ID_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.GET_LEAD_ID_SUCCESS:
      lead = {
        lead: {
          results: []
        },
        fetchState: ""
      };
      lead.lead.results.push(action.payload);
      return { ...lead, fetchState: "Lead by Id Success" };
    case actions.GET_LEAD_ID_ERROR:
      return { lead: action.payload, fetchState: "Error" };
    case actions.REAPPLY_LEAD_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.REAPPLY_LEAD_SUCCESS:
      lead = {
        lead: {
          results: []
        },
        fetchState: ""
      };
      lead.lead.results.push(action.payload);
      return { ...lead, fetchState: "Reapply Success" };
    case actions.PUT_LEAD_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.PUT_LEAD_SUCCESS:
      return { lead: action.payload, fetchState: "Put Lead Success" };
    case actions.PUT_LEAD_ERROR:
      return { lead: action.payload, fetchState: "Error" };
    case actions.PATCH_LEAD_LOADING:
      return { ...lead, fetchState: "Loading" };
    case actions.PATCH_LEAD_SUCCESS:
      return { lead: action.payload, fetchState: "Patch Lead Success" };
    case actions.PATCH_LEAD_ERROR:
      return { lead: action.payload, fetchState: "Error" };
    default:
      return lead;
  }
};
