import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Fab from "@material-ui/core/Fab";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import smoothscroll from "smoothscroll-polyfill";
import OfferDetailCard from "./OfferDetailCard";
import useStyles from "../styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Offer2 = ({ copy, ...offer }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);

  const [dialog, setDialog] = useState(false);

  function handleDialogOpen() {
    setDialog(true);
  }

  function handleDialogClose() {
    setDialog(false);
  }

  function handleInternalExit() {
    setDialog(false);
    const jwt = sessionStorage.getItem("jwt");
    const url = (jwt) ? `${offer.apply_url}?token=${jwt}` : offer.apply_url;
    window.location.replace(url);
  }

  function handleExternalExit() {
    setDialog(false);
    window.location.replace(offer.apply_url);
  }

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (auth.fetchState === "Session Success")
      window.location.replace(offer.apply_url);
  });

  const { name } = offer.originator;
  return (
    <>
      <div className={classes.wizard}>
        <Card raised className={classes.card}>
          <Typography
            variant="h1"
            color="primary"
            className={classes.largeHeader}
          >
            {copy.account.offers2.line1}
          </Typography>

          <div style={{ margin: "20px auto 50px" }}>
            <div style={{ display: "flex" }}>
              <Typography
                variant="body2"
                color="primary"
                className={classes.offerCardText}
              >
                {name === "Finwise Bank"
                  ? copy.account.offers2.line3
                  : copy.account.offers2.line2}
              </Typography>
            </div>
          </div>

          <OfferDetailCard
            {...offer}
            copy={copy.account.loancard}
            offer2
            setOpen={handleDialogOpen}
          />
          <Link href={`${process.env.PUBLIC_URL}/offers`} underline="none">
            <Fab
              color="primary"
              aria-label="Submit"
              style={{ marginBottom: 50 }}
            >
              <NavigateBeforeIcon />
            </Fab>
          </Link>
        </Card>
      </div>

      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px 20px 0" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            color="primary"
          >
            {name === "Finwise Bank"
              ? copy.modals.exit.custom.line1.line1
              : copy.modals.exit.default.line1}
            <br />
            {name === "Finwise Bank" && (
              <>
                <ul>
                  {copy.modals.exit.custom.line1.items.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
                {copy.modals.exit.custom.line1.line2}
                <br />
              </>
            )}
            <br />
            {copy.modals.exit.default.line2}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingBottom: 40 }}>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Fab
              variant="extended"
              aria-label="Submit"
              type="submit"
              className={classes.modalButton}
              onClick={
                name === "Finwise Bank"
                  ? handleInternalExit
                  : handleExternalExit
              }
            >
              Ok
            </Fab>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

Offer2.propTypes = {
  copy: PropTypes.object.isRequired
};

export default Offer2;
