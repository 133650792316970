import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import LogRocket from "logrocket";
import reduxThunk from "redux-thunk";
import rootReducer from "./reducers";

export const configureStore = initialState => {
  const persistConfig = {
    key: "root",
    storage: storageSession,
    blacklist: ["merchantInfo"]
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(reduxThunk, LogRocket.reduxMiddleware()))
  );

  store.__persistor = persistStore(store);
  // store.__persistor.purge();
  return store;
};

export default configureStore;
