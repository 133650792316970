import { Merchant } from "../api/Agent";

export const CLEAR_MERCHANT = "CLEAR_MERCHANT";

export const GET_MERCHANT_LOADING = "GET_MERCHANT_LOADING";
export const GET_MERCHANT_SUCCESS = "GET_MERCHANT_SUCCESS";
export const GET_MERCHANT_ERROR = "GET_MERCHANT_ERROR";

export const clearMerchant = () => ({
  type: CLEAR_MERCHANT
});

export const getMerchantInfo = payId => async dispatch => {
  dispatch({
    type: GET_MERCHANT_LOADING
  });
  try {
    const response = await Merchant.search(payId);
    dispatch({
      type: GET_MERCHANT_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_MERCHANT_ERROR
    });
  }
};
