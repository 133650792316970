import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

class AnalyticsWrapper extends Component {
  componentDidMount() {
    const { page } = this.props;
    ReactGA.initialize("UA-131525007-1");
    ReactGA.pageview(`/${page}`);
  }

  render() {
    const { children } = this.props;
    return <section>{children}</section>;
  }
}

AnalyticsWrapper.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default AnalyticsWrapper;
