import { Auth, Token } from "../api/Agent";

export const CLEAR_AUTH = "CLEAR_AUTH";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const VERIFY_LOADING = "VERIFY_LOADING";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_ERROR = "VERIFY_ERROR";
export const REFRESH_LOADING = "REFRESH_LOADING";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_ERROR = "REFRESH_ERROR";
export const SESSION_LOADING = "SESSION_LOADING";
export const SESSION_SUCCESS = "SESSION_SUCCESS";
export const SESSION_ERROR = "SESSION_ERROR";

export const clearAuth = () => ({
  type: CLEAR_AUTH
});

export const register = (personal) => async (dispatch) => {
  dispatch({
    type: REGISTER_LOADING
  });
  try {
    let result = await Auth.register(
      personal.first_name,
      personal.last_name,
      personal.email,
      personal.password
    );
    result = await Auth.login(personal.email, personal.password);
    sessionStorage.setItem("jwt", result.token);
    await Token.set(sessionStorage.getItem("jwt"));
    dispatch({
      type: REGISTER_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: REGISTER_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING
  });
  try {
    const result = await Auth.login(email, password);
    sessionStorage.setItem("jwt", result.token);
    await Token.set(sessionStorage.getItem("jwt"));
    dispatch({
      type: LOGIN_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const logout = () => (dispatch) => {
  Token.unSet();
  return sessionStorage.removeItem("jwt");
};

export const verifyToken = () => async (dispatch) => {
  dispatch({
    type: VERIFY_LOADING
  });
  try {
    const token = sessionStorage.getItem("jwt");
    await Token.set(token);
    const result = await Auth.verify(token);
    dispatch({
      type: VERIFY_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: VERIFY_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const refreshToken = () => async (dispatch) => {
  dispatch({
    type: REFRESH_LOADING
  });
  try {
    const token = sessionStorage.getItem("jwt");
    await Token.set(token);
    const result = await Auth.refresh(token);
    sessionStorage.setItem("jwt", result.token);
    dispatch({
      type: REFRESH_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: REFRESH_ERROR,
      payload: "token refresh error"
    });
  }
};

export const sessionToken = () => async (dispatch) => {
  dispatch({
    type: SESSION_LOADING
  });
  try {
    const token = sessionStorage.getItem("jwt");
    await Token.set(token);
    const result = await Auth.session(token);
    dispatch({
      type: SESSION_SUCCESS,
      payload: result
    });
  } catch (error) {
    dispatch({
      type: SESSION_ERROR,
      payload: "token session error"
    });
  }
};
