import React from "react"
import Layout from "../components/layout/Layout";
import Typography from "@material-ui/core/Typography";
import useStyles from "../components/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";


const Error = () => {
    const classes = useStyles();

    return (
        <Layout>
            <div className={classes.wizard}>
            <Card raised className={classes.card}>
                <Grid container alignItems={"center"} justify={"center"}>
                    <Typography variant={"h1"} color={"primary"} className={classes.largeHeader}>
                        Uh Oh! - Something went wrong.
                    </Typography>
                    <br />
                    <Typography align={"center"} variant={"h3"} color={"primary"} className={classes.subheader}>
                        Our technical team has been notified and is looking into the error.
                        <br /> <br /> <br /><br />
                        Please contact PayPossible Support
                    </Typography>
                    <br />
                    <Typography align={"center"}>
                        <br /><br />
                        <strong>(855) 997-2931<br />
                            support@paypossible.com</strong>
                    </Typography>
                </Grid>
            </Card>
            </div>
        </Layout>
    )

}

export default Error;