import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Sentry from "@sentry/browser";
import Link from "@material-ui/core/Link";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import Fab from "@material-ui/core/Fab";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import LogRocket from "logrocket";
import Layout from "./layout/Layout";
import history from "../myHistory";
import { sentryUrl } from "../utlis/sentry";
import {
  clearAuth,
  clearLead,
  clearMerchant,
  clearUser,
  clearOrder,
  logout
} from "../actions";
import payPossibleLogo from "../images/payPossibleFooterLogo.png";
import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const styles = {
  logo: {
    margin: "10vh auto",
    maxWidth: 220
  },
  button: {
    margin: "50px auto",
    fontSize: "0.8rem"
  }
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: sentryUrl
  });
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(event => {
        event.setExtra("sessionURL", sessionURL);
        return event;
      });
    });
  }

  componentDidCatch(error, errorInfo) {
    const {
      clearAuth,
      clearLead,
      clearMerchant,
      clearUser,
      clearOrder,
      logout
    } = this.props;
    clearAuth();
    clearLead();
    clearMerchant();
    clearUser();
    clearOrder();
    logout();
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Layout>
          <Container style={{ textAlign: "center", minHeight: "100vh" }}>
            <img
              alt="PayPossible Logo"
              title="PayPossible Logo"
              src={payPossibleLogo}
              style={styles.logo}
            />
            <Typography
              variant="body1"
              color="primary"
              style={{ marginBottom: 15 }}
            >
              {copy.errorBoundary.line1}
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              style={{ marginBottom: 30 }}
            >
              {copy.errorBoundary.line3}{" "}
              <Link
                color="secondary"
                href="https://www.paypossible.com/contact"
                style={{ textDecoration: "none" }}
              >
                {copy.errorBoundary.line4}
              </Link>{" "}
              {copy.errorBoundary.line5}
            </Typography>

            <Fab
              variant="extended"
              aria-label="Submit"
              onClick={() => {
                this.setState({ error: null });
                history.push(`${process.env.PUBLIC_URL}`);
              }}
              color="secondary"
              style={styles.button}
            >
              <ArrowLeft />
              {copy.errorBoundary.button}
            </Fab>
          </Container>
        </Layout>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  clearAuth: PropTypes.func.isRequired,
  clearLead: PropTypes.func.isRequired,
  clearMerchant: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

// -----Redux Functions-----
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearAuth,
      clearLead,
      clearMerchant,
      clearUser,
      clearOrder,
      logout
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ErrorBoundary);
