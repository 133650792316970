import React, { useEffect, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Fab from "@material-ui/core/Fab";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import history from "../../myHistory";
import useStyles from "../styles";
import { clearAuth, logout, reDirect } from "../../actions";

import copyEnable from "../../utlis/copyEnable";
import copyEn from "../../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Navbar = ({ logo }) => {
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [stateLogout, setStateLogout] = useState(false);

  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleNavbarClose() {
    setAnchorEl(null);
  }

  function handleDialogClose() {
    setDialog(false);
  }

  function handleSignOut() {
    setDialog(false);
    setStateLogout(true);
  }

  function SignOut() {
    setAnchorEl(null);
    setDialog(true);
  }

  useEffect(() => {
    if (stateLogout) {
      dispatch(clearAuth());
      logout();
      dispatch(reDirect(true));
      history.push(`${process.env.PUBLIC_URL}`);
    }
  }, [dispatch, stateLogout]);

  return (
    <>
      <div className={classes.grow}>
        <AppBar
          position="static"
          color="inherit"
          className={classes.appPadding}
        >
          <Toolbar>
            <Link href="/" underline="none">
              <img
                alt="Logo"
                title="Logo"
                src={logo}
                className={classes.logoHeader}
              />
            </Link>
            <div className={classes.grow} />
            {auth.isAuth ? (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="secondary"
                >
                  <AccountCircle style={{ fontSize: "2rem" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  open={open}
                  onClose={handleNavbarClose}
                >
                  <MenuItem className={classes.userItem} disabled>
                    {user.first_name} {user.last_name}
                  </MenuItem>
                  <Link
                    href={`${process.env.PUBLIC_URL}/account`}
                    underline="none"
                  >
                    <MenuItem onClick={handleNavbarClose}>
                      {copy.nav.link1}
                    </MenuItem>
                  </Link>
                  <Link
                    href={`${process.env.PUBLIC_URL}/settings`}
                    underline="none"
                  >
                    <MenuItem onClick={handleNavbarClose}>
                      {copy.nav.link2}
                    </MenuItem>
                  </Link>

                  <Link
                    href={`${process.env.PUBLIC_URL}/loans`}
                    underline="none"
                  >
                    <MenuItem onClick={handleNavbarClose}>
                      {copy.nav.link3}
                    </MenuItem>
                  </Link>
                  <Link
                    href={`${process.env.PUBLIC_URL}/orders`}
                    underline="none"
                  >
                    <MenuItem onClick={handleNavbarClose}>
                      {copy.nav.link4}
                    </MenuItem>
                  </Link>
                  <Link href={null} underline="none">
                    <MenuItem onClick={SignOut}>{copy.nav.link5}</MenuItem>
                  </Link>
                </Menu>
              </div>
            ) : (
              <Link
                href={`${process.env.PUBLIC_URL}/sign-in`}
                underline="none"
                className={classes.navLink}
              >
                Sign In
              </Link>
            )}
          </Toolbar>
        </AppBar>
      </div>

      <Dialog
        open={dialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent style={{ padding: "30px 30px 0" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.header}
            style={{ fontWeight: 600 }}
          >
            {copy.modals.signOut.line1}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around", padding: 30 }}>
          <Fab
            variant="extended"
            aria-label="Submit"
            type="submit"
            className={classes.modalButton}
            onClick={handleSignOut}
          >
            {copy.modals.signOut.button1}
          </Fab>
          <Fab
            variant="extended"
            aria-label="Submit"
            color="secondary"
            type="submit"
            onClick={handleDialogClose}
          >
            {copy.modals.signOut.button2}
          </Fab>
        </DialogActions>
      </Dialog>
    </>
  );
};

Navbar.propTypes = {
  logo: PropTypes.string.isRequired
};

export default Navbar;
