/* eslint-disable consistent-return */
// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import history from "../myHistory";
import Wizard from "./Wizard";
import Layout from "./layout/Layout";
import {
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
  Page10,
  Page11,
  Page12,
  Page13,
  Page14,
  Page15,
  Page16
} from "./form";
import useStyles from "./styles";
import {
  clearMerchant,
  getMerchantInfo,
  setLead,
  login,
  getUser,
  clearPing,
  clearLead,
  clearLoan,
  clearUser,
  clearOrder,
  reapplyLead,
  patchLead
} from "../actions";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
import MarketingDisclosure from "./compliance/MarketingDisclosure";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn;


const App = () => {
  const classes = useStyles();

  const merchant = useSelector((state) => state.merchantInfo);
  const auth = useSelector((state) => state.auth);
  const lead = useSelector((state) => state.lead);
  const [leadInfo, setLeadInfo] = useState({});
  const user = useSelector((state) => state.user);
  const redirect = useSelector((state) => state.redirect);
  const dispatch = useDispatch();
  const [wizard, setWizard] = useState(1);
  const [error, setError] = useState("");
  const [displayBack, setDisplayBack] = useState(true);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordApi, setPasswordApi] = useState("");
  const [agree, setAgree] = useState(false);
  const [next, setNext] = useState(false);
  const [productId, setProductId] = useState(null);
  const [prodUrl, setProdUrl] = useState(null);

  useEffect(() => {
    async function fetchMLink(id) {
      dispatch(await getMerchantInfo(id.toLowerCase()));
    }

    if (window.location.pathname.split("/").indexOf("m") > -1) {
      const mId = window.location.pathname.split("/")[
      window.location.pathname.split("/").indexOf("m") + 1
          ];
      const pId = window.location.pathname.split("/")[
      window.location.pathname.split("/").indexOf("m") + 2
          ];
      fetchMLink(mId).then((res) => {
        setNext(true);
      });
      if (pId) setProductId(pId);
    }

    if (next) {
      setWizard(2);
    }
  }, [dispatch, next, wizard]);

  useEffect(() => {
    if (!auth.isAuth) {
      dispatch(clearPing());
      dispatch(clearLead());
      dispatch(clearLoan());
      dispatch(clearMerchant());
      dispatch(clearUser());
      dispatch(clearOrder());
      sessionStorage.removeItem("jwt");
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (merchant.fetchState === "Success") {
      setError("");
      setWizard(2);
    }
    if (merchant.fetchState === "Error") setError(copy.application.page1.error);

    if (merchant.fetchState === "") setError("");

    if (auth.isAuth && !redirect && lead.lead.hasOwnProperty("results")) {
      setLeadInfo({
        amount: lead.lead.results[0].amount,
        purpose: lead.lead.results[0].purpose,
        first_name: lead.lead.results[0].personal.first_name,
        last_name: lead.lead.results[0].personal.last_name,
        dob: moment(lead.lead.results[0].personal.dob, "YYYY-MM-DD").format(
            "MM-DD-YYYY"
        ),
        phone: lead.lead.results[0].personal.phone,
        email: lead.lead.results[0].personal.email,
        street1: lead.lead.results[0].address.street1,
        street2: lead.lead.results[0].address.street2,
        city: lead.lead.results[0].address.city,
        state: lead.lead.results[0].address.state,
        zip: lead.lead.results[0].address.zip,
        years: Math.floor(lead.lead.results[0].address.address_length / 12),
        months: lead.lead.results[0].address.address_length % 12,
        annual_income: lead.lead.results[0].income.annual_income
      });
    }

    if (auth.isAuth && redirect) {
      history.push(`${process.env.PUBLIC_URL}/account`);
    }

    if (!redirect && lead.lead.fetchState === "Reapply Success") {
      dispatch(patchLead(lead.lead.results[0].id, lead.lead.results[0]));
    }

    // if (lead.fetchState === "Error") throw new Error(JSON.stringify(lead.lead));
  }, [
    auth.isAuth,
    dispatch,
    lead,
    lead.lead,
    lead.lead.results,
    merchant.fetchState,
    redirect
  ]);

  const getMerchant = async (values) => {
    if (
        Object.getOwnPropertyNames(values).length === 0 ||
        values.payId === null
    ) {
      setWizard(2);
    } else dispatch(await getMerchantInfo(values.payId.toLowerCase()));
  };

  const onSubmit = (values) => {
    let loanObj = {};
    if (
        Object.getOwnPropertyNames(merchant.merchant).length !== 0 &&
        prodUrl !== null &&
        redirect
    ) {
      loanObj = {
        merchant: {
          url: merchant.merchant.url
        },
        purpose: values.purpose,
        credit_score: values.credit_score,
        agree: true,
        personal: {
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: passwordApi,
          dob: moment(values.dob, "MM-DD-YYYY").format("YYYY-MM-DD"),
          ssn: values.ssn
        },
        address: {
          street1: values.street1,
          street2: values.street2 || null,
          city: values.city,
          state: values.state,
          zip: values.zip,
          status: values.status,
          address_length: values.years * 12 + Number(values.months)
        },
        employment: values.employment,
        income: {
          annual_income: Math.round(values.annual_income),
          payroll_frequency: values.payroll_frequency,
          payroll_type: values.payroll_type
        },
        product: {
          url: prodUrl
        }
      };
    } else if (
        Object.getOwnPropertyNames(merchant.merchant).length !== 0 &&
        prodUrl === null &&
        values.product &&
        redirect
    ) {
      loanObj = {
        merchant: {
          url: merchant.merchant.url
        },
        purpose: values.purpose,
        credit_score: values.credit_score,
        agree: true,
        personal: {
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: passwordApi,
          dob: moment(values.dob, "MM-DD-YYYY").format("YYYY-MM-DD"),
          ssn: values.ssn
        },
        address: {
          street1: values.street1,
          street2: values.street2 || null,
          city: values.city,
          state: values.state,
          zip: values.zip,
          status: values.status,
          address_length: values.years * 12 + Number(values.months)
        },
        employment: values.employment,
        income: {
          annual_income: Math.round(values.annual_income),
          payroll_frequency: values.payroll_frequency,
          payroll_type: values.payroll_type
        },
        product: {
          url: values.product
        }
      };
    } else if (
        Object.getOwnPropertyNames(merchant.merchant).length !== 0 &&
        prodUrl === null &&
        !values.product &&
        redirect
    ) {
      loanObj = {
        merchant: {
          url: merchant.merchant.url
        },
        amount: Math.round(values.amount),
        purpose: merchant.merchant.loan_type,
        credit_score: values.credit_score,
        agree: true,
        personal: {
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: passwordApi,
          dob: moment(values.dob, "MM-DD-YYYY").format("YYYY-MM-DD"),
          ssn: values.ssn
        },
        address: {
          street1: values.street1,
          street2: values.street2 || null,
          city: values.city,
          state: values.state,
          zip: values.zip,
          status: values.status,
          address_length: values.years * 12 + Number(values.months)
        },
        employment: values.employment,
        income: {
          annual_income: Math.round(values.annual_income),
          payroll_frequency: values.payroll_frequency,
          payroll_type: values.payroll_type
        }
      };
    } else if (redirect) {
      loanObj = {
        merchant: {
          url: `${process.env.REACT_APP_API_ENDPOINT}/v1/merchants/get-paid/`
        },
        amount: Math.round(values.amount),
        purpose: values.purpose,
        credit_score: values.credit_score,
        agree: true,
        personal: {
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: passwordApi,
          dob: moment(values.dob, "MM-DD-YYYY").format("YYYY-MM-DD"),
          ssn: values.ssn
        },
        address: {
          street1: values.street1,
          street2: values.street2 || null,
          city: values.city,
          state: values.state,
          zip: values.zip,
          status: values.status,
          address_length: values.years * 12 + Number(values.months)
        },
        employment: values.employment,
        income: {
          annual_income: Math.round(values.annual_income),
          payroll_frequency: values.payroll_frequency,
          payroll_type: values.payroll_type
        }
      };
    } else if (!redirect) {
      dispatch(reapplyLead(lead.lead.results[0].id));
    }

    if (redirect) {
      dispatch(setLead(loanObj)).then((res) => {
        dispatch(login(loanObj.personal.email, passwordApi)).then((res) => {
          dispatch(getUser());
        });
      });
    }
  };

  function setBack() {
    if (wizard === 2) dispatch(clearMerchant());
    setWizard(wizard - 1);
  }

  return (
      <Layout>
        <div className={classes.wizard} style={{ minHeight: "100vh" }}>
          {wizard === 1 && (
              <>
                <Card raised className={classes.card}>
                  <Wizard
                      onSubmit={getMerchant}
                      initWizard
                      setDisplayBack={setDisplayBack}
                      copy={copy}
                      initialValues={
                        auth.isAuth &&
                        lead &&
                        lead.lead.hasOwnProperty("results") &&
                        lead.lead.results[0].merchant && {
                          payId: lead.lead.results[0].merchant.code
                        }
                      }
                  >
                    <Page1
                        error={error}
                        copy={copy.application.page1}
                        merchantFetchState={merchant.fetchState}
                    />
                  </Wizard>
                </Card>
              </>
          )}
          {wizard === 2 && (
              <>
                <Card raised className={classes.card}>
                  <Wizard
                      onSubmit={onSubmit}
                      setDisplayBack={setDisplayBack}
                      initialValues={leadInfo}
                      setName={setName}
                      setUserName={setUserName}
                      passwordValid={passwordValid}
                      copy={copy}
                      agree={agree}
                      authFetchState={auth.fetchState}
                      leadFetchState={lead.fetchState}
                      userFetchState={user.fetchState}
                      merchantFetchState={merchant.fetchState}
                  >
                    <Page2
                        copy={copy.application.page2}
                        pId={productId}
                        setProdUrl={setProdUrl}
                    />
                    <Page3 copy={copy.application.page3} />
                    <Page4
                        header={copy.application.initHeader}
                        setPasswordValid={setPasswordValid}
                        copy={copy.application.page4}
                    />
                    <Page5
                        header={copy.application.initHeader}
                        auth={auth}
                        name={name}
                        copy={copy.application.page5}
                        setPasswordValid={setPasswordValid}
                    />
                    <Page6
                        name={name}
                        header={copy.application.initHeader}
                        setPasswordValid={setPasswordValid}
                        setPasswordApi={setPasswordApi}
                        copy={copy.application.page6}
                        userName={userName}
                    />
                    <Page7
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page7}
                    />
                    <Page8
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page8}
                    />
                    <Page9
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page9}
                    />
                    <Page10
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page10}
                    />
                    <Page11
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page11}
                    />
                    <Page12
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page12}
                    />
                    <Page13
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page13}
                    />
                    <Page14
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page14}
                    />
                    <Page15
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page15}
                    />
                    <Page16
                        header={copy.application.creditProfileHeader}
                        copy={copy.application.page16}
                        setAgree={setAgree}
                        authFetchState={auth.fetchState}
                        leadFetchState={lead.fetchState}
                        userFetchState={user.fetchState}
                    />
                  </Wizard>
                  <Grid container style={{marginTop: -50}}>
                    <Grid item xs={2}>
                      {displayBack &&
                      (window.location.pathname.split("/").pop() === "" ||
                          window.location.pathname.split("/").pop() === "app") && (
                          <Fab
                              color="primary"
                              aria-label="Submit"
                              onClick={setBack}
                              style={{ marginTop: 50 }}
                          >
                            <NavigateBeforeIcon />
                          </Fab>
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <MarketingDisclosure />
                    </Grid>
                  </Grid>
                </Card>
              </>
          )}
        </div>
      </Layout>
  );
};

export default App;
