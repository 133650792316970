/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import smoothscroll from "smoothscroll-polyfill";
import Wizard from "../Wizard";
import Error from "../Error";
import useStyles from "./styles";
import { User } from "../../api/Agent";

const passwordValidation = async (value) => {
  let result;
  try {
    result = await User.validatePassword(value);
  } catch (error) {
    if (error.response) result = JSON.parse(error.response.text);
  }
  return result;
};

const Page6 = ({
  name,
  setPasswordValid,
  setPasswordApi,
  copy,
  userName,
  header
}) => {
  const classes = useStyles();
  const [passwordType, setPasswordType] = useState("password");
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState({
    passwordOne: "",
    passwordTwo: ""
  });
  const [apiErrors, setApiErrors] = useState([]);
  const [errorTwo, setErrorTwo] = useState("");
  const [initState, setInitState] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleBlur = (event) => {
    setInitState(true);
  };

  const handlePasswordOneChange = (event) => {
    passwordValidation({ password: event.target.value }).then((res) => {
      setApiErrors(res.password);
    });
    setPassword({ ...password, passwordOne: event.target.value });
  };

  const handlePasswordTwoChange = (event) => {
    setPassword({ ...password, passwordTwo: event.target.value });
  };

  useEffect(() => {
    smoothscroll.polyfill();
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (checked) setPasswordType("text");
    else if (!checked) setPasswordType("password");

    if (
      password.passwordOne === password.passwordTwo &&
      password.passwordOne !== "" &&
      password.passwordTwo !== "" &&
      !apiErrors
    ) {
      setPasswordApi(password.passwordOne);
      setPasswordValid(true);
    } else {
      setPasswordApi("");
      setPasswordValid(false);
    }

    if (password.passwordOne === "" || password.passwordTwo === "")
      setErrorTwo("Required");
    else if (password.passwordOne !== password.passwordTwo)
      setErrorTwo("Passwords do not match");
    else setErrorTwo("");
  }, [
    checked,
    password,
    setPasswordValid,
    errorTwo,
    apiErrors,
    setPasswordApi
  ]);

  return (
    <Wizard.Page>
      <Typography variant="body1" className={classes.subHeader}>
        {header}
      </Typography>
      <Typography variant="h1" className={classes.header}>
        {copy.line2} {name}
      </Typography>
      <Typography variant="body1" className={classes.text}>
        {copy.line3}
      </Typography>

      <Typography
        variant="body1"
        className={classes.header}
        style={{ marginBottom: 30 }}
      >
        Username: {userName}
      </Typography>

      <TextField
        id="password"
        type={passwordType}
        variant="outlined"
        fullWidth
        label="Set Password"
        value={password.passwordOne}
        onChange={handlePasswordOneChange}
        onBlur={handleBlur}
        style={{ marginBottom: 30 }}
      />
      {apiErrors &&
        apiErrors.map((error, key) => (
          <Typography
            variant="caption"
            color="error"
            align="center"
            display="block"
            className={classes.error}
            key={key}
          >
            {error}
          </Typography>
        ))}
      <TextField
        id="password2"
        type={passwordType}
        variant="outlined"
        fullWidth
        label="Confirm Password"
        value={password.passwordTwo}
        onChange={handlePasswordTwoChange}
        onBlur={handleBlur}
        style={{ marginBottom: 30 }}
      />
      {initState && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          display="block"
          className={classes.error}
        >
          {errorTwo}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox checked={checked} onChange={handleChange} value="checked" />
        }
        label="Show Password"
        style={{ marginBottom: 30 }}
      />
      <Typography
        variant="body2"
        display="block"
        className={classes.fieldDisclaimer}
      >
        {copy.line4}
      </Typography>
    </Wizard.Page>
  );
};

Error.propTypes = {
  name: PropTypes.string.isRequired
};

Page6.defaultProps = {
  name: "",
  userName: ""
};

Page6.propTypes = {
  name: PropTypes.string,
  userName: PropTypes.string,
  setPasswordValid: PropTypes.func.isRequired,
  setPasswordApi: PropTypes.func.isRequired,
  copy: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired
};

export default Page6;
