import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import copyEnable from "../utlis/copyEnable";
import copyEn from "../utlis/copyEn";
const copy = (process.env.REACT_APP_FE === "enable") ? copyEnable : copyEn

const Loading = () => {
  return (
    <div style={{ minHeight: "100vh", marginTop: 50 }}>
      <div style={{ width: "100%", textAlign: "center", margin: "10vh auto" }}>
        <CircularProgress color="secondary" size="120px" thickness={1} />
      </div>
      <Typography
        variant="body2"
        color="primary"
        align="center"
        style={{ marginBottom: "8vh" }}
      >
        {copy.account.signin.line1}
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        align="center"
        style={{ fontWeight: 600 }}
      >
        {copy.account.signin.line2}
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        align="center"
        style={{ fontWeight: 600 }}
      >
        {copy.account.signin.line3}
      </Typography>
    </div>
  );
};

export default Loading;
