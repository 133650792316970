import { Loan, Token } from "../api/Agent";

export const CLEAR_LOAN = "CLEAR_LOAN";
export const GET_LOAN_LOADING = "GET_LOAN_LOADING";
export const GET_LOAN_SUCCESS = "GET_LOAN_SUCCESS";
export const GET_LOAN_ERROR = "GET_LOAN_ERROR";
export const GET_LOAN_ID_LOADING = "GET_LOAN_ID_LOADING";
export const GET_LOAN_ID_SUCCESS = "GET_LOAN_ID_SUCCESS";
export const GET_LOAN_ID_ERROR = "GET_LOAN_ID_ERROR";

export const clearLoan = () => ({
  type: CLEAR_LOAN
});

export const getLoan = () => async dispatch => {
  dispatch({
    type: GET_LOAN_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Loan.get();
    dispatch({
      type: GET_LOAN_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_LOAN_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};

export const getLoanById = id => async dispatch => {
  dispatch({
    type: GET_LOAN_ID_LOADING
  });
  try {
    await Token.set(sessionStorage.getItem("jwt"));
    const response = await Loan.getById(id);
    dispatch({
      type: GET_LOAN_ID_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: GET_LOAN_ID_ERROR,
      payload: JSON.parse(error.response.text)
    });
  }
};
